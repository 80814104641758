import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import { BootstrapVue, IconsPlugin } from 'bootstrap-vue'
import './assets/custom.scss'
import mixin from './components/Mixin.vue'
import axios from 'axios'
import VueAxios from 'vue-axios'
import loading from './components/Loading.vue'



Vue.component('loading',loading)
// Make BootstrapVue available throughout your project
Vue.use(BootstrapVue)
// Optionally install the BootstrapVue icon components plugin
Vue.use(IconsPlugin)
Vue.mixin(mixin)
Vue.use(VueAxios, axios)
Vue.prototype.$axios = axios;

Vue.config.productionTip = false


new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')
