<template>
  <div class="news">
    <!--***************************************************************-->
    
    <!--***************************************************************-->
    <b-container>
      <header>
      <h1 class="title">最新消息</h1>
      </header>
          <b-nav>
            <b-nav-item href="javascript:void(0)" @click="listClick('')"  :active="SelectIf.type==''">全部</b-nav-item>
            <b-nav-item href="javascript:void(0)" 
            v-for="(i2,i1) in typeName2" :key="i1" :active="SelectIf.type==i2.DL_VALUE" 
            @click="listClick(i2.DL_VALUE)">{{i2.DL_NAME}}
            </b-nav-item>
          </b-nav>
      
          <!--***************************************************************-->
          <BTableV1 :TablePage="TablePage" :cell="['data']" >
            <template #data="{row}">
              <ul class="list-unstyled list news-list" style="margin-bottom:0px">
                <li>
                  <b-link @click="linkGo(row.item)">
                    <div class="mb-0">
                      <strong class="newsDate text-black-50">{{ IsNull(row.item.NE_DATE)==''?'':date2str(new Date(row.item.NE_DATE)).join('-') }}</strong>
                      <h5> {{IsNull(row.item.NE_TITLE)}}</h5>
                      <span class="badge badge-danger mr-1" v-show="row.item.NE_ISTIOP==1">置頂</span>
                      <span class="badge badge-primary">{{IsNull(typeName2[row.item.NE_TYPE],{DL_NAME:''}).DL_NAME }}</span>
                    </div>
                    <div>
                      <b-img v-show="IsNull(files[row.item.NE_MCID])==''" src="https://fakeimg.pl/160x120/" />
                      <b-img v-show="IsNull(files[row.item.NE_MCID])!=''" :src="IsNull(files[row.item.NE_MCID])" />      
                      <!-- <b-icon-arrow-right-square
                        font-scale="2"
                      ></b-icon-arrow-right-square> -->
                    </div>
                  </b-link>
                </li>
              </ul>
            </template>
          </BTableV1>

          <PageV1 :TablePage="TablePage"></PageV1>
          <!--***************************************************************-->
   
    </b-container>
    <!--***************************************************************-->
  </div>
</template>
<script>
import BTableV1 from '../components/BTableV1.vue'
import PageV1 from '../components/PageV1.vue'
export default {
  name:'news',
  components:{
    BTableV1,
		PageV1,
  },
  data() {
    return {

      typeName:{
					'1':'公告',
					'2':'重要公告',
					'3':'課程訊息',
					'4':'推廣教育小組',
					'5':'疫情公告',
			},
      typeName2:{},

      files:{},
      SelectIf:{
        type:'',
      },

      TablePage:{
                    title:'',class:'tclass courses',
                    BT_style:{'thead-class':'d-none'},
                    PG_style:{'pills':true,'align':'center',
					//'first-text':'第一頁','prev-text':'上一頁',
					//'next-text':'下一頁','last-text':'最後一頁'
          },
                    itemsAll:[],items:[],
                    id:"Table1",currentPage:1,rows:100,page:20,
                    sortBy:'',sortDesc:false,sortDirection:"asc",
                    filter:null,filterOn:[],
                    fields: [
						{key: 'data', label:'',},
					],
                    TNum:[   
                            {text:'列出近10筆記錄',val:10},
                            {text:'列出近20筆記錄',val:20},
                            {text:'列出近50筆記錄',val:50},
                            {text:'列出近100筆記錄',val:100},],
                    TIndex(th1,index){
                    return ((th1.currentPage-1)*th1.page)+index
                    },
                    clear(th1){
                    th1.itemsAll=[];
                    th1.items=[];
                    th1.rows=0;
                    th1.currentPage=1
                    },
                    set(th1,data){
                    th1.itemsAll=JSON.parse(JSON.stringify(data));
                    th1.items=JSON.parse(JSON.stringify(data));
                    th1.rows=data.length;
                    
                    },
                    set2(th1,data){
                    th1.items=JSON.parse(JSON.stringify(data));
                    th1.rows=data.length;
                    th1.currentPage=1;
                    },
                    set3(th1,data){
                    th1.items=JSON.parse(JSON.stringify(data));
                    th1.rows=data.length;
                    th1.currentPage=1;
                    }
                },
      
    };
  },
  mounted(){
    let navBgSwitch=this._storeData('navBgSwitch')
    if(this.IsNull(navBgSwitch)!=''){
      navBgSwitch(false)
    }
    //this.dataSet()
    this.FunctionToken(this.FunctionGetNewsIndex,{})
    this.FunctionToken(this.FunctionDropDownGetList,{
							TYPE:'LIST',
							DL_PJID:'TP01'
						})
  },
  methods: {

    TSelectF1(){
      if(this.SelectIf.type==''){
        this.TablePage.set2(this.TablePage,this.TablePage.itemsAll)
        return
      }
      let data1=[]
      for(let i in this.TablePage.itemsAll){
        let item=this.TablePage.itemsAll[i]
        let if1=0,if2=0;
        if(this.SelectIf.type!=''){
          if1+=1
          if(this.IsNull(item.NE_TYPE)!='' && item.NE_TYPE==this.SelectIf.type){
            if2+=1
          }
        }
        if(if1==if2){
          data1.push(item)
        }

      }
      this.TablePage.set2(this.TablePage,data1)
    },

    listClick(val){
      this.SelectIf.type=val
      this.TSelectF1()
    },
    linkGo(data){
      this._go2({
        data:{NE_ID:this.IsNull(data.NE_ID)},
        path:'NewsDetail',
      },'query')
    },


    dataSet(){
      let data1=[
        {
          title:
            "5/15~5/28停課，部分課程採遠距教學。辦公室不對外服務，諮詢請以電子郵件申辦",
          tag: "公告",
          date: "2021-05-15",
        },
        {
          title: "學分班課程自5/24起，全數採遠距教學。",
          tag: "公告",
          date: "2021-05-20",
        },
        {
          title: "數位資訊學苑 課程異動",
          tag: "公告",
          date: "2020-04-04",
        },
        {
          title: "5月13日起本校校園以實聯制量溫入校",
          tag: "重要公告",
          date: "2020-04-04",
        },
        {
          title: "疫情升溫‧校園防疫行動更謹慎",
          tag: "推廣教育小組",
          date: "2020-04-04",
        },
        {
          title: "第22屆全國高中生法律營第一波備取名單",
          tag: "高中生營隊",
          date: "2020-04-04",
        },
        {
          title: "微軟MOS國際證照2021年6月考試開始報名了",
          tag: "MOS考試公告",
          date: "2020-04-04",
        },
        {
          title: "出入教育學習場所請配戴口罩",
          tag: "重要公告",
          date: "2020-04-04",
        },
      ]

      this.TablePage.set(this.TablePage,data1)

    },
    //===================================================================
    //api

    FunctionGetNewsIndex(data_in){
				this.apiGetNewsIndex(data_in)
				.then(res=>{
					let json=JSON.parse(res.data)
					if(json.Status){
				
						let data1=JSON.parse(json.Data)
						this.Clog(data1)
            let data2=[]
            let data3=[]
            let MC_IDs=[]
            for(let i in data1){
              let item=data1[i]
              item.JS_DATETIME=0
              if(this.IsNull(item.NE_DATE)!=''){
                item.JS_DATETIME=Number(new Date(item.NE_DATE))
              }
              if(item.NE_ISTIOP==1){
                data2.push(item)
              }else{
                data3.push(item)
              }

              if(this.IsNull(item.NE_MCID)!=''){
                MC_IDs.push(item.NE_MCID)
              }
            }
            data2.sort((a,b)=>{return -1*(a['JS_DATETIME']-b['JS_DATETIME'])})
            data3.sort((a,b)=>{return -1*(a['JS_DATETIME']-b['JS_DATETIME'])})
            let data4=data2.concat(data3)
            this.TablePage.set(this.TablePage,data4)
            if(MC_IDs.length!=0){
              this.FunctionToken(this.FunctionGetArchive,{
                MC_ID:MC_IDs
              })
            }
            
					}
				})
				.catch(err=>{
					this.Elog(err)
				})
			},

      FunctionGetArchive(data_in) {
        this.apiGetArchive(data_in)
          .then((res) => {
            let json = JSON.parse(res.data);
            if (json.Status) {
              let data1 = JSON.parse(json.Data);
              for (let i in data1) {
                let item = data1[i];
                this.files[item.MC_ID] = [item.SP_PATH_WEB, item.MC_FILE].join( "");
              }
              this._watcher.run();
            }
          })
          .catch((err) => {
            this.Elog(err);
          });
      },

      FunctionDropDownGetList(data_in){
				this.apiDropDownForm(data_in)
				.then(res=>{
					let json=JSON.parse(res.data)
					if(json.Status){
						let data1=JSON.parse(json.Data)
						console.log(data1)
						for(let i in data1){
							let item=data1[i]
							this.typeName2[item.DL_VALUE]=item
						}
            this._watcher.run();
					}
				})
				.catch(err=>{
					console.log(err)
				})
			},

    
  },
};
</script>
<style lang="scss">
.news-list {
  img {
    width: 180px;
    height: auto;
  }
}

.tclass tr td {
	border-top: 0px;
}

.tclass tr:last-child .item {
	border-bottom:  0px;
}

.tclass th, .tclass td   {
    padding: 0px !important;
}

</style>


