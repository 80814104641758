<template>
    <div class="footer">
        <footer>
            <b-container fluid>
                <b-row align-h="between">
                    <b-col>
                        <b-row align-v="stretch">
                        <!-- <b-col cols="auto">
                            <b-img class="mb-3" :src="foot.data['img']" fluid></b-img>
                        </b-col> -->
                        <b-col cols="12" sm="auto">
                            <h5>逢甲大學產學經營與人才培育中心</h5>
                            <ul class="list-unstyled infoo">
                                <li><span>通訊地址</span>{{foot.data['address']}}</li>
                                <li><span>聯絡電話</span>{{foot.data['tell']}}</li>
                                <li><span>傳真電話</span>{{foot.data['fax']}}</li>
                                <li><span>聯絡信箱</span>{{foot.data['email']}}</li>
                            </ul>
                        </b-col>
                        <b-col cols="12" sm="auto">
                            <div class="line">
                                <h6>關於</h6>
                                <b-nav vertical>
                                    <b-nav-item @click="_go1('./#/about',1)">關於我們</b-nav-item>
                                    <b-nav-item target="_blank" href="https://www.fcu.edu.tw/privacy/">個資保護政策</b-nav-item>
                                    <b-nav-item target="_blank" href="https://myextension.fcu.edu.tw/Login.aspx?ReturnURL=/S48/S4805/S48050401.aspx?sys_id=S48&sys_pid=S48050401">審課系統登入</b-nav-item>
                                    <b-nav-item target="_blank" href="https://extension.fcu.edu.tw/website_admin/#/Signin">課程登錄管理</b-nav-item>
                                </b-nav>
                            </div>
                        </b-col>
                        <b-col cols="12" sm="auto">
                            <div class="line">
                                <h6>幫助</h6>
                                <b-nav vertical>
                                    <b-nav-item to="/news">最新消息</b-nav-item>
                                    <b-nav-item to="/qanda">常見問題</b-nav-item>
                                </b-nav>
                            </div>
                        </b-col>
                        </b-row>
                    </b-col>
                    <b-col sm='3'>
                        <b-row>
                            <b-col>
                                <div style="display: flex;justify-content: center;padding-left:3rem">
                                    <b-link @click="_go1('https://www.facebook.com/CSEE.FCU',1)" style="width:7rem;margin-right: -1rem">
                                        <svg data-name="Layer 1" id="Layer_1" viewBox="0 0 512 512" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink"><defs><linearGradient gradientUnits="userSpaceOnUse" id="linear-gradient" x1="256" x2="256" y1="99.15" y2="424.78"><stop offset="0" stop-color="#47a2f5"/><stop offset="1" stop-color="#2b6fdc"/></linearGradient></defs><title/><rect fill="url(#linear-gradient)" height="412.22" rx="55.43" width="412.22" x="49.89" y="49.89"/><path d="M217.92,266.2H171v53.15h47V462.11h62.43V320.43H327l9.69-54.23h-56V226.57A33.57,33.57,0,0,1,314.33,193H341V143.49H283.11a64.93,64.93,0,0,0-64.93,64.64Z" fill="#fefefe"/></svg>
                                    </b-link>

                                    <b-link @click="_go1('https://www.instagram.com/fcu_extension_education/',1)" style="width:7rem;margin-right: -1rem">
                                        <svg data-name="Layer 1" id="Layer_1" viewBox="0 0 512 512" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink"><defs><radialGradient cx="193.09" cy="493.29" fx="159.10076116445987" gradientUnits="userSpaceOnUse" id="radial-gradient" r="466"><stop offset="0" stop-color="#fed77b"/><stop offset="0.11" stop-color="#fed277"/><stop offset="0.2" stop-color="#fdc067"/><stop offset="0.36" stop-color="#f4794f"/><stop offset="0.46" stop-color="#ee483f"/><stop offset="0.78" stop-color="#af2c94"/><stop offset="1" stop-color="#5658d1"/></radialGradient></defs><title/><rect fill="url(#radial-gradient)" height="412.22" rx="55.43" width="412.22" x="49.89" y="49.89"/><path d="M256,315.88a61.7,61.7,0,1,1,61.7-61.7A61.75,61.75,0,0,1,256,315.88Zm0-106.61a44.92,44.92,0,1,0,44.91,44.91A45,45,0,0,0,256,209.27Z" fill="#fefefe"/><path d="M314.41,379.83H198.53a67.13,67.13,0,0,1-67.05-67.05V196.9a67.13,67.13,0,0,1,67.05-67.05H314.41a67.13,67.13,0,0,1,67,67.05V312.78A67.13,67.13,0,0,1,314.41,379.83ZM202.12,147.29a53.28,53.28,0,0,0-53.21,53.21V309.18a53.28,53.28,0,0,0,53.21,53.21H310.81A53.27,53.27,0,0,0,364,309.18V200.5a53.27,53.27,0,0,0-53.21-53.21Z" fill="#fefefe"/><circle cx="325.02" cy="186.34" fill="#fefefe" r="14.74"/></svg>
                                    </b-link>

                                    <b-link @click="_go1('https://lin.ee/pO8xeFn',1)" style="width:7rem;margin-right: -1rem">
                                        <svg data-name="Layer 1" id="Layer_1" viewBox="0 0 512 512" xmlns="http://www.w3.org/2000/svg"><title/><rect fill="#57bd37" height="412.22" rx="55.43" width="412.22" x="49.89" y="49.89"/><path d="M230.9,359.35s17.42,0,15.24,18.87l.11,17.92a8.13,8.13,0,0,0,12,7.13c26.22-13.92,90.44-52.21,137.43-114.86,0,0,51.71-75.66-30.48-143.15,0,0-81.09-73.94-194.59-14.17-23.35,12.29-43.81,30-56.52,53.13-13.74,25-22.13,61-.73,105.09C113.33,289.31,141.82,348.1,230.9,359.35Z" fill="#fff"/><path d="M199.8,272.63H157a2,2,0,0,1-2-2V205.69a2,2,0,0,1,2-2h12a2,2,0,0,1,2,2v48.95a2,2,0,0,0,2,2H199.8a2,2,0,0,1,2,2v12A2,2,0,0,1,199.8,272.63Z" fill="#57bd37"/><path d="M281.24,271.59l-23.86-35.91a2,2,0,0,0-3.67,1.11v33.69a2,2,0,0,1-2,2h-12a2,2,0,0,1-2-2V205.69a2,2,0,0,1,2-2h14.51a2,2,0,0,1,1.67.9l23.86,35.91a2,2,0,0,0,3.67-1.11V205.69a2,2,0,0,1,2-2h12a2,2,0,0,1,2,2v64.79a2,2,0,0,1-2,2H282.91A2,2,0,0,1,281.24,271.59Z" fill="#57bd37"/><rect fill="#57bd37" height="68.8" rx="2.01" width="15.98" x="211.32" y="203.68"/><path d="M354,217V205a2,2,0,0,0-2-2H310.76a2,2,0,0,0-2,2v65.61a2,2,0,0,0,2,2H352a2,2,0,0,0,2-2v-12a2,2,0,0,0-2-2H324.74V246.46H352a2,2,0,0,0,2-2v-12a2,2,0,0,0-2-2H326.74a2,2,0,0,1-2-2V221a2,2,0,0,1,2-2H352A2,2,0,0,0,354,217Z" fill="#57bd37"/></svg>
                                    </b-link>
                                </div>
                                
                            </b-col>
                            
                        </b-row>
                        
                    </b-col>
                </b-row>

            </b-container>
        </footer>
        <div class="footer-nav">
            <b-container fluid>
                Copyright©逢甲大學Feng Chia University
            </b-container>
        </div>
    </div>
</template>
<script>
export default {
    name:'page-footer',
    components:{},
    data(){
        return{
            foot:{
                title:'頁尾',
                data:{
                    img:require('@/assets/logo-h-w.png'),
                    address:'台中市 40724 西屯區文華路100號 行政二館一樓',
                    tell:'04-24517250 轉 2411',
                    fax:'04-24514503',
                    email:'extension@fcu.edu.tw',
                }
            },
            links:{
                title:'連結',
                data:{
                    s2:{text:'最新消息',url:'/news',},
                    s3:{text:'常見問題',url:'/',}
                }
            }
        }
    },
    mounted(){

    },
    methods:{

    }
}
</script>