<template>
    <div class="min-vh-100">
        <b-container style="padding-top:120px">
            <div class="text-center">
                <b-img style="width:128px; margin-bottom:2rem" :src="img" fluid></b-img>
                <h4 class="text-success mb-4"><b-icon-check-circle-fill></b-icon-check-circle-fill> 您已報名成功</h4>
                <b-list-group class="text-left w-50 mx-auto mb-3">
                    <b-list-group-item>報名日期：xxxx年xx月xx日</b-list-group-item>
                    <b-list-group-item>課程名稱：</b-list-group-item>
                    <b-list-group-item>學員人數：</b-list-group-item>
                    <b-list-group-item>課程費用：</b-list-group-item>
                    <b-list-group-item>付款期限：xxxx年xx月xx日</b-list-group-item>
                    <b-list-group-item>付款方式：臨櫃、逢甲 Pay</b-list-group-item>
                    <b-list-group-item>優惠身份：教職員、在校生<span class="text-success">（已驗證）</span><span class="text-danger">（未驗證）</span><b-button size="sm">身份驗證</b-button></b-list-group-item>
                </b-list-group>
                <p>請於繳費期限內，完成付款。臨櫃報名請至逢甲大學推廣教育處辦公室完成報名繳費程序。</p>
                <b-button size="lg" variant="primary">前往付款（逢甲 PAY）</b-button>
            </div>
        </b-container>
    </div>
</template>
<script>
export default {
    name:'RegistrationComplete',
    data(){
        return {
            img: require("@/assets/success.png"),
        }
    },
    mounted(){

    },
    methods:{
        
    },
}
</script>