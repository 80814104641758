<template>

                            <b-pagination
                              v-model="TablePage.currentPage"
                              :total-rows="TablePage.rows"
                              :per-page="TablePage.page"
                              :aria-controls="TablePage.id"
                              v-bind="TablePage.PG_style"
                            ></b-pagination>
                         
</template>
<script>
export default {
    name:'PageV1',
    props:{
        TablePage:{
            type:[Object],
            default(){
                return{
                    title:'',
                    BT_style:{'hover':true,'outlined':true,'responsive':true,'head-variant':'light'},
                    PG_style:{'pills':true,'align':'center','size':'sm'},
                    itemsAll:[],items:[],
                    id:"Table1",currentPage:1,rows:1,page:10,
                    sortBy:'',sortDesc:false,sortDirection:"asc",
                    filter:null,filterOn:[],
                    fields: [],
                    TNum:[   
                            {text:'列出近10筆記錄',val:10},
                            {text:'列出近20筆記錄',val:20},
                            {text:'列出近50筆記錄',val:50},
                            {text:'列出近100筆記錄',val:100},],
                    TIndex(th1,index){
                    return ((th1.currentPage-1)*th1.page)+index
                    },
                    clear(th1){
                    th1.itemsAll=[];
                    th1.items=[];
                    th1.rows=0;
                    th1.currentPage=1
                    },
                    set(th1,data){
                    th1.itemsAll=JSON.parse(JSON.stringify(data));
                    th1.items=JSON.parse(JSON.stringify(data));
                    th1.rows=data.length;
                    
                    },
                    set2(th1,data){
                    th1.items=JSON.parse(JSON.stringify(data));
                    th1.rows=data.length;
                    th1.currentPage=1;
                    },
                    set3(th1,data){
                    th1.items=JSON.parse(JSON.stringify(data));
                    th1.rows=data.length;
                    th1.currentPage=1;
                    }
                }
            }
        }
    },
    //['TablePage'],
    data(){
        return{}
    }
}
</script>