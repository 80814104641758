<template>
<div>
  <div class="home" ref="infoBox">
      <!--***********************************************************************************************-->
      <div class="ccccc" ref="mainSplideBox">
        <b-container>
          <splide 
          :slides="mainSplide.photos" 
          :options="mainSplide.options"
          :ref="mainSplide.ref"
          >
          <template v-for="(slide, index) in mainSplide.photos">
            <splide-slide ref="splideSlide" v-if="index>3"  :key="index">
                <figure>
                  <b-img 
                  @load="mainSplideGo()"
                  @click="mainSplideGo2(slide.MP_URL)"
                  :src=" IsNull(files[slide.MP_MCID])==  '' ? '' : files[slide.MP_MCID]" 
                  fluid center></b-img>
                </figure>
            </splide-slide>
              </template>
          </splide>
          <b-row cols="4" cols-sm="4" cols-md="4" cols-lg="4" class="py-4">
            <template v-for="(slide, index) in mainSplide.photos">
              <b-col v-if="index<=3" :key="index">
                  <b-img 
                  @load="mainSplideGo()"
                  @click="mainSplideGo2(slide.MP_URL)"
                  :src=" IsNull(files[slide.MP_MCID])==  '' ? '' : files[slide.MP_MCID]" 
                  fluid></b-img> 
              </b-col>
            </template>
          </b-row>
          </b-container>
      </div>
      <!--*******************************************************************************************************-->
      <!-- 課程類別 -->
      
      <section class="box white" ref="menuBox">
        <b-container>
        <h3 class="title text-center">課程分類</h3>
        <b-nav pills class="categories" >
          <b-nav-item class="catalog" :key="index" v-for="(item, index) in nav.data" @click="navGo(item)">
            <div class="icon" :style="{ 'background-image': 'url(' +IsNull(files[item.CAT_ICON_URL])+ ')' }"></div>
            <strong>{{IsNull(item.CAT_NAME)}}</strong>
          </b-nav-item>
        </b-nav>
        </b-container>
      </section>
      
  </div>


    <!--***********************************************************************************************-->
      <section class="box light-blue">
        <b-container>
        <h3 class="title text-center">
          {{ cards3.title }}-
          <div class="loading">
            <span v-for="(i2,i1) in '熱烈招生中'" :key="i1">{{i2}}</span>
          </div>
          <!-- <br />
          <small>{{ cards3.title2 }}</small> -->
        </h3>
        <b-row cols-md="3" align-v="stretch" class="courses grid mb-3">
          <b-col v-for="(i2, i1) in cards3.data" :key="i1" class="p-3">
            <b-link class="mainImage" @click="cards3Go(i2)" style=" text-decoration :none">
            <b-card
              img-alt="Image"
              img-top
              tag="article"
              class="course"
              no-body
            >
              <figure>
                <b-card-img
                  :src=" IsNull(files[i2.CA_IMAGE_URL]) == '' ? '' : files[i2.CA_IMAGE_URL] "
                ></b-card-img>
              </figure> 
              <!-- <div class="state">
                <b-badge 
                  v-for="(j2,j1) in IsNull(i2.courseStatus,[])" :key="j1" 
                  v-bind="IsNull(courseStatusStyle[IsNull(j2)],courseStatusStyle[''])"
                  pill
                  >
                {{IsNull(j2)}}</b-badge>
              </div> -->
              <b-card-body>
                <div class="mb-2">
                  <!-- <b-badge
                    v-for="(j2, j1) in i2.JS_HASHTAGs"
                    :key="j1"     
                    :class="j2.class"
                    >{{ j2.text }}
                  </b-badge> -->
                </div>
                <b-card-title title-tag="h5" class="mb-0">
                  {{IsNull(i2.CS_TITLE)}} <br />
                  <!-- <small> 第{{IsNull(i2.CA_PERIOD)}}期 </small> -->
                  <small style="color: #888;" v-html="Re_html(i2.CA_ABSTRACT)"></small>
                </b-card-title>
                <!-- <b-card-text class="text-black-50"> 開課日期 {{ IsNull(i2.JS_BETIME) }} </b-card-text> -->
              </b-card-body>
              <b-card-footer>
                <span class="text-black-50">原價</span><span class="price" style="text-decoration:line-through; color:#666"> NT${{IsNull2(i2.CA_ORIGIN_TUITION,0)}}</span>
                <span class="text-black-50"></span><span class="price"> <span class="number">NT${{IsNull2(i2.CA_TUITION,0)}}</span></span><br />
              </b-card-footer>
            </b-card>
            </b-link>
          </b-col>
        </b-row>
        <div class="more text-center">
          <b-button
            pill
            variant="primary"
            class="px-5"
            @click="cards3AllGo()"
            >檢視全部</b-button
          >
        </div>
        </b-container>
      </section>
    <!--***********************************************************************************************-->

      <section style="background-color: #E2F5FF;" class="box">
        <b-container>
        <h3 class="title text-center">
          {{ cards1.title }}-
          <div class="loading">
            <span v-for="(i2,i1) in '新班規劃中'" :key="i1">{{i2}}</span>
          </div>
          <!-- <br /><small>{{ cards1.title2 }}</small> -->
        </h3>
        <b-row cols-md="3" class="courses grid mb-3">
          <b-col v-for="(i2, i1) in cards1.data" :key="i1" class="p-3">
            <b-link class="mainImage" @click="cards1Go(i2)" style=" text-decoration :none">
            <b-card
              img-alt="Image"
              img-top
              tag="article"
              class="course"
              no-body
            >
              
              <figure>
                <b-card-img
                  :src=" IsNull(files[i2.CA_IMAGE_URL]) == '' ? '' : files[i2.CA_IMAGE_URL] "
                ></b-card-img>
              </figure>
              <!-- <div class="state">
                <b-badge 
                  v-for="(j2,j1) in IsNull(i2.courseStatus,[])" :key="j1" 
                  v-bind="IsNull(courseStatusStyle[IsNull(j2)],courseStatusStyle[''])"
                  pill
                  >
                {{IsNull(j2)}}</b-badge>
              </div> -->
              <b-card-body>
                <div class="mb-2">
                <!-- <b-badge
                  v-for="(j2, j1) in i2.JS_HASHTAGs"
                  :key="j1"
                  :class="j2.class"
                  >{{ j2.text }}</b-badge
                > -->
                </div>

                <b-card-title title-tag="h5" class="mb-0">
                  {{ IsNull(i2.CS_TITLE) }} <br /> 
                  <small style="color: #888;" v-html="Re_html(i2.CA_ABSTRACT)" ></small> 
                </b-card-title>
           
                
                <!-- <b-card-text class="text-black-50">開課日期 {{ IsNull(i2.JS_BETIME) }}</b-card-text> -->
                  
                
              </b-card-body>
              <b-card-footer>
                <span class="text-black-50">原價</span><span class="price" style="text-decoration:line-through; color:#666"> NT${{IsNull2(i2.CA_ORIGIN_TUITION)}}</span>
                <span class="text-black-50"></span><span class="price"><span class="number">NT${{IsNull2(i2.CA_TUITION)}}</span></span><br />
              </b-card-footer>
            </b-card>
            </b-link>
          </b-col>
        </b-row>
        <div class="more text-center">
          <b-button
            pill
            variant="primary"
            class="px-5"
            @click="cards1AllGo()"
            >檢視全部</b-button
          >
        </div>
        </b-container>
      </section>

    <!--***********************************************************************************************-->

      <section class="box light-blue">
        <b-container>
        <h3 class="title text-center"> {{ cards2.title }}<!-- <br /><small>{{ cards2.title2 }}</small> --></h3>
        <b-row  class="courses grid mb-3">
          <template v-for="(i2,i1) in cards2.data">
            <b-col :key="i1" md="4">
              <b-link @click="cards2Go(i2)">
                <figure>
                <b-img class="rounded shadow" :src="IsNull(files[i2.SS_MCID])!=''? files[i2.SS_MCID]:'https://fakeimg.pl/1440x540/?retina=1&text=主圖&font=noto'" fluid></b-img>
                </figure>
              </b-link>
            </b-col>
          </template>
        </b-row>
        <div class="more text-center">
        <b-button pill variant="primary"  class="px-5"  @click="cards2AllGo()">檢視全部</b-button>
        </div>
        </b-container>
      </section>

    <!--***********************************************************************************************-->

      <section class="box white">
        <b-container>
        <h3 class="title text-center">
          {{ news.title }}
          <!-- <br /><small>{{ news.title2 }}</small> -->
        </h3>

        <div class="index-news">
          <template v-for="(item, key, index) in news.data">
            <div            
              :key="index"
              v-bind:class="[index == 0 ? 'box-first' : 'box-basic']"
            >
              <b-link @click="newsGo(item)">
                <figure style="background-size: cover; background-position: center;" :style="{ 'background-image': 'url(' + IsNull(files[item.NE_MCID]) + ')' }">
                  <!-- <b-img :src=" IsNull(files[item.NE_MCID]) ==  '' ? '' : files[item.NE_MCID] " fluid></b-img> -->
                </figure>
                <div class="title">
                  <h6>{{IsNull(item.NE_TITLE) }}</h6>
                  <span class="text-black-50">
                  <b-icon-clock></b-icon-clock> {{IsNull(item.JS_DATE)}}
                  </span>
                </div>
              </b-link>
            </div>
          </template>
        </div>
        <div class="more text-center">
          <b-button @click="newsAllGo()"  pill variant="primary" class="px-5">檢視全部</b-button>
        </div>
        </b-container>
      </section>

    <!--***********************************************************************************************-->

 
</div>
</template>
<script>

// import Carousel from "@/components/Carousel.vue";
import { Splide, SplideSlide } from "@splidejs/vue-splide";
import "@splidejs/splide/dist/css/themes/splide-default.min.css";

export default {
  name: "Home",
  components: {
    // Carousel,
    Splide,
    SplideSlide,
  },
  data() {
    return {
      files: {
        'MC_categoriesAll':'',
        'MC_categoriesAll2':'',
        'MP_MCID1':'/update/home1.png'
      },

      courseStatusStyle:{
        '':{variant:'primary'},
        '規劃中':{variant:'primary'},
        '已截止':{variant:'primary'},
        '招生中':{variant:'primary'},
        '已成班':{variant:'primary'},
        '開課中':{variant:'primary'},
        '早鳥優惠':{variant:'primary'},
        '仍可報名':{variant:'primary'},
        '已結束':{variant:'primary'},
        '最新課程':{variant:'primary'},
        '熱門課程':{variant:'danger'},
      },

      categories:[],
      categoriesAll:{
        CAT_DESCRIPTION:'全部',
        CAT_DISPLAY:1,
        CAT_ICON_URL:'MC_categoriesAll',
        CAT_ID:'JS',
        CAT_IMAGE_URL:'MC_categoriesAll2',
        CAT_ISDEL:0,
        CAT_NAME:'全部',
        CAT_SORT:','
      },
      mainSplide:{
        ref:'mainSplide',
        title:'首頁輪播',
        imgIf:0,
        photos:[
          {MP_MCID:'MP_MCID1'},
          // {MP_MCID:'MP_MCID1'},
          // {MP_MCID:'MP_MCID1'},
          // {MP_MCID:'MP_MCID1'},
        ],
        options: {
          type     : 'loop',
          focus    : 'center',
          //arrows    : false,
          // autoWidth: true,
          trimSpace: true,
          start: 0,
          autoplay:  true,
          //interval:1000,
          //  perPage: 2,
        }, 
      },
      nav: {
        title: "",
        loca: "",
        data: [{"CAT_ID":"CAT85RrTHZNEmvCZGOuO4Q","CAT_NAME":"資訊科技","CAT_ICON_URL":"MCm0sQagVEeUeZXOEoe0XDyA","CAT_IMAGE_URL":null,"CAT_SORT":0,"CAT_DESCRIPTION":"","CAT_DISPLAY":1,"CAT_ISDEL":0},{"CAT_ID":"5","CAT_NAME":"智慧製造","CAT_ICON_URL":"MCoyLuVt9ZrUqG6u8yqw5M4g","CAT_IMAGE_URL":"MCVIDOLtoi006ODNYGGyZQA","CAT_SORT":1,"CAT_DESCRIPTION":"智慧製造","CAT_DISPLAY":1,"CAT_ISDEL":0},{"CAT_ID":"CATdfttPw0QWUGJgX8NSMWwJw","CAT_NAME":"人工智慧","CAT_ICON_URL":"MCUvRsbolQ6ECAm7oYYaKI4g","CAT_IMAGE_URL":"MClbogSLoikGW09uigMCwuQ","CAT_SORT":2,"CAT_DESCRIPTION":"人工智慧","CAT_DISPLAY":1,"CAT_ISDEL":0},{"CAT_ID":"3","CAT_NAME":"經營管理","CAT_ICON_URL":"MCodqxXM3pDUKWvlgwIEtlYA","CAT_IMAGE_URL":"MCQxE9jT8yj0OaehmWRU8iA","CAT_SORT":3,"CAT_DESCRIPTION":"經營管理","CAT_DISPLAY":1,"CAT_ISDEL":0},{"CAT_ID":"CATiYIb0s4aKkal6LVpyHx0gg","CAT_NAME":"財務金融","CAT_ICON_URL":"MC1ZOokg16dEmzoMR44Hqi3Q","CAT_IMAGE_URL":"MCncGBgXVqJkuGvPNoXb7Chw","CAT_SORT":4,"CAT_DESCRIPTION":"財務金融","CAT_DISPLAY":1,"CAT_ISDEL":0},{"CAT_ID":"4","CAT_NAME":"外國語言","CAT_ICON_URL":"MCXNdc5MuD4UG4RGllT3yKw","CAT_IMAGE_URL":"MCy60DoqjA0uR7uNwYMQGyA","CAT_SORT":5,"CAT_DESCRIPTION":"外國語言","CAT_DISPLAY":1,"CAT_ISDEL":0},{"CAT_ID":"2","CAT_NAME":"多媒體設計","CAT_ICON_URL":"MCEA1WO3nTSkmahjxWFQIOg","CAT_IMAGE_URL":"MCmtmdfp5D2UydkM2IoZqS4g","CAT_SORT":6,"CAT_DESCRIPTION":"多媒體設計","CAT_DISPLAY":1,"CAT_ISDEL":0},{"CAT_ID":"CAThbBx4uIpmkuu0jgPe4tcg","CAT_NAME":"藝術休閒","CAT_ICON_URL":"MCepHfnG9VkGWqC4zOm8Ppg","CAT_IMAGE_URL":"MC0b2YdmxYUmvQ8V3zOIrWg","CAT_SORT":7,"CAT_DESCRIPTION":"藝術休閒","CAT_DISPLAY":1,"CAT_ISDEL":0},{"CAT_ID":"CATHYv60OPOAU6FgwOcheY8Hg","CAT_NAME":"樂齡親子","CAT_ICON_URL":"MCcpvILTlGkyAgS9XYW174g","CAT_IMAGE_URL":"MClkIqHpxqa0WWvNGuI2iYHA","CAT_SORT":8,"CAT_DESCRIPTION":"樂齡親子","CAT_DISPLAY":1,"CAT_ISDEL":0}],
      },
      
      cards1: {
        title: "特色課程",
        title2: "副標題",
        maxnum:6,
        data: {},
      },

      cards2: {
        title: "系列課程",
        title2: "副標題",
        maxnum:6,
        data: {},
      },

      cards3: {
        title: "精采好課",
        title2: "副標題",
        maxnum:6,
        data: {},
      },

      news: {
        title: "最新消息",
        title2: "副標題",
        maxnum:5,
        data: {},
      },
      imgif:0,
      temps:{photos:[],photosUrl:[]},

   
    };
  },
  mounted(){
    let navBgSwitch=this._storeData('navBgSwitch')
    if(this.IsNull(navBgSwitch)!=''){
      navBgSwitch(false)
    }
  },
  beforeMount() {
    this.sessionF1();
    this.awaitFunList(
      [{type:'await',fun:this.FunctionToken3,data_in:{}},
       {type:'await',fun:this.FunctionGetListMainPholo,data_in:{}},
       {type:'await',fun:this.FunctionToken3,data_in:{}},
       {type:'await',fun:this.FunctionGetArchive,data_in:{
         MC_ID:this.temps['photosUrl'],
         JSval:{photos:this.temps['photos']}
       }},
      ]
    )
    //this.FunctionToken(this.FunctionGetListMainPholo, {});
    this.FunctionToken(this.FunctionGetCourseFeriodListByIndex_mini, {
      CA_ISNOT:1,
      CA_ISNEW:1
    });
    this.FunctionToken(this.FunctionGetCategoryListByIndex, {});
    this.FunctionToken(this.FunctionGetNewsIndex, {});
    this.FunctionToken(this.FunctionGetArchive, {});
    this.FunctionToken(this.FunctionGetListSeries,{})
  },
  created() { 
  },
  updated() {
    // let aa = this.$refs.mainSplideBox.clientHeight ;
    // let bb = this.$refs.infoBox.clientHeight ;
    // let cc = this.$refs.menuBox.clientHeight ;
    // this.$refs.mainSplideBox.style.height = bb-cc+'px';
  },
  methods: {
    
    sessionF1(url, data) {
      if (url == "courses") {
        /*
        this._go2({
          path:'/courses',
          data:{CAT_ID:data}
        },'query')
        */
        this._sessionData('CAT_ID',this.IsNull(data.CAT_ID))
        this._sessionData('courseStatus',this.IsNull(data.courseStatus))
        this._go1(this.LocaPath.default+'courses',1)
        
      } else if (url == "course") {
        let url=this.LocaPath.default+'course?CP_ID={CP_ID}'
        this._go1(this.Str_Replace(url,'{CP_ID}',data),1)
      } else if (url == "NewsDetail") {
          this._go2({
            path:'/NewsDetail',
            data:{NE_ID:data}
          },'query')
      }else if(url=='courseSeriesDetails'){
        this._go2({
          path:'/courseSeriesDetails',
          data:{SS_ID:data},
        },'query')
      }
    },
    mainSplideGo(){
      if(this.mainSplide.imgIf==1){
        this.$refs[this.mainSplide.ref].splide.go('0')
      }
      this.mainSplide.imgIf+=1
      /*
      let doc=document.getElementsByClassName('splide__pagination')
      doc.forEach(x=>{
        x.style.display='none'
      })
      */
    },
    mainSplideGo2(url){
      if(this.IsNull(url)!=''){
        this._go1(url,1)
      }
    },
    navGo(data) {
      this.sessionF1("courses",this.IsNull({CAT_ID:data.CAT_ID}));
    },
    cards3Go(data) {
      this.sessionF1("course", this.IsNull(data.CP_ID));
    },
    cards3AllGo() {
      this.sessionF1("courses",this.IsNull({courseStatus:'熱門課程'}));
    },
    cards1Go(data) {
      this.sessionF1("course", this.IsNull(data.CP_ID));
    },
    cards1AllGo() {
      this.sessionF1("courses",this.IsNull({courseStatus:'最新課程'}));
    },
    cards2Go(data){
      this.sessionF1('courseSeriesDetails',this.IsNull(data.SS_ID))
    },
    cards2AllGo(){
      this._go1(this.LocaPath.default+'courseSeries',1)
    },
    
    newsGo(data) {
      this.sessionF1("NewsDetail",this.IsNull(data.NE_ID));
    },
    newsAllGo(){
      this._go1(this.LocaPath.default+'news',1)
    },
    Re_html(val){
      if(this.IsNull(val)===''){
        return ''
      }

      return this.Str_Replace2(val,[{old:'\r\n',new:'<br>'},{old:'\n',new:'<br>'}])
      
    },
    //=========================================
    //api

    FunctionGetListMainPholo(data_in,resolve) {
      this.apiGetListMainPholo(data_in) 
        .then((res) => {
          let json = JSON.parse(res.data);
          if (json.Status) { 
            
            let photos = JSON.parse(json.Data);
            let url = [];
            for (let i = 0; i < photos.length; i++) {
              const photo = photos[i];
              if (this.IsNull(photo.MP_MCID) != "") {
                url.push(photo.MP_MCID);
              } 
            }
            if (url.length != 0) {
              this.temps['photos']=photos
              this.temps['photosUrl']=url
              this.FunctionToken(this.FunctionGetArchive, {
                MC_ID: url,
                JSval:{photos}
              });
            }
            //this.mainSplide.photos = photos
          }
          if (this.IsNull(resolve) != "") {
            resolve("ok");
          }
        })
        .catch((err) => {
          this.Elog(err);
        });
    },
    FunctionGetCategoryListByIndex(data_in) {
      this.apiGetCategoryListByIndex(data_in)
        .then((res) => {
          let json = JSON.parse(res.data);
          if (json.Status) {
            let data1 = JSON.parse(json.Data);
            let files = [];
            let data2=[]
            for (let i in data1) {
              let item = data1[i];
              if(this.OnlyData['CAT'].findIndex(x=>x.CAT_ID==item.CAT_ID)==-1){
                data2.push(item)
                if(this.IsNull(item.CAT_ICON_URL)!=''){
                  files.push(item.CAT_ICON_URL);
                }
              }
            }
            this.nav.data=data2
            if (files.length != 0) {
              this.FunctionToken(this.FunctionGetArchive, {
                MC_ID: files,
              });
            }
          }
        })
        .catch((err) => {
          console.log(err);
        });
    },
    FunctionGetCourseFeriodListByIndex_mini(data_in) {
      this.apiGetCourseFeriodListByIndex_mini(data_in)
        .then((res) => {
          let json = JSON.parse(res.data);
          if (json.Status) {
            let data1 = JSON.parse(json.Data);
            let files = [];
            let sum={
              cards1:{max:this.cards1.maxnum,num:0},
              cards3:{max:this.cards3.maxnum,num:0},
            }
            let datas={cards1:[],cards3:[]}      
            for (let i in data1) {
              let item = data1[i];
              this.CourseIf2(item)
              if (
                item.CA_ISNEW != "1" &&
                item.CA_ISNOT != "1" &&
                item.CA_ISBANNERS != "1"
              ) {
                continue;
              }
              if(this.IsNull(item.CA_IMAGE_URL)!=''){
                files.push(item.CA_IMAGE_URL);
              }
              
              item.JS_BETIME=''
              let date1=['',''];
              if (this.IsNull(item.CA_BEGIN_TIME) != "") {
                date1[0] = this.Str_Replace(
                  this.str2date3(item.CA_BEGIN_TIME),
                  "/",
                  "-"
                );
              }
              if (this.IsNull(item.CA_END_TIME) != "") {
                date1[1] = this.Str_Replace(
                  this.str2date3(item.CA_END_TIME),
                  "/",
                  "-"
                );
              }
              item.JS_BETIME=date1.join('~')
              item.JS_HASHTAGs=[]
              if (this.IsNull(item.CA_HASHTAG_ID) != "") {
                let tag1 = item.CA_HASHTAG_ID.split(",");
                for (let j in tag1) {
                  item.JS_HASHTAGs.push({
                    style1: "primary",
                    class: "mr-1",
                    text: tag1[j],
                  });
                }
              }
              item['CA_NEW_SORT']=this.IsNum(item['CA_NEW_SORT'])
              item['CA_HOT_SORT']=this.IsNum(item['CA_HOT_SORT'])
              if(item.CA_ISNEW == "1"){
                datas['cards1'].push(item)
              }
              if(item.CA_ISNOT == "1"){
                datas['cards3'].push(item)
              }
            }


            datas['cards1'].sort((a,b)=>{
              return a['CA_NEW_SORT']-b['CA_NEW_SORT']
            })
            datas['cards3'].sort((a,b)=>{
              return a['CA_HOT_SORT']-b['CA_HOT_SORT']
            })

            if(datas['cards1'].length<=sum['cards1'].max){
              for(let i in datas['cards1']){
                let item=datas['cards1'][i]
                this.cards1.data[item.CP_ID] = item;
              }
            }else {
              for(let i=0;i<sum['cards1'].max;i++){
                let item=datas['cards1'][i]
                this.cards1.data[item.CP_ID] = item;
              }
            }
            if(datas['cards3'].length<=sum['cards3'].max){
              for(let i in datas['cards3']){
                let item=datas['cards3'][i]
                this.cards3.data[item.CP_ID] = item;
              }
            }else {
              for(let i=0;i<sum['cards3'].max;i++){
                let item=datas['cards3'][i]
                this.cards3.data[item.CP_ID] = item;
              }
            }
            if (files.length != 0) {
              this.FunctionToken(this.FunctionGetArchive, {
                MC_ID: files,
              });
            }
          }
        })
        .catch((err) => {
          console.log(err);
        });
    },
    FunctionGetListSeries(data_in) {
      this.apiGetListSeries(data_in) 
        .then(res => {
          let json=JSON.parse(res.data)
          if(json.Status){
            let data1=JSON.parse(json.Data)
            let sum={
              cards2:{max:this.cards2.maxnum,num:0}
            }
            let files=[]
            for(let i in data1){
              let item=data1[i]
              if(this.IsNull(item.SS_MCID)!=''){
                files.push(item.SS_MCID)
              }
              if(sum['cards2'].num<sum['cards2'].max){
                this.cards2.data[item.SS_ID]=item
                sum['cards2'].num+=1
              }
            }
            if (files.length != 0) {
              this.FunctionToken(this.FunctionGetArchive, {
                MC_ID: files,
              });
            }
          }
        })
        .catch((err) => {
          this.Elog(err);
        });
    },
    FunctionGetNewsIndex(data_in) {
      this.apiGetNewsIndex(data_in)
        .then((res) => {
          let json = JSON.parse(res.data);
          if (json.Status) {
            let newsData = JSON.parse(json.Data);
            let data1=[]
            let data2=[]
            for(let i in newsData){
              let item=newsData[i]   
              item.JS_DATETIME=0
              if(this.IsNull(item.NE_DATE)!=''){
                let date1=this.date2str(new Date(item.NE_DATE))
                item.JS_DATE=date1[0]+'年'+date1[1]+'月'+date1[2]+'日'
                item.JS_DATETIME=Number(new Date(item.NE_DATE))
              }
              if(item.NE_ISTIOP==1){
                data1.push(item)
              }else {
                data2.push(item)
              }
            }       
            data1.sort((a,b)=>{return -1*(a['JS_DATETIME']-b['JS_DATETIME'])})
            data2.sort((a,b)=>{return -1*(a['JS_DATETIME']-b['JS_DATETIME'])})
            let data3=data1.concat(data2)
            let imgsUrl = [];
            let sum={
              news:{max:this.news.maxnum,num:0},
            }
            for(let i in data3){
              let item=data3[i]
              if(sum['news'].num<sum['news'].max){
                this.news.data[item.NE_ID]=item
                sum['news'].num+=1
                if(this.IsNull(item.NE_MCID)!=''){
                  imgsUrl.push(item.NE_MCID)
                }
              }
            }
            if (imgsUrl.length != 0) {
              this.FunctionToken(this.FunctionGetArchive, {
                MC_ID: imgsUrl,
              });
            }          
          }
        })
        .catch((err) => {
          this.Elog(err);
        });
    },

    FunctionGetArchive(data_in,resolve) {
      this.apiGetArchive(data_in)
        .then((res) => {
          let json = JSON.parse(res.data);
          if (json.Status) {
            let data1 = JSON.parse(json.Data);
            let MCIDs=[]
            for (let i in data1) {
              let item = data1[i];
              this.files[item.MC_ID] = encodeURI([item.SP_PATH_WEB, item.MC_FILE].join(""));
              MCIDs.push(item.MC_ID)
            }      
            if(this.IsNullKeys(data_in,['JSval','photos'])!=''){
              this.mainSplide.photos=data_in.JSval.photos             
            }
            this._watcher.run();
          }
          if (this.IsNull(resolve) != "") {
            resolve("ok");
          }
        })
        .catch((err) => {
          console.log(err);
        });
    },
  },
};
</script>

<style lang="scss" scoped>

.loading {
  display: inline-block;
  color: white;
  animation-name: example;
  animation-duration: 4s;
}
.loading span {
  display: inline-block;
  margin: 0 -.075em;
  animation: loading 0.8s infinite alternate;
  padding: 0.25rem;
  border-radius: 8px;
  text-shadow: 0 0 4px rgba($color: #000000, $alpha: 0.45);
  font-weight: bold;
}
.loading span:nth-child(2) {
  animation-delay: .2s;
}
.loading span:nth-child(3) {
  animation-delay: .4s;
}
.loading span:nth-child(4) {
  animation-delay: .6s;
}
.loading span:nth-child(5) {
  animation-delay: .8s;
}
.loading span:nth-child(6) {
  animation-delay: .10s;
}
@keyframes loading {
  0% {
    transform: scale(1);
    background-color: #fc4a1a;
  }
  100% {
    transform: scale(0.8);
    background-color: #f7b733;
  }
}

</style>