<template>
   <b-nav class="mb-3 memberMenu" pills justified>
      <b-nav-item to="myRegistration">報名表</b-nav-item>
      <b-nav-item to="profile">基本資料</b-nav-item>
      <!-- <b-nav-item>課程紀錄 </b-nav-item> -->
      <b-nav-item @click="sessionF1('/')">登出</b-nav-item>
   </b-nav>
</template>
<script>
export default {
   data() {
      return {
         page:this.$route.path
      }
   },
   mounted(){

   },
   methods:{
      sessionF1(url){
         if(url=='/'){
            let data1=this._storeData('Header_form1')
            if(this.IsNull(data1)!=''){
               data1.text='學員登入'
            }
            this._sessionData('TokenID','')
            this._go('/')
         }
      },
   }
}
</script>
