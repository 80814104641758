<template>
  <div>


    <header style="background-color:#f5f5f5">
      <b-container class="text-center">
        <b-img :src="IsNull(files[data.SS_MCID])!=''?files[data.SS_MCID]:'https://fakeimg.pl/1440x540/?retina=1&text=主圖（系列課程列表）&font=noto'   " fluid
        ></b-img>
      </b-container>
    </header>


    <b-container>
      <div class="py-5" v-html="IsNull(data.SS_CONTENT)">
      </div>
      <h4>課程列表</h4>
        <b-row
          cols="1"
          class="courses list"
          v-for="(i2,i1) in courses.data" 
          :key="i1"

        >
          <b-col class="mb-4">
            <b-card
              img-alt="Image"
              tag="article"
              class="course"
              img-left
              no-body
            >
              <b-link class="mainImage" style="width:400px" @click="TableGo(i2.CP_ID)" >
                <b-card-img
                  :src="IsNull(files[i2.CA_IMAGE_URL],' https://fakeimg.pl/1280x800/?retina=1&text=主圖（系列課程列表）&font=noto')" 
                ></b-card-img>
              </b-link>
              <b-card-body>
                <b-card-title>
                  {{IsNull(i2.CS_TITLE)}}<br>
                <small v-html="Re_html(i2.CA_ABSTRACT)"></small>

                </b-card-title>
                
                <b-card-sub-title class="mb-2" style="position: static;">
                  <p v-show="false">
                  <template v-for="(j2,j1) in i2.courseStatus">
                     <b-badge  :key="j1"
                     v-bind="IsNull(courseStatusStyle[IsNull(j2)],courseStatusStyle[''])" >
                      {{IsNull(j2)}}</b-badge>
                  </template>
                  </p> 

                  <p>
                  <template v-for="(j2,j1) in IsNull(i2.CA_HASHTAG_ID)==''?[]:i2.CA_HASHTAG_ID.split(',')">
                     <b-badge class="mr-1" variant="primary" :key="j1">
                      {{IsNull(j2)}}</b-badge>
                  </template>
                  </p> 
                </b-card-sub-title>
                <ul class="list-unstyled price">
                  <li>
                    課程原價 <span class="number" style="text-decoration:line-through;">{{IsNull(i2.CA_ORIGIN_TUITION)}}</span>
                    、課程費用 <span class="number">{{IsNull(i2.CA_TUITION)}}</span> 
                  </li>
                  <li v-if="i2.CA_IS_GROUP">
                    團體報名 
                    <span class="number">{{IsNull(i2.CA_GROUP_TUITION)}}</span>
                  </li>
                </ul>
                <b-card-text class="description" v-show="false">課程資訊</b-card-text>
                <ul class="list-unstyled mb-0" v-show="false">
                  <li>授課教師：{{i2.JSteacher.join(',')}}</li>
                  <li>開課日期：{{[IsNull(i2.CA_BEGIN_TIME),IsNull(i2.CA_END_TIME)].join('~')}}</li>
                  <li>上課時數：{{IsNull(i2.CA_HOURS)}}</li>
                  <li>課程類別：
                    <span v-if="i2.CA_IS_ENTRUS">委訓合作</span>
                    <span v-if="i2.CS_IS_CREDIT">學分班</span>
                    <span v-else>非學分班</span>
                  </li>
                  <li>學分{{ IsNull(i2.CS_CREDIT)}}</li>
                  <li
                  v-if="i2.CA_IS_BEGIN_DATE && i2.CA_OPEN"
                  >開放報名：{{ turnDate(i2.CA_BEGIN_DATE) }}</li>
                  <li
                  v-if="i2.CA_IS_END_DATE && i2.CA_OPEN"
                  >截止報名：{{turnDate(i2.CA_END_DATE) }}</li>
                </ul>
              </b-card-body>
              <div class="signin" v-if="i2.canSignin">
                <b-button  block class="mr-1" @click="butGo(0,i2)">我要報名</b-button>
                <b-button v-if=" i2.CA_IS_GROUP" @click="butGo(1,i2)" block>團體報名</b-button>
              </div>
            </b-card>
          </b-col>
        </b-row>

        
    </b-container>


  </div>
</template>

<script>
export default {
  name:'CourseSeriesDetails',
  data(){
    return{
       courseStatusStyle:{
         '':{class:'mr-1',variant:'primary'},
        '規劃中':{class:'mr-1',variant:'primary'},
        '已截止':{class:'mr-1',variant:'primary'},
        '招生中':{class:'mr-1',variant:'primary'},
        '已成班':{class:'mr-1',variant:'primary'},
        '開課中':{class:'mr-1',variant:'primary'},
        '早鳥優惠':{class:'mr-1',variant:'primary'},
        '仍可報名':{class:'mr-1',variant:'primary'},
        '已結束':{class:'mr-1',variant:'primary'},
        '最新課程':{class:'mr-1',variant:'primary'},
        '熱門課程':{class:'mr-1',variant:'danger'},
      },
      files:{},
      data_in:{},
      data:{},
      courses:{
        data:{},
      },
    }
  },
  mounted(){
    let navBgSwitch=this._storeData('navBgSwitch')
    if(this.IsNull(navBgSwitch)!=''){
      navBgSwitch(false)
    }
    this.sessionF1()
    this.FunctionToken(this.FunctionGetListSeries, {});
    
  },
  methods:{
    sessionF1(url,data){
      if(this.IsNull(url)==''){
        let data1=this._go2({key:'SS_ID'},'query')
        if(this.IsNull(data1)!=''){
          this.data_in.SS_ID=data1
          this.FunctionToken(this.FunctionGetListSeriesCourseIndex_mini,{
            SC_SSID:this.data_in.SS_ID
          })
        }else{
          this._go('/')
        }
      }else if(url=='course'){
        let url=this.LocaPath.default+'course?CP_ID={CP_ID}'
        this._go1(this.Str_Replace(url,'{CP_ID}',data),1)
      }else if(url=='courseSignup'){
        this._go2({
          path:'/courseSignup',
          data:{CP_ID: data.CP_ID, RF_IS_GROUP: data.RF_IS_GROUP}
        },'query')
      }
    },
    // 日期樣態調整
    turnDate: function(date) {
      let newDate = new Date( date );
      return newDate.getFullYear()+ "年" + (newDate.getMonth()+1) + "月" + newDate.getDate() + "日"
    },
    // 來去報名
    butGo(type, data) {
       if(data.canSignin==false){
        alert('未開放報名')
        return
      }
      if (this.IsNull(this._sessionData("TokenID")) == "") {
        //alert("尚未登入");
        let fun1=this._storeData('LoginF1')
        if(this.IsNull(fun1)!=''){
          let path1=this.$route.path
          this._storeData('LoginFUN',(path2,err)=>{
            if(path1==path2){
              this.sessionF1("courseSignup", {
                CP_ID:data.CP_ID,
                RF_IS_GROUP:type
              });
            }else if(this.IsNull(err)!=''){
              err();
            }
            this._storeData('LoginFUN','')
          })
          fun1()
        }
        return;
      }
    
      this.sessionF1("courseSignup", {
        CP_ID:data.CP_ID,
        RF_IS_GROUP:type
      });

    },
    // 來去看課程詳細資訊
    TableGo(data) {
      this.sessionF1("course", data);
    },

    Re_html(val){
      if(this.IsNull(val)===''){
        return ''
      }

      return this.Str_Replace2(val,[{old:'\r\n',new:'<br>'},{old:'\n',new:'<br>'}])
      
    },


    //====================================================================
    //api

    FunctionGetListSeries(data_in){
      this.apiGetListSeries(data_in)
      .then(res=>{
        let json=JSON.parse(res.data)
        if(json.Status){
          let data1=JSON.parse(json.Data)
          let files=[]
          for(let i in data1){
            let item=data1[i]
            if(item.SS_ID==this.data_in.SS_ID){
              this.data=item
              if(this.IsNull(item.SS_MCID)!=''){
                files.push(item.SS_MCID)
              }
            }
          }
          if(files.length!=0){
            this.FunctionToken(this.FunctionGetArchive, {
                MC_ID: files,
            });
          }
        }
      })
      .catch(err=>{
        this.Elog(err)
      })
    },

    FunctionGetListSeriesCourseIndex_mini(data_in){
      this.apiGetListSeriesCourseIndex_mini(data_in)
      .then(res=>{
        let json=JSON.parse(res.data)
        if(json.Status){
          let data1=JSON.parse(json.Data)
          this.Clog('sss')
          let imgUrl = [];
          for(let i in data1){
            let item=data1[i]
            if(this.IsNull(item.CA_IMAGE_URL)!=''){
              imgUrl.push(item.CA_IMAGE_URL)
            }
            this.CourseIf2(item)
            item.JSteacher=[]
            if(this.IsNull(item.PROFESSOR)!=''){
              for(let j in item.PROFESSOR){
                item.JSteacher.push(this.IsNull(item.PROFESSOR[j].PROF_NAME))
              }
            }
            this.courses.data[item.CP_ID]=item
          }

          if(imgUrl.length!=0){
            this.FunctionToken(this.FunctionGetArchive, {
                MC_ID: imgUrl,
            });
          }

        }
      })
      .catch(err=>{
        this.Elog(err)
      })
    },

    FunctionGetListSeriesCourse(data_in){
      this.apiGetListSeriesCourse(data_in)
      .then(res=>{
        let json=JSON.parse(res.data)
        if(json.Status){
          let data1=JSON.parse(json.Data)
          this.Clog(data1)
        }
      })
      .catch(err=>{
        this.Elog(err)
      })
    },

    FunctionGetArchive(data_in) {
      this.apiGetArchive(data_in)
        .then((res) => {
          let json = JSON.parse(res.data);
          if (json.Status) {
            let data1 = JSON.parse(json.Data);
            for (let i in data1) {
              let item = data1[i];
              this.files[item.MC_ID] = [item.SP_PATH_WEB, item.MC_FILE].join("");
            }
            
            this._watcher.run();
          }
        })
        .catch((err) => {
          console.log(err);
        });
    },
  }
}
</script>


<style lang="scss" scoped>
.courses {
  align-content: stretch;
  .mainImage {
    .card-img {
      aspect-ratio: 16 / 9;
      max-width: 400px;
    }
  }
  .description {
    overflow:hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    white-space: normal;
    margin-bottom: 1rem;
  }
  .price {
    display: flex;
    li {
      margin-right: 1rem ;
      .number {
        color: #e73827;
        font-size: 1.3rem;
        font-weight: bold;
      }
    }
  }

  &.list {
    
    .course {
      border-width: 0 0 1px 0;
      flex:1 2 200px;
      .card-body {
        padding:  0rem 2rem 1.5rem 2rem;
      }
    }
    .mainImage {
      .card-img {
        width: 400px;
      }
    }
    .signin {
      white-space: nowrap;
    }
  }

  &.grid {
    .description {
      display: none;
    }
    .end {
      display: none;
    }
    .begin-signin {
      display: none;
    }
    .end-signin {
      display: none;
    }
    .signin {
      display: none;
    }
  }
  .course {
    height: 100%;
  }
}

.list {
  .card-img-left {
    height: 200px;
  }
}
</style>

