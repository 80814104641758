
<template>

    <div v-if="type=='text'"> 
        <input  type="text" 
        :id="IsNull(data_in.id)"
        :class="(IsNull(data_in.msg)==''?'form-control':data_in.msg.type? 'form-control is-valid':'form-control is-invalid')+' '+IsNull(data_in.class)"  
        :style="data_in.style"
        v-model="data_in.val" :placeholder="data_in.text" :disabled="data_in.only"
        @change="evenF1('change',data_in,$event)" 
        @click="evenF1('click',data_in,$event)"
        @keypress="evenF1('keypress',data_in,$event)"
        />
        <div class="valid-feedback" >
            <template v-for="(i2,i1) in ((IsNull(data_in.msg)=='' || IsNull(data_in.msg.texts)=='')?[]:data_in.msg.texts)">
                {{i2}}<br v-show="i1!=data_in.msg.texts.length-1" :key="i1+'_valid'">
            </template>
        </div>
        <div class="invalid-feedback" >
            <template v-for="(i2,i1) in ((IsNull(data_in.msg)=='' || IsNull(data_in.msg.texts)=='')?[]:data_in.msg.texts)">
                {{i2}}<br v-show="i1!=data_in.msg.texts.length-1" :key="i1+'_invalid'">
            </template>
        </div>
    </div>
    


    <div v-else-if="type=='password'">
        <input type="password"  
        :class="(IsNull(data_in.msg)==''?'form-control':data_in.msg.type? 'form-control is-valid':'form-control is-invalid')+' '+IsNull(data_in.class)"
        :style="data_in.style"
        v-model="data_in.val" :placeholder="data_in.text" :disabled="data_in.only"
        @change="evenF1('change',data_in,$event)" 
        @click="evenF1('click',data_in,$event)"
        @keypress="evenF1('keypress',data_in,$event)"
        />
        <div class="valid-feedback" >
            <template v-for="(i2,i1) in ((IsNull(data_in.msg)=='' || IsNull(data_in.msg.texts)=='')?[]:data_in.msg.texts)">
                {{i2}}<br v-show="i1!=data_in.msg.texts.length-1" :key="i1+'_valid'">
            </template>
        </div>
        <div class="invalid-feedback" >
            <template v-for="(i2,i1) in ((IsNull(data_in.msg)=='' || IsNull(data_in.msg.texts)=='')?[]:data_in.msg.texts)">
                {{i2}}<br v-show="i1!=data_in.msg.texts.length-1" :key="i1+'_invalid'">
            </template>
        </div>
    </div>


    <div v-else-if="type=='text-ap'">
        <div  :class="IsNull(data_in.msg)==''?'input-group':data_in.msg.type? 'input-group is-valid':'input-group is-invalid'"
           
        >
            <div class="input-group-prepend" v-if="IsNull(data_in.textL)!=''">
                <span class="input-group-text" >{{data_in.textL}}</span>
            </div>

            <input type="text" :class="IsNull(data_in.msg)==''?'form-control':data_in.msg.type? 'form-control is-valid':'form-control is-invalid'"
            :style="data_in.style"  
            v-model="data_in.val" :placeholder="data_in.text" :disabled="data_in.only"
              @change="evenF1('change',data_in,$event)" 
            @click="evenF1('click',data_in,$event)"
            @keypress="evenF1('keypress',data_in,$event)"
            >
            
            <div class="input-group-append" v-if="IsNull(data_in.textR)!=''">
                <span class="input-group-text" >{{data_in.textR}}</span>
            </div>
        </div>

        <div class="valid-feedback" >
            <template v-for="(i2,i1) in ((IsNull(data_in.msg)=='' || IsNull(data_in.msg.texts)=='')?[]:data_in.msg.texts)">
                {{i2}}<br v-show="i1!=data_in.msg.texts.length-1" :key="i1+'_valid'">
            </template>
        </div>

        <div class="invalid-feedback" >
            <template v-for="(i2,i1) in ((IsNull(data_in.msg)=='' || IsNull(data_in.msg.texts)=='')?[]:data_in.msg.texts)">
                {{i2}}<br v-show="i1!=data_in.msg.texts.length-1" :key="i1+'_invalid'">
            </template>
        </div>

    </div>

    <div v-else-if="type=='text-ap2'">
        <div  :class="IsNull(data_in.msg)==''?'input-group':data_in.msg.type? 'input-group is-valid':'input-group is-invalid'"
           
        >
            <div class="input-group-prepend" v-if="hasSlot('p')">
                <slot name="p"></slot>
            </div>

            <input type="text" :class="IsNull(data_in.msg)==''?'form-control':data_in.msg.type? 'form-control is-valid':'form-control is-invalid'"
            :style="data_in.style"  
            v-model="data_in.val" :placeholder="data_in.text" :disabled="data_in.only"
              @change="evenF1('change',data_in,$event)" 
            @click="evenF1('click',data_in,$event)"
            @keypress="evenF1('keypress',data_in,$event)"
            >
            
            <div class="input-group-append" v-if="hasSlot('a')">
                <slot name="a"></slot>
            </div>
        </div>

        <div class="valid-feedback" >
            <template v-for="(i2,i1) in ((IsNull(data_in.msg)=='' || IsNull(data_in.msg.texts)=='')?[]:data_in.msg.texts)">
                {{i2}}<br v-show="i1!=data_in.msg.texts.length-1" :key="i1+'_valid'">
            </template>
        </div>

        <div class="invalid-feedback" >
            <template v-for="(i2,i1) in ((IsNull(data_in.msg)=='' || IsNull(data_in.msg.texts)=='')?[]:data_in.msg.texts)">
                {{i2}}<br v-show="i1!=data_in.msg.texts.length-1" :key="i1+'_invalid'">
            </template>
        </div>

    </div>


    



        <b-form-checkbox  v-else-if="type=='b-checkbox'"
        :class="IsNull(data_in .msg)==''?'':data_in.msg.type? 'is-valid':'is-invalid'"
        v-model="data_in.val" :disabled="data_in.only"
        @change="evenF1('change',data_in,$event)" 
        @click="evenF1('click',data_in,$event)"
        @keypress="evenF1('keypress',data_in,$event)"
        > {{data_in.text}}
        </b-form-checkbox>
       
   

    <div v-else-if="type=='select'">
        <select  
        :id="IsNull(data_in.id)"
        v-model="data_in.val" 
        :class="(IsNull(data_in.msg)==''?'form-control':data_in.msg.type? 'form-control is-valid':'form-control is-invalid')+' '+data_in.class"
        :style="data_in.style" 
        :disabled="data_in.only"
        @change="evenF1('change',data_in,$event)" 
        @click="evenF1('click',data_in,$event)"
        @keypress="evenF1('keypress',data_in,$event)"
        >
            <option v-show="IsNull(data_in.text)!=''" value="">{{data_in.text}}</option>
            <option v-for="(i2,i1) in data_in.options" :key="i1" :value="i2.val">{{i2.text}}</option>
        </select>
        <div class="valid-feedback" >
            <template v-for="(i2,i1) in ((IsNull(data_in.msg)=='' || IsNull(data_in.msg.texts)=='')?[]:data_in.msg.texts)">
                {{i2}}<br v-show="i1!=data_in.msg.texts.length-1" :key="i1+'_valid'">
            </template>
        </div>
        <div class="invalid-feedback" >
            <template v-for="(i2,i1) in ((IsNull(data_in.msg)=='' || IsNull(data_in.msg.texts)=='')?[]:data_in.msg.texts)">
                {{i2}}<br v-show="i1!=data_in.msg.texts.length-1" :key="i1+'_invalid'">
            </template>
        </div>
    </div>


    <div v-else-if="type=='select2'">
        <select  
        :id="IsNull(data_in.id)"
        v-model="data_in.val" 
        :class="(IsNull(data_in.msg)==''?'custom-select':data_in.msg.type? 'custom-select is-valid':'form-control is-invalid')+' '+data_in.class"
        :style="data_in.style" 
        :disabled="data_in.only"
        @change="evenF1('change',data_in,$event)" 
        @click="evenF1('click',data_in,$event)"
        @keypress="evenF1('keypress',data_in,$event)"
        >
            <option v-show="IsNull(data_in.text)!=''" value="">{{data_in.text}}</option>
            <option v-for="(i2,i1) in data_in.options" :key="i1" :value="i2.val">{{i2.text}}</option>
        </select>
        <div class="valid-feedback" >
            <template v-for="(i2,i1) in ((IsNull(data_in.msg)=='' || IsNull(data_in.msg.texts)=='')?[]:data_in.msg.texts)">
                {{i2}}<br v-show="i1!=data_in.msg.texts.length-1" :key="i1+'_valid'">
            </template>
        </div>
        <div class="invalid-feedback" >
            <template v-for="(i2,i1) in ((IsNull(data_in.msg)=='' || IsNull(data_in.msg.texts)=='')?[]:data_in.msg.texts)">
                {{i2}}<br v-show="i1!=data_in.msg.texts.length-1" :key="i1+'_invalid'">
            </template>
        </div>
    </div>


    <div v-else-if="type=='T-select'">
        <select  
        v-model="data_in.page" 
        :class="(IsNull(data_in.TNum.msg)==''?'form-control':data_in.TNum.msg.type? 'form-control is-valid':'form-control is-invalid')+' '+data_in.TNum.class"
        :style="data_in.TNum.style" 
        :disabled="data_in.TNum.only"
        @change="evenF1('change',data_in.TNum,$event)" 
        @click="evenF1('click',data_in.TNum,$event)"
        @keypress="evenF1('keypress',data_in.TNum,$event)"
        >
            <option v-show="IsNull(data_in.TNum.text)!=''" value="">{{data_in.TNum.text}}</option>
            <option v-for="(i2,i1) in data_in.TNum.options" :key="i1" :value="i2.val">{{i2.text}}</option>
        </select>
        <div class="valid-feedback" >
            <template v-for="(i2,i1) in ((IsNull(data_in.TNum.msg)=='' || IsNull(data_in.TNum.msg.texts)=='')?[]:data_in.TNum.msg.texts)">
                {{i2}}<br v-show="i1!=data_in.TNum.msg.texts.length-1" :key="i1+'_valid'">
            </template>
        </div>
        <div class="invalid-feedback" >
            <template v-for="(i2,i1) in ((IsNull(data_in.TNum.msg)=='' || IsNull(data_in.TNum.msg.texts)=='')?[]:data_in.TNum.msg.texts)">
                {{i2}}<br v-show="i1!=data_in.TNum.msg.texts.length-1" :key="i1+'_invalid'">
            </template>
        </div>
    </div>



    <div v-else-if="type=='textarea'">
        <textarea  
        :id="IsNull(data_in.id)"
        :class="(IsNull(data_in.msg)==''?'form-control':data_in.msg.type? 'form-control is-valid':'form-control is-invalid')+' '+data_in.class"
        :style="data_in.style"
        v-model="data_in.val" :placeholder="data_in.text" :disabled="data_in.only" :rows="data_in.rows"
        @change="evenF1('change',data_in,$event)" 
        @click="evenF1('click',data_in,$event)"
        @keypress="evenF1('keypress',data_in,$event)"
        ></textarea>
        <div class="valid-feedback" >
            <template v-for="(i2,i1) in ((IsNull(data_in.msg)=='' || IsNull(data_in.msg.texts)=='')?[]:data_in.msg.texts)">
                {{i2}}<br v-show="i1!=data_in.msg.texts.length-1" :key="i1+'_valid'">
            </template>
        </div>
        <div class="invalid-feedback" >
            <template v-for="(i2,i1) in ((IsNull(data_in.msg)=='' || IsNull(data_in.msg.texts)=='')?[]:data_in.msg.texts)">
                {{i2}}<br v-show="i1!=data_in.msg.texts.length-1" :key="i1+'_invalid'">
            </template>
        </div>
     </div>



    <div v-else-if="type=='file'">
        <div  :class="IsNull(data_in.msg)==''?'input-group':data_in.msg.type? 'input-group is-valid':'input-group is-invalid'"
        @click="fileClick(data_in)">

            <input type="text" :class="IsNull(data_in.msg)==''?'form-control':data_in.msg.type? 'form-control is-valid':'form-control is-invalid'"
            disabled :style=" data_in.only?'':'background-color: #fff;'" v-model="data_in.name"  >
            
            <div class="input-group-append">
                <span class="input-group-text" >{{data_in.text}}</span>
            </div>
        </div>
        <div class="valid-feedback" >
            <template v-for="(i2,i1) in ((IsNull(data_in.msg)=='' || IsNull(data_in.msg.texts)=='')?[]:data_in.msg.texts)">
                {{i2}}<br v-show="i1!=data_in.msg.texts.length-1" :key="i1+'_valid'">
            </template>
        </div>
        <div class="invalid-feedback" >
            <template v-for="(i2,i1) in ((IsNull(data_in.msg)=='' || IsNull(data_in.msg.texts)=='')?[]:data_in.msg.texts)">
                {{i2}}<br v-show="i1!=data_in.msg.texts.length-1" :key="i1+'_invalid'">
            </template>
        </div>
        <input type="file" :id="data_in.id" v-show="false" @change="fileChange($event,data_in)" >
    </div>


    
    <div v-else-if="type=='b-file'">
        <b-input-group @click="fileClick(data_in)"
        @keypress="evenF1('keypress',data_in,$event)"
        >
            <template #append>
                <b-input-group-text>{{data_in.text}}</b-input-group-text>
            </template>
            <b-form-input disabled :style=" data_in.only?'':'background-color: #fff;'" v-model="data_in.name" ></b-form-input>
        </b-input-group>
        <input type="file" :id="data_in.id" v-show="false" @change="fileChange($event,data_in)" >
    </div>


    <div v-else-if="type=='date'">
        <input  type="date" 
        :id="IsNull(data_in.id)"
        :class="(IsNull(data_in.msg)==''?'form-control':data_in.msg.type? 'form-control is-valid':'form-control is-invalid')+' '+data_in.class"
        :style="data_in.style"
            v-model="data_in.val"  :disabled="data_in.only"
            @change="evenF1('change',data_in,$event)" 
            @click="evenF1('click',data_in,$event)"
            @keypress="evenF1('keypress',data_in,$event)">
        <div class="valid-feedback" >
            <template v-for="(i2,i1) in ((IsNull(data_in.msg)=='' || IsNull(data_in.msg.texts)=='')?[]:data_in.msg.texts)">
                {{i2}}<br v-show="i1!=data_in.msg.texts.length-1" :key="i1+'_valid'">
            </template>
        </div>
        <div class="invalid-feedback" >
            <template v-for="(i2,i1) in ((IsNull(data_in.msg)=='' || IsNull(data_in.msg.texts)=='')?[]:data_in.msg.texts)">
                {{i2}}<br v-show="i1!=data_in.msg.texts.length-1" :key="i1+'_invalid'">
            </template>
        </div>
    </div>
    
    <b-form-radio-group  v-else-if="type=='b-radio-group'" 
        :state="IsNull(data_in.msg)==''?null:data_in.msg.type"
        v-model="data_in.val" :options="data_in.options" :disabled="data_in.only" 
        @change="evenF1('change',data_in,$event)" 
        @click="evenF1('click',data_in,$event)"
        @keypress="evenF1('keypress',data_in,$event)"
         :stacked="data_in.stacked"
        :buttons="data_in.buttons"
         :buttonVariant="data_in.buttonVariant"
        >

        <b-form-valid-feedback 
        :state="IsNull(data_in.msg)==''?null:data_in.msg.type">
            <template v-for="(i2,i1) in ((IsNull(data_in.msg)=='' || IsNull(data_in.msg.texts)=='')?[]:data_in.msg.texts)">
                {{i2}}<br v-show="i1!=data_in.msg.texts.length-1" :key="i1+'_valid'">
            </template>
        </b-form-valid-feedback>
        <b-form-invalid-feedback 
        :state="IsNull(data_in.msg)==''?null:data_in.msg.type" >
            <template v-for="(i2,i1) in ((IsNull(data_in.msg)=='' || IsNull(data_in.msg.texts)=='')?[]:data_in.msg.texts)">
                {{i2}}<br v-show="i1!=data_in.msg.texts.length-1" :key="i1+'_invalid'">
            </template>
        </b-form-invalid-feedback>
        
    </b-form-radio-group>


    <b-form-checkbox v-else-if="type=='b-switch'" 
    :id="IsNull(data_in.id)"
    v-model="data_in.val"  switch :disabled="data_in.only"
     @change="evenF1('change',data_in,$event)" 
     @click="evenF1('click',data_in,$event)"
     @keypress="evenF1('keypress',data_in,$event)"
     >{{data_in.text}}</b-form-checkbox>


    <img v-else-if="type=='img'" :src="data_in.src" 
                    :width="data_in.width" :height="data_in.height" 
                    :title="data_in.title" :alt="data_in.alt"
     @change="evenF1('change',data_in,$event)" 
     @click="evenF1('click',data_in,$event)"
     @keypress="evenF1('keypress',data_in,$event)"
     >

     <b-form-checkbox-group  v-else-if="type=='b-checkbox-group'" 
        name="checkbox-validation"
        :state="IsNull(data_in.msg)==''?null:data_in.msg.type"
        v-model="data_in.val" :options="data_in.options" :disabled="data_in.only" 
        :stacked="data_in.stacked"
        @change="evenF1('change',data_in,$event)" 
        @click="evenF1('click',data_in,$event)"
        @keypress="evenF1('keypress',data_in,$event)"
        >
        <b-form-valid-feedback 
        :state="IsNull(data_in.msg)==''?null:data_in.msg.type">
            <template v-for="(i2,i1) in ((IsNull(data_in.msg)=='' || IsNull(data_in.msg.texts)=='')?[]:data_in.msg.texts)">
                {{i2}}<br v-show="i1!=data_in.msg.texts.length-1" :key="i1+'_valid'">
            </template>
        </b-form-valid-feedback>
        <b-form-invalid-feedback 
        :state="IsNull(data_in.msg)==''?null:data_in.msg.type" >
            <template v-for="(i2,i1) in ((IsNull(data_in.msg)=='' || IsNull(data_in.msg.texts)=='')?[]:data_in.msg.texts)">
                {{i2}}<br v-show="i1!=data_in.msg.texts.length-1" :key="i1+'_invalid'">
            </template>
        </b-form-invalid-feedback>
    </b-form-checkbox-group>


    <b-form-checkbox-group  v-else-if="type=='b-switch-group'" 
        :size="data_in.size"
        :state="IsNull(data_in.msg)==''?null:data_in.msg.type"
        v-model="data_in.val" :options="data_in.options" :disabled="data_in.only" 
        @change="evenF1('change',data_in,$event)" 
        @click="evenF1('click',data_in,$event)"
        @keypress="evenF1('keypress',data_in,$event)"
        switches
        :stacked="data_in.stacked"
        >
        <b-form-valid-feedback 
        :state="IsNull(data_in.msg)==''?null:data_in.msg.type">
            <template v-for="(i2,i1) in ((IsNull(data_in.msg)=='' || IsNull(data_in.msg.texts)=='')?[]:data_in.msg.texts)">
                {{i2}}<br v-show="i1!=data_in.msg.texts.length-1" :key="i1+'_valid'">
            </template>
        </b-form-valid-feedback>
        <b-form-invalid-feedback 
        :state="IsNull(data_in.msg)==''?null:data_in.msg.type" >
            <template v-for="(i2,i1) in ((IsNull(data_in.msg)=='' || IsNull(data_in.msg.texts)=='')?[]:data_in.msg.texts)">
                {{i2}}<br v-show="i1!=data_in.msg.texts.length-1" :key="i1+'_invalid'">
            </template>
        </b-form-invalid-feedback>
    </b-form-checkbox-group>

    <div v-else-if="type=='datepicker'">
        <b-form-datepicker 
        :id="data_in.id"        :state="IsNull(data_in.msg)==''?null:data_in.msg.type"
         v-model="data_in.val" :disabled="data_in.only"  :placeholder="IsNull(data_in.text)"
        :size="data_in.size" :locale="IsNull(data_in.loca)==''?'zh':data_in.loca"
        :date-format-options="data_in.format"
        :show-decade-nav="IsNull(data_in.year10)==''?false:data_in.year10" 
        :hide-header="IsNull(data_in.headShow)==''?false:!data_in.headShow"
        

        v-bind="dateSet"
        @context="evenF1('context',data_in,$event)"
        @change="evenF1('change',data_in,$event)" 
        @click="evenF1('click',data_in,$event)"
        @keypress="evenF1('keypress',data_in,$event)"
        ></b-form-datepicker>
        <div class="valid-feedback"  >
            <template v-for="(i2,i1) in ((IsNull(data_in.msg)=='' || IsNull(data_in.msg.texts)=='')?[]:data_in.msg.texts)">
                {{i2}}<br v-show="i1!=data_in.msg.texts.length-1" :key="i1+'_valid'">
            </template>
        </div>

        <div class="invalid-feedback" >
            <template v-for="(i2,i1) in ((IsNull(data_in.msg)=='' || IsNull(data_in.msg.texts)=='')?[]:data_in.msg.texts)">
                {{i2}}<br v-show="i1!=data_in.msg.texts.length-1" :key="i1+'_invalid'">
            </template>
        </div>

    </div>


    

    <div v-else></div>

</template>
<script>
export default {
    name:'InputV1',
    props:{
        type:{
            type:[String],
            default(){
                return ""
            }
        },
        data_in:{
            type:[Object],
            default(){
                return {}
            }
        }
    },
    data(){
        return{
            dateSet:{
                weekdayHeaderFormat: 'narrow',
				labelPrevDecade: '過去十年',
				labelPrevYear: '上一年',
				labelPrevMonth: '上個月',
				labelCurrentMonth: '當前月份',
				labelNextMonth: '下個月',
				labelNextYear: '明年',
				labelNextDecade: '往後十年',
				labelToday: '今天',
				labelSelected: '選擇日期',
				labelNoDateSelected: '未選擇日期',
				labelCalendar: '日曆',
				labelNav: '日曆導覽',
				labelHelp: '使用鼠標瀏覽日期'
            }
        }
    },
    mounted(){
       if(this.type=='file' || this.type=='b-file'){
            this.data_in['fileClick']=this.fileClick
            this.data_in['fileChange']=this.fileChange
            this.data_in['fileClear']=this.fileClear
        }

    },
    methods:{

        evenF1(type,data,event){
            if(type=='change'){
                if(this.IsNull(data.change)==''){
                    return false
                }else{
                    data.change(data)
                }
            }else if(type=='click'){
                if(this.IsNull(data.click)==''){
                    return false
                }else{
                    data.click(data)
                }
            }else if(type=='keypress'){
                if(this.IsNull(data.keypress)==''){
                    return false
                }else{
                    data.keypress(event,data)
                }
            }else if(type=='context'){
                if(this.IsNull(data.context)==''){
                    return false
                }else{
                    data.context(data)
                }
            }
            
        },
        init(){
            return{
                'text':{val:'',text:'test',only:false,msg:{type:true,texts:[]}},
                'password':{val:'',text:'test',only:false,msg:{type:true,texts:[]}},
                'b-checkbox':{val:false,text:'test',only:false},
                'select':{val:'',text:'test',only:false,
                          options:{'val1':{val:'val1',text:'test'}},
                          msg:{type:true,texts:[]},
                          },
                'select2':{val:'',text:'test',only:false,
                          options:{'val1':{val:'val1',text:'test'}},
                          msg:{type:true,texts:[]},
                          },
                'T-select':{only:false,style:'width:auto;display: inline;',
                            options:[{text:'列出近10筆記錄',val:10},
                                {text:'列出近20筆記錄',val:20},
                                {text:'列出近50筆記錄',val:50},
                                {text:'列出近100筆記錄',val:100},]
                          },
                'textarea':{val:'',text:'test',rows:2,only:false,msg:{type:true,texts:[]}},
                'file':{val:'',text:'選擇檔案',only:false,
                        id:'upfile1',name:'請選擇檔案',even:'',
                        msg:{type:true,texts:[]}
                        },
                'b-file':{val:'',text:'選擇檔案',only:false,even:'',
                        id:'upfile1',name:'請選擇檔案',},
                'date':{val:'',only:false,msg:{type:true,texts:[]}},
                'b-radio-group':{val:'val2',only:false,stacked:false,buttons:false,
                                 options:[{value:'val1',text:'test1'},
                                            {value:'val2',text:'test2'},
                                            {value:'val3',text:'test3'},],
                                msg:{type:true,texts:[]}
                                },
                'b-switch':{val:false,text:'test',only:false,},
                'img':{src:'',width:'',height:'',alt:'test',title:'',},
                'b-checkbox-group':{val:[],only:false,stacked:false,
                                 options:[{value:'val1',text:'test1'},
                                          {value:'val2',text:'test2'},
                                          {value:'val3',text:'test3'},],
                                 msg:{type:true,texts:[]}
                                },
                'b-switch-group':{val:[],only:false,size:'',stacked:false,
                                 options:[{value:'val1',text:'test1'},
                                          {value:'val2',text:'test2'},
                                          {value:'val3',text:'test3'},],
                                 msg:{type:true,texts:[]}
                                },
                'datepicker':{val:'',only:false,text:'',msg:'',
                              size:'',loca:'zh',
                              headShow:true,year10:false,
                              format:{ year: 'numeric', 
                                       month: '2-digit', 
                                       day: '2-digit',
                                        //weekday:'narrow'
                                        },
                              },
                'text-ap':{val:'',text:'',textR:'',textL:'',only:false,msg:''},
                'text-ap2':{val:'',text:'',only:false,msg:''},
                
                                
                  
                        
            }
        },
        fileClick(th1){
            if(th1.only){return}
            document.getElementById(th1.id).click();
            if(this.IsNull(th1.click)!=''){
                th1.click(th1)
            }
        },
        fileChange(even,th1){
            th1.name=even.target.files[0].name;
            th1.val=even.target.files[0]
            th1.even=even
            if(this.IsNull(th1.change)!=''){
                th1.change(th1)
            }
        },
        fileClear(th1,name='請選擇檔案'){
            th1.name=name
            th1.val=''
            th1.even=''
            if(this.IsNull(document.getElementById(th1.id))!=''){
                document.getElementById(th1.id).value = "";
            }
            if(this.IsNull(th1.clear)!=''){
                th1.clear(th1)
            }
        },
       
    }
}
</script>