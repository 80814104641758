<template>
    <b-modal id="signin" centered hide-footer hide-header body-class="p-0">
        <b-card no-body>
        <b-tabs card fill content-class="p-4">
            <b-tab :title="data_in.form1.title" 
                    :active="data_in.form1.show"
                >
              <!--*******************************************************************-->
                <b-form-group class="course-search">
                    <b-input-group >
                        <template #prepend>
                            <b-input-group-text>
                                <b-icon-person></b-icon-person>
                            </b-input-group-text>
                        </template>
                        <b-form-input 
                            :state="IsNull(data_in.form1.data['Id'].msg,{type:null}).type"
                            id="username"
                            type="search"
                            :disabled="data_in.form1.data['Id'].only"
                            :placeholder="data_in.form1.data['Id'].text"
                            v-model="data_in.form1.data['Id'].val"
                            @change="evenF1('change',data_in.form1.data['Id'],$event)"
                            @keypress="evenF1('keypress',data_in.form1.data['Id'],$event)"
                        ></b-form-input>

                        <b-form-valid-feedback >
                          <template v-for="(i2,i1) in IsNull(data_in.form1.data['Id'].msg,{texts:[]}).texts">
                              <template v-if="i1==0">{{i2}}</template>
                              <template v-else> <br :key="i1">{{i2}}</template>
                          </template>
                        </b-form-valid-feedback>

                        <b-form-invalid-feedback >
                          <template v-for="(i2,i1) in IsNull(data_in.form1.data['Id'].msg,{texts:[]}).texts">
                              <template v-if="i1==0">{{i2}}</template>
                              <template v-else> <br :key="i1">{{i2}}</template>
                          </template>
                        </b-form-invalid-feedback>

                    </b-input-group>
                </b-form-group>
                <b-form-group class="course-search">
                    <b-input-group>
                        <template #prepend>
                            <b-input-group-text>
                                <b-icon-lock></b-icon-lock>
                            </b-input-group-text>
                        </template>
                        <b-form-input
                            :state="IsNull(data_in.form1.data['DecryptionPass'].msg,{type:null}).type"
                            id="loginpassword"
                            type="password"
                            :disabled="data_in.form1.data['DecryptionPass'].only"
                            :placeholder="data_in.form1.data['DecryptionPass'].text"
                            v-model="data_in.form1.data['DecryptionPass'].val"
                            @change="evenF1('change',data_in.form1.data['DecryptionPass'],$event)"
                            @keypress="evenF1('keypress',data_in.form1.data['DecryptionPass'],$event)"
                        ></b-form-input>

                        <b-form-valid-feedback >
                          <template v-for="(i2,i1) in IsNull(data_in.form1.data['DecryptionPass'].msg,{texts:[]}).texts">
                              <template v-if="i1==0">{{i2}}</template>
                              <template v-else> <br :key="i1">{{i2}}</template>
                          </template>
                        </b-form-valid-feedback>

                        <b-form-invalid-feedback >
                          <template v-for="(i2,i1) in IsNull(data_in.form1.data['DecryptionPass'].msg,{texts:[]}).texts">
                              <template v-if="i1==0">{{i2}}</template>
                              <template v-else> <br :key="i1">{{i2}}</template>
                          </template>
                        </b-form-invalid-feedback>

                    </b-input-group>
                </b-form-group>
                <b-row>
                    <b-col>
                        <b-form-group class="course-search">
                            <b-input-group>
                                <template #prepend>
                                    <b-input-group-text>
                                        <b-icon-info-circle></b-icon-info-circle>
                                    </b-input-group-text>
                                </template>
                                <b-form-input
                                    :state="IsNull(data_in.form1.data['CodePass'].msg,{type:null}).type"
                                    id="codelogin"
                                    type="search"
                                    :disabled="data_in.form1.data['CodePass'].only"
                                  :placeholder="data_in.form1.data['CodePass'].text"
                                  v-model="data_in.form1.data['CodePass'].val" 
                                  @change="evenF1('change',data_in.form1.data['CodePass'],$event)"
                                  @keypress="evenF1('keypress',data_in.form1.data['CodePass'],$event)"                             
                                ></b-form-input>
                                
                                <b-form-valid-feedback >
                                  <template v-for="(i2,i1) in IsNull(data_in.form1.data['CodePass'].msg,{texts:[]}).texts">
                                      <template v-if="i1==0">{{i2}}</template>
                                      <template v-else> <br :key="i1">{{i2}}</template>
                                  </template>
                                </b-form-valid-feedback>

                                <b-form-invalid-feedback >
                                  <template v-for="(i2,i1) in IsNull(data_in.form1.data['CodePass'].msg,{texts:[]}).texts">
                                      <template v-if="i1==0">{{i2}}</template>
                                      <template v-else> <br :key="i1">{{i2}}</template>
                                  </template>
                                </b-form-invalid-feedback>
                                
                            </b-input-group>
                        </b-form-group>
                    </b-col>
                    <b-col cols="auto">
                        <!-- <b-img :src="require('@/assets/verification.jpeg')" style="height:38px" fluid></b-img> -->
                        <b-img :src="data_in.form1.data['CodePass'].img.Image" style="height:38px" fluid></b-img>
                    </b-col>
                </b-row>
                <slot name="form1"></slot>
                

                <!--*******************************************************************-->
            </b-tab>
            <b-tab :title="data_in.form2.title"
                   :active="data_in.form2.show">
              <!--*******************************************************************-->
                <b-form-group class="course-search">
                    <b-input-group>
                        <template #prepend>
                            <b-input-group-text>
                                <b-icon-person></b-icon-person>
                            </b-input-group-text>
                        </template>
                        <b-form-input
                            :state="IsNull(data_in.form2.data['AC_USERNAME'].msg,{type:null}).type"
                            id="user"
                            type="search" 
                            :disabled="data_in.form2.data['AC_USERNAME'].only"
                            :placeholder="data_in.form2.data['AC_USERNAME'].text"
                            v-model="data_in.form2.data['AC_USERNAME'].val" 
                            @change="evenF1('change',data_in.form2.data['AC_USERNAME'],$event)"
                            @keypress="evenF1('keypress',data_in.form2.data['AC_USERNAME'],$event)"   
                        ></b-form-input>

                        <b-form-valid-feedback >
                            <template v-for="(i2,i1) in IsNull(data_in.form2.data['AC_USERNAME'].msg,{texts:[]}).texts">
                                <template v-if="i1==0">{{i2}}</template>
                                <template v-else> <br :key="i1">{{i2}}</template>
                            </template>
                        </b-form-valid-feedback>

                        <b-form-invalid-feedback >
                            <template v-for="(i2,i1) in IsNull(data_in.form2.data['AC_USERNAME'].msg,{texts:[]}).texts">
                                <template v-if="i1==0">{{i2}}</template>
                                <template v-else> <br :key="i1">{{i2}}</template>
                            </template>
                        </b-form-invalid-feedback>

                    </b-input-group>
                </b-form-group>
                <b-form-group class="course-search" v-show="false">
                    <b-input-group>
                        <template #prepend>
                            <b-input-group-text>
                                <b-icon-card-text></b-icon-card-text>
                            </b-input-group-text>
                        </template>
                        <b-form-input
                            :state="IsNull(data_in.form2.data['AC_USERID'].msg,{type:null}).type"
                            id="userid"
                            type="search"
                            :disabled="data_in.form2.data['AC_USERID'].only"
                            :placeholder="data_in.form2.data['AC_USERID'].text"
                            v-model="data_in.form2.data['AC_USERID'].val" 
                            @change="evenF1('change',data_in.form2.data['AC_USERID'],$event)"
                            @keypress="evenF1('keypress',data_in.form2.data['AC_USERID'],$event)"  
                        ></b-form-input>

                        <b-form-valid-feedback >
                            <template v-for="(i2,i1) in IsNull(data_in.form2.data['AC_USERID'].msg,{texts:[]}).texts">
                                <template v-if="i1==0">{{i2}}</template>
                                <template v-else> <br :key="i1">{{i2}}</template>
                            </template>
                        </b-form-valid-feedback>

                        <b-form-invalid-feedback >
                            <template v-for="(i2,i1) in IsNull(data_in.form2.data['AC_USERID'].msg,{texts:[]}).texts">
                                <template v-if="i1==0">{{i2}}</template>
                                <template v-else> <br :key="i1">{{i2}}</template>
                            </template>
                        </b-form-invalid-feedback>

                    </b-input-group>
                </b-form-group>
                <b-form-group class="course-search" v-show="false">
                    <!-- <b-input-group>
                        <template #prepend>
                            <b-input-group-text>
                                <b-icon-calendar3></b-icon-calendar3>
                            </b-input-group-text>
                        </template>
                    </b-input-group> -->
                    <b-form-datepicker 
                        id="example-datepicker" 
                        style="border-radius: 40px;"
                        :disabled="data_in.form2.data['AC_BIRTHDAY'].only"
                        :placeholder="data_in.form2.data['AC_BIRTHDAY'].text"
                        v-model="data_in.form2.data['AC_BIRTHDAY'].val" 
                        @change="evenF1('change',data_in.form2.data['AC_BIRTHDAY'],$event)"
                        @keypress="evenF1('keypress',data_in.form2.data['AC_BIRTHDAY'],$event)"  
                    ></b-form-datepicker>
                </b-form-group>
                <b-form-group class="course-search">
                    <b-input-group>
                        <template #prepend>
                            <b-input-group-text>
                                <b-icon-envelope></b-icon-envelope>
                            </b-input-group-text>
                        </template>
                        <b-form-input 
                            :state="IsNull(data_in.form2.data['AC_EMAIL'].msg,{type:null}).type"
                            id="email"
                            type="email"
                            :disabled="data_in.form2.data['AC_EMAIL'].only"
                            :placeholder="data_in.form2.data['AC_EMAIL'].text"
                            v-model="data_in.form2.data['AC_EMAIL'].val"
                            @keypress="evenF1('keypress',data_in.form2.data['AC_EMAIL'],$event)"  
                            @change="evenF1('change',data_in.form2.data['AC_EMAIL'],$event)"

                        ></b-form-input>

                        <b-form-valid-feedback >
                            <template v-for="(i2,i1) in IsNull(data_in.form2.data['AC_EMAIL'].msg,{texts:[]}).texts">
                                <template v-if="i1==0">{{i2}}</template>
                                <template v-else> <br :key="i1">{{i2}}</template>
                            </template>
                        </b-form-valid-feedback>

                        <b-form-invalid-feedback >
                            <template v-for="(i2,i1) in IsNull(data_in.form2.data['AC_EMAIL'].msg,{texts:[]}).texts">
                                <template v-if="i1==0">{{i2}}</template>
                                <template v-else> <br :key="i1">{{i2}}</template>
                            </template>
                        </b-form-invalid-feedback>

                    </b-input-group>
                </b-form-group>
                <b-form-group class="course-search">
                    <b-input-group>
                        <template #prepend>
                            <b-input-group-text>
                                <b-icon-phone></b-icon-phone>
                            </b-input-group-text>
                        </template>
                        <b-form-input 
                            :state="IsNull(data_in.form2.data['AC_TEL'].msg,{type:null}).type"
                            id="phone"
                            type="tel"
                            oninput="value=value.replace(/[^\d]/g,'')"
                            :disabled="data_in.form2.data['AC_TEL'].only"
                        :placeholder="data_in.form2.data['AC_TEL'].text"
                        v-model="data_in.form2.data['AC_TEL'].val"
                        @keypress="evenF1('keypress',data_in.form2.data['AC_TEL'],$event)"  
                        @change="evenF1('change',data_in.form2.data['AC_TEL'],$event)"
                        ></b-form-input>

                        <b-form-valid-feedback >
                            <template v-for="(i2,i1) in IsNull(data_in.form2.data['AC_TEL'].msg,{texts:[]}).texts">
                                <template v-if="i1==0">{{i2}}</template>
                                <template v-else> <br :key="i1">{{i2}}</template>
                            </template>
                        </b-form-valid-feedback>

                        <b-form-invalid-feedback >
                            <template v-for="(i2,i1) in IsNull(data_in.form2.data['AC_TEL'].msg,{texts:[]}).texts">
                                <template v-if="i1==0">{{i2}}</template>
                                <template v-else> <br :key="i1">{{i2}}</template>
                            </template>
                        </b-form-invalid-feedback>

                    </b-input-group>
                </b-form-group>
                <b-form-group class="course-search">
                    <b-input-group>
                        <template #prepend>
                            <b-input-group-text>
                                <b-icon-lock></b-icon-lock>
                            </b-input-group-text>
                        </template>
                        <b-form-input
                            :state="IsNull(data_in.form2.data['AC_PWD'].msg,{type:null}).type"
                            id="registerpassword"
                            type="password"
                            :disabled="data_in.form2.data['AC_PWD'].only"
                        :placeholder="data_in.form2.data['AC_PWD'].text"
                        v-model="data_in.form2.data['AC_PWD'].val"
                        @keypress="evenF1('keypress',data_in.form2.data['AC_PWD'],$event)"  
                        @change="evenF1('change',data_in.form2.data['AC_PWD'],$event)"
                        ></b-form-input>

                        <b-form-valid-feedback >
                            <template v-for="(i2,i1) in IsNull(data_in.form2.data['AC_PWD'].msg,{texts:[]}).texts">
                                <template v-if="i1==0">{{i2}}</template>
                                <template v-else> <br :key="i1">{{i2}}</template>
                            </template>
                        </b-form-valid-feedback>

                        <b-form-invalid-feedback >
                            <template v-for="(i2,i1) in IsNull(data_in.form2.data['AC_PWD'].msg,{texts:[]}).texts">
                                <template v-if="i1==0">{{i2}}</template>
                                <template v-else> <br :key="i1">{{i2}}</template>
                            </template>
                        </b-form-invalid-feedback>

                    </b-input-group>
                </b-form-group>
                <b-form-group class="course-search">
                    <b-input-group>
                        <template #prepend>
                            <b-input-group-text>
                                <b-icon-lock></b-icon-lock>
                            </b-input-group-text>
                        </template>
                        <b-form-input
                            :state="IsNull(data_in.form2.data['AC_PWD2'].msg,{type:null}).type"
                            id="check-password"
                            type="password"
                            :disabled="data_in.form2.data['AC_PWD2'].only"
                        :placeholder="data_in.form2.data['AC_PWD2'].text"
                        v-model="data_in.form2.data['AC_PWD2'].val"
                        @keypress="evenF1('keypress',data_in.form2.data['AC_PWD2'],$event)" 
                        @change="evenF1('change',data_in.form2.data['AC_PWD2'],$event)"
                        ></b-form-input>

                        <b-form-valid-feedback >
                            <template v-for="(i2,i1) in IsNull(data_in.form2.data['AC_PWD2'].msg,{texts:[]}).texts">
                                <template v-if="i1==0">{{i2}}</template>
                                <template v-else> <br :key="i1">{{i2}}</template>
                            </template>
                        </b-form-valid-feedback>

                        <b-form-invalid-feedback >
                            <template v-for="(i2,i1) in IsNull(data_in.form2.data['AC_PWD2'].msg,{texts:[]}).texts">
                                <template v-if="i1==0">{{i2}}</template>
                                <template v-else> <br :key="i1">{{i2}}</template>
                            </template>
                        </b-form-invalid-feedback>

                    </b-input-group>
                </b-form-group>
                <!--
                <b-row>
                    <b-col>
                        <b-form-group class="course-search">
                            <b-input-group>
                                <template #prepend>
                                    <b-input-group-text>
                                        <b-icon-info-circle></b-icon-info-circle>
                                    </b-input-group-text>
                                </template>
                                <b-form-input 
                                    id="coderegister"
                                    type="text"                                    
                                    :disabled="data_in.form2.data['if1'].only"
                        :placeholder="data_in.form2.data['if1'].text"
                        v-model="data_in.form2.data['if1'].val"                                 
                                ></b-form-input>
                            </b-input-group>
                        </b-form-group>
                    </b-col>
                    <b-col cols="auto">
                    
                        <b-img :src="data_in.form2.data['if1'].img.Image" style="height:38px" fluid></b-img>
                    </b-col>
                </b-row>
                -->
                <slot name='form2'> </slot>

                <!--*******************************************************************-->
            </b-tab>
        </b-tabs>
        </b-card>
    </b-modal>
</template>

<script>
export default {
  name: "Login",
  components:{},
  props:{
    data_in:{
      type:[Object],
      default(){
        return{}
      }
    }
  },
  data() {
    return {
      isLoading: false,
      imageCode: "",
      loginData: {
        Id: "",
        Type: "login",
        DecryptionPass: "",
        CodePass: "",        
        DecryptionCodePass: "",

        TokenID: "",
        Token: "",
        Page: "",
      },
      creatData:{        
        AC_USERNAME: "",
        AC_USERID: "",
        AC_USERBIR: "",
        AC_USERMAIL: "",
        AC_TEL: "",
        AC_PWD: "",
        AC_PWD_Comfirm: "",
        AC_CodePass: "",
        AC_DecryptionCodePass: "",

        TokenID: "",
        Token: "",
        Page: "",
      },
      lastpass: "",
      checkA: false,
      errormsg: "",
    };
  },
  created() {
    this.getImageCode();   
  },  
  mounted(){
    //this.getImageCode();   
  },
  computed: {
    /*
    // 檢查email欄位
    checkEmail() {
      const re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
      return re.test(String(this.creatData.AC_USERMAIL).toLowerCase());
    },
    // 檢查身份欄位
    checkUserid() {
      const re = /^[a-zA-Z][0-9]{9}$/;
      return re.test(String(this.creatData.AC_USERID).toUpperCase());
    },
    // 檢查電話欄位
    checkPhone() {
      if (
        this.creatData.AC_TEL.length < 9
      ) {
        return false;
      } else {
        return true;
      }
    },
    // 檢查密碼
    checkPassword() {
      let CP = this.creatData.AC_PWD;
      let RP = this.creatData.AC_PWD_Comfirm;
      if (!CP || !RP || CP !== RP) {
        return false;
      } else {
        return true;
      }
    },    
    */
  },
  methods: {   
    funIf(fun){
      if(this.IsNull(fun)==''){
        return
      }else(
        fun()
      )
    },
     evenF1(type,data,event){
            if(type=='keypress'){
                if(this.IsNull(data.keypress)==''){
                    return false
                }else{
                    data.keypress(event,data)
                }
            }else if(type=='change'){
              if(this.IsNull(data.change)==''){
                return false
              }else{
                data.change(data)
              }
            }
            
        },
    //登入檢查
    checkLoginData() {
      this.errormsg = "";
      if (this.loginData.Id == "") this.errormsg += "\n請輸入帳號!!";
      if (this.loginData.DecryptionPass == "")
        this.errormsg += "\n請輸入密碼!!";
      if (this.loginData.CodePass == "")
        this.errormsg += "\n請輸入圖形驗證碼!!";
        console.log(this.errormsg)
      if (this.errormsg != "") alert(this.errormsg);
    },
    //準備登入
    checkAchange() {
      if (!this.checkA) {
        sessionStorage.removeItem("CCC");
      }
      sessionStorage.setItem("checkA", this.checkA);
    },
    userLogin() {
      this.checkLoginData();
      if (this.errormsg === "") {
        this.FunctionToken(this.Login, this.loginData);
      }
    },
    Login(data) {
      this.apiLogin(data)
        .then((res) => {
          const json = JSON.parse(res.data);
          if (json.Status) {
            const jsonToken = JSON.parse(json.Data);
            sessionStorage.setItem("TokenID", jsonToken);
            if (this.checkA) {
              sessionStorage.setItem("CCC", data.Id);
            } else {
              sessionStorage.setItem("CCC", null);
            }

            //this.$router.push({ path: "/" });
            sessionStorage.setItem("PM_TYPE", 0);
            // this.$router.push({ path: "/tree_info" });
            window.location.reload();
          } else {
            alert(json.Message);
          }
        })
        .catch((err) => {
          console.log(err);
        });
    },
    getImageCode() {
      this.apiGetImageCode()
        .then((res) => {
          const json = JSON.parse(res.data);
          if (json.Status) {
            const jsonImage = JSON.parse(json.Data);
            this.imageCode = jsonImage.Image;
            this.loginData.DecryptionCodePass = jsonImage.Code;
            this.creatData.AC_DecryptionCodePass = jsonImage.Code;
          }
        })
        .catch((err) => {
          console.log(err);
        });
    },
    //註冊檢查
    checkInput() {
      this.errorText = "";
      if (!this.checkUserid) this.errorText += "\n請輸入正確身份證號";
      if (!this.checkEmail) this.errorText += "\n請輸入正確電子郵件";
      if (!this.checkPhone) this.errorText += "\n請輸入電話";
      if (!this.checkPassword) this.errorText += "\n請確認密碼是否一致";
      if (this.creatData.AC_CodePass == "") this.errorText += "\n請輸入圖形驗證碼";      
      if (this.errorText !== "") alert(this.errorText);
    },
    //準備註冊
    userSignup() {
      this.checkInput();
      if (this.errorText === "") {        
        this.FunctionToken(this.signup, this.creatData);
      } else {
        console.log("註冊失敗");
      }    
    },
    signup(data) {
      this.isLoading = true;
      this.apiACCOUNTInsert(data)
        .then((res) => {
          console.log(res);
          const json = JSON.parse(res.data);
          const msg = json.Data;
          this.isLoading = false;
          if (json.Status) {
            // const jsonToken = JSON.parse(json.Data);
            alert("註冊成功");
            // this.$router.push({ path: "/" });
            window.location.reload();
          } else {
            alert(`註冊失敗，${msg}，請再試一次`);
          }
        });
    },
  },

};
</script>