<template>
   <div class="user">
       <div class="headerbg">
          <b-container>
         <h1>帳號姓名</h1>
          </b-container>
      </div>
      <b-container>

         <b-row>
            <b-col cols="12">
               <nacUser></nacUser>
            </b-col>
            <b-col cols="12">
               <h2>課程紀錄</h2>
               <b-row cols="1" class="courses">
                  <b-col v-for="(item, index) in courses" :key="index">
                     <div class="item">
                        <b-row>
                           <b-col cols="3">
                              <b-link to="/course">
                                 <b-card-img :src="item.imgSrc" alt="Image" class="rounded-0 border"></b-card-img>
                              </b-link>
                           </b-col>
                           <b-col>
                              <h5>
                                 <b-link to="/course">
                                    <b-badge v-if="item.start">開課中</b-badge> {{item.title}}
                                 </b-link>
                              </h5>
                              <p>教會您如何解 JS 題目，並透過線上資源逐步攻略地下城！</p>
                              <ul class="pl-3">
                                 <li>師資</li>
                                 <li>上課時間</li>
                                 <li>天數／時數／學分</li>
                                 <li>報名截止日期</li>
                              </ul>
                           </b-col>
                           <b-col cols="auto">
                              <strong class="price">NT$8,000</strong>
                           </b-col>
                        </b-row>
                     </div>
                  </b-col>
               </b-row>
               <b-pagination v-model="currentPage" :total-rows="rows" :per-page="perPage" first-text="第一頁"
                  prev-text="上一頁" next-text="下一頁" last-text="最後一頁" align="center" pills></b-pagination>
            </b-col>
         </b-row>
      </b-container>
   </div>
</template>
<script>
   import nacUser from '@/components/nacUser.vue'
   export default {
      name: 'user-course',
      data() {
         return {
            courses: [{
                  start: false,
                  title: "無人機實務飛行特訓班",
                  imgSrc: ''
               },
               {
                  start: false,
                  title: "無人機實務飛行特訓班",
                  imgSrc: ''
               },
               {
                  start: true,
                  title: "無人機實務飛行特訓班",
                  imgSrc: ''
               },
               {
                  start: false,
                  title: "無人機實務飛行特訓班",
                  imgSrc: ''
               },
               {
                  start: false,
                  title: "無人機實務飛行特訓班",
                  imgSrc: ''
               }
            ],
         }
      },
      components: {
         nacUser
      }
   }
</script>