<template>
    <loading1
      :active.sync="data_in.show"
      :is-full-page="data_in.fullPage"
      :loader="data_in.type"
      :color="data_in.color"
    ></loading1>
</template>
<script>
import loading1 from "vue-loading-overlay";
import 'vue-loading-overlay/dist/vue-loading.css';
export default {
    name:'Loading',
    components:{
        loading1,
    },
    props:{
        data_in:{
            type:[Object],
            default(){
                return{
                    fullPage: true,
                    type:'dots',
                    color:'rgba(0,0,0,0.7)',
                    show:false
                }
            }
        }
    },
    data(){
        return{
            fullPage: true,type:'dots',color:'rgba(0,0,0,0.7)',
        }
    }
}
</script>