<template>
  <div>
    <b-container class="course">
      <b-link class="back" to="courses"><b-icon-arrow-left-circle-fill></b-icon-arrow-left-circle-fill> 返回列表</b-link>
      <h1 class="title">
        {{ IsNull(course.CS_TITLE) }} <small>梯次{{IsNull(course.CA_PERIOD)}}</small>
      </h1>
      <div class="state2">
      <!-- <template v-for="(i2,i1) in course.courseStatus">
        <b-badge :key="i1"  pill
        v-bind="IsNull(courseStatusStyle[IsNull(i2)],courseStatusStyle[''])" 
        >{{i2}}</b-badge>
      </template> -->

        <template v-for="(i2,i1) in (IsNull(course.CA_HASHTAG_ID)==''?[]:course.CA_HASHTAG_ID.split(','))">
          <b-badge class="mr-1" :key="i1">{{i2}}</b-badge>
        </template>
 
      </div>
      <b-row class="mb-3">
        <b-col order="2" order-md="1">
        <div class="course-attr border-bottom">
          <b-row align-v="stretch">
            <b-col v-show="Table.data['LOC_NAME'].show">
              <div class="attr-box ">
                <span class="title">上課地點</span>
                <div class="attr">
                {{Table.data['LOC_NAME'].val}}<br>
                {{Table.data['LOC_CLASSROOM_NAME'].val}}
                </div>
              </div>
            </b-col>
            <b-col v-show="Table.data['date'].show">
              <div class="attr-box ">
                <span class="title">上課日期</span>
                <div class="attr time">
                {{turnDate(Table.data['date'].val)}} <br/>～<br /> {{turnDate(Table.data['date'].val2)}}
                </div>
              </div>
            </b-col>
            <!-- <b-col v-show="Table.data['type'].show">
            <span class="title">課程類別</span>
            <div>{{Table.data['type'].val}}</div>
            </b-col> -->
            <b-col v-show="Table.data['CS_CREDIT'].show">
              <div class="attr-box ">
                <span class="title">學分數</span>
                <div class="attr">
                <span><span class="number">{{Table.data['CS_CREDIT'].val}}</span>學分</span>
                </div>
              </div>
            </b-col>

            <b-col v-show="Table.data['CA_NUMBER'].show">
              <div class="attr-box ">
                <span class="title">招生名額</span>
                <!--
                <div>
                {{ IsNull(course.CA_ENROLLMENT) }}  
                <span v-if="IsNull(course.CA_DEFINITE_NUMBER)">{{IsNull(course.CA_DEFINITE_NUMBER)}}</span>
                </div>
                -->
                <div class="attr">{{Table.data['CA_NUMBER'].val}}</div>
              </div>
            </b-col>
            <b-col v-show="Table.data['CA_HOURS'].show">
              <div class="attr-box ">
                <span class="title">上課時數</span>
                <div class="attr">
                <span><span class="number">{{Table.data['CA_HOURS'].val}}</span>小時</span>
                </div>
              </div>
            </b-col>

            <b-col v-show="Table.data['time'].show">
              <div class="attr-box ">
                <span class="title">上課時間</span>
                <div class="attr time">
                  <template v-if="IsNull(Table.data['memo'].val)!=''">
                    {{Table.data['memo'].val}}<br>
                  </template>
                  
                {{Table.data['time'].val}} ～ {{Table.data['time'].val2}}
                </div>
              </div>
            </b-col>


          </b-row>
          </div>
          <div class="bg-white border-bottom p-3 p-md-5" v-if="IsNull(course.PROFESSOR)">
					<h3 class="title mb-4" id="course_href3"><span>師資介紹</span></h3>
          
            <b-row>
              <b-col cols="12" class="mb-3"  v-for="(prof, index) in course.PROFESSOR" :key="index">
                <b-card-group columns deck>
                <b-card no-body class="overflow-hidden teacher border-0 shadow-sm rounded-lg" style="background-color:#f0f9ff;">
                <b-row no-gutters>
                  <b-col  cols="12" md="auto" class="text-center p-3">
                    <b-card-img v-if="IsNull(files[prof.PROF_PHOTO_URL])!=''"
                    :src="files[prof.PROF_PHOTO_URL]" 
                    style="width:128px;"
                    alt="Image" 
                    class="rounded-lg mb-2"></b-card-img>
                    <b-card-img v-else
                    :src="require('../assets/user.svg')" 
                    alt="Image" 
                    style="width:128px;"
                    class="rounded-lg mb-2"></b-card-img>
                    <h4>{{IsNull(prof.PROF_NAME)}}</h4>              
                        {{IsNull(prof.PROF_COMPANY)}} {{IsNull(prof.PROF_UNIT)}} {{IsNull(prof.PROF_TITLE)}}
                  
                  </b-col>
                  <b-col>
                    <b-card-body>
                      <b-card-text>
                        <div v-if="IsNull(prof.PROF_EXPERIENCE)">
                          <h5>經歷</h5>
                          <div v-html="IsNull(prof.PROF_EXPERIENCE)"></div>
                        </div>
                        <div v-if="IsNull(prof.PROF_EDUCATION)">
                          <h5>學歷</h5>
                          <div class="mb-3" v-html="IsNull(prof.PROF_EDUCATION)"></div>
                        </div>
                      </b-card-text>
                    </b-card-body>
                  </b-col>
                </b-row>
              </b-card>
              </b-card-group>
              </b-col>
            </b-row>
              

        </div>
  
        <div class="bg-white border-bottom p-3 p-md-5" v-if="IsNull(course.CA_INTRODUCTION)">
          <h3 class="title">課程介紹</h3>
          <div style="overflow: hidden;" v-html="IsNull(course.CA_INTRODUCTION)"></div>
          </div>

          <div class="bg-white border-bottom p-3 p-md-5" v-if="IsNull(course.COURSE_OUTLINE)">
            <h3 class="title mb-4"><span>課程大綱</span></h3>
            <ul>
              <li class="mb-2" v-for="(curriculum, index) in course.COURSE_OUTLINE" :key="index">
                {{ turnDate(curriculum.CO_DATE) }} 
                {{ReTime(curriculum.CO_BEGIN_TIME)}} ~ {{ReTime(curriculum.CO_END_TIME)}}
                <div>{{ IsNull(curriculum.CO_CONTENT) }}</div>
                <!-- <ul>
                  <li>授課教師：{{ curriculum.CO_PROFESSOR }}</li>
                  <li>授課地點：{{ curriculum.CO_LOCATION }}</li>
                </ul> -->
              </li>
            </ul>
          </div>

        <div class="bg-white border-bottom p-3 p-md-5" v-show="Table.data['CA_SUITABLE'].show">
          <h3 class="title">適合對象</h3>
          <div v-html="Table.data['CA_SUITABLE'].val"></div>
        </div>
 
          <div class="bg-white border-bottom p-3 p-md-5" v-show="Table.data['CA_QUALIFICATIONS'].show">
            <h3 class="title">報名資格</h3>
            <div v-html="Table.data['CA_QUALIFICATIONS'].val"></div>
          </div>
      
          
    
        </b-col>
        <b-col class="mb-3" order="1" order-md="2" cols="auto">
          <div class="external-signin">
          <div class="signup shadow-sm">
            <b-img 
              :src="IsNull(files[course.CA_IMAGE_URL]) == '' ? '' : files[course.CA_IMAGE_URL]" 
              fluid
              style="width:400px"
              class="shadow-sm rounded-lg"
              ></b-img>

              <div class="signup-body">
            <p class="text-danger" v-show="Table.data['date2'].show">
              報名日期：<br />{{turnDate(Table.data['date2'].val)}} ~ {{turnDate(Table.data['date2'].val2)}}
            </p>
            <ul class="list-unstyled">
              <li v-show="Table.data['price'].show && IsNull(Table.data['price'].off)==''">
                <span class="text-black-50">課程費用</span> 
                <span class="price"> NT$<span class="number">{{Table.data['price'].val}}</span></span>
              </li>
              <li v-show="Table.data['price'].show && IsNull(Table.data['price'].off)!=''">
                <span class="text-black-50">課程費用</span> 
                <span class="price"> NT$<span class="number">{{ Table.data['price'].val }}</span></span><br>
                <span class="text-black-50">早鳥優惠</span> 
                <span class="price"> NT$<span class="number">{{ Table.data['price'].off}}</span></span>
              </li>
            </ul>


            <div v-show="dateIf(course.CA_END_DATE)">
            <b-button
              variant="primary"
              class="px-5"
              size="lg"
              block
              @click="SignUp(0)"
              >我要報名</b-button
              >
              <p class="mt-3" v-if="course.CA_IS_GROUP" v-show="Table.data['price2'].show && IsNull(Table.data['price2'].off)!=''">
              {{Table.data['price2'].num}} 人以上 <span class="price"> NT$<span class="number">{{Table.data['price2'].off}}</span></span>／人
              </p>

              <p class="mt-3" v-if="course.CA_IS_GROUP" v-show="Table.data['price2'].show && IsNull(Table.data['price2'].off)==''">
              <span class="price"> NT$<span class="number">{{ Table.data['price2'].val}}</span></span>／人
              </p>

              <b-button
              variant="primary"
              class=" px-5"
              size="lg"
              block
              v-if="course.CA_IS_GROUP"
              @click="SignUp(1)"
              >團體報名
              </b-button>
              </div>

              <b-alert :show="dateIf(course.CA_END_DATE)==false" variant="secondary" class="text-center" style="font-weight: bold;" >新班次規劃中</b-alert>

              <!-- <div class="text-center">
                <b-link>
                  <b-icon icon="facebook" class="mr-2" font-scale="2"></b-icon>
                </b-link>
                <b-link>
                  <b-icon icon="twitter" class="mr-2" font-scale="2"></b-icon>
                </b-link>
                <b-link>
                  <b-icon icon="bookmark-heart" font-scale="2" variant="danger"></b-icon>
                </b-link>
              </div> -->
              </div>
          </div>
          <b-card v-show="Object.keys(Table.data['identity'].options).length!=0" no-body :header="IsNull(Msg1.title)">
            <b-list-group flush>
              <template v-for="(i2, i1) in Msg1.data">
                <b-list-group-item :key="i1" >{{ i2 }}</b-list-group-item>
              </template>
              <template v-for="(i2,i1) in Table.data['identity'].options" >
                <b-list-group-item :key="i1" v-show="Table.data['identity'].show">
                  {{i2.text}} {{i2.data.IDCA__TYPE==1?'減免':'折扣'}} <span class="text-primary">{{i2.val}}</span> 
                </b-list-group-item>
              </template>
            </b-list-group>
          </b-card>
        </div>
        </b-col>
      </b-row>

      
  
  
    </b-container>
  </div>
</template>

<script>


export default {
  name: "Course",

  data() {
    return {
      modal_set: [{ name: "form1", id: "signin" }],
      data_in: {},
			files: {},
      categoriesName:{},
      identityData:{},
      courseStatusStyle:{
        '':{variant:'primary'},
        '規劃中':{variant:'primary'},
        '已截止':{variant:'primary'},
        '招生中':{variant:'primary'},
        '已成班':{variant:'primary'},
        '開課中':{variant:'primary'},
        '早鳥優惠':{variant:'primary'},
        '仍可報名':{variant:'primary'},
        '已結束':{variant:'primary'},
        '最新課程':{variant:'primary'},
        '熱門課程':{variant:'primary'},
      },
      
      course: {
        courseStatus:[],
      },
      Table:{
        title:'課程資訊',
        data:{
          date:{text:'上課日期',val:'',val2:'',show:true},
          time:{text:'上課時間',val:'',val2:'',show:true},
          memo:{text:'上課時間備註',val:'',show:true},
          type:{text:'課程類別',val:'',show:true},
          CS_CREDIT:{text:'學分數',val:'',show:true},
          CA_HOURS:{text:'上課時數',val:'',show:true},
          CA_NUMBER:{text:'招生名額',val:'',show:true},
          LOC_NAME:{text:'上課地點',val:'',show:true},
          LOC_CLASSROOM_NAME:{text:'上課教室',val:'',show:true},
          CA_SUITABLE:{text:'適合對象',val:'',show:true},
          CA_QUALIFICATIONS:{text:'報名資格',val:'',show:true},
          date2:{text:'報名日期',val:'',val2:'',show:true},
          price:{text:'單人報名',val:'',show:true,off:''},
          price2:{text:'團體報名',val:'',show:true,off:'',num:''},
          identity:{text:'身分優惠',show:true,
                    options:{}
          },

        }
      },
      data_all: {},
      Msg1: {
        title: "優惠辦法",
        data: [],
      },
    };
  },

  computed: {
    signinBegin: function() {
      let begin = new Date(this.course.CA_BEGIN_DATE);
      return begin.getFullYear()+ "年" + (begin.getMonth()+1) + "月" + begin.getDate() + "日"
    }
  },

  mounted() {
    this.modal_init(this.modal_set);
    let navBgSwitch=this._storeData('navBgSwitch')
    if(this.IsNull(navBgSwitch)!=''){
      navBgSwitch(false)
    }
    this.sessionF1();
  },
  methods: {
    dateIf(){

      if(this.IsNull2(this.course)==='' || 
        this.IsNull2(this.course.canSignin)===''){
          return false
      }

      return this.course.canSignin
      /*
      if(this.IsNull(date)==''){
        return false
      }
      let dates=[
        Number(new Date()),
        Number(new Date(date))
      ]
      if(dates[1]<dates[0]){
        return false
      }else{
        return true
      }
      */
    },
    sessionF1(url, data) {
      if (this.IsNull(url) == "") {
        let data1 = this._go2({ key: "CP_ID" }, "query");
        if (this.IsNull(data1) == "") {
          this._go("/");
        } else {
          this.data_in = { CP_ID: data1 };
          this.FunctionToken(this.FunctionGetCourseFeriodSingleByIndex, {
            CP_ID: data1,
          });
          
        }
      } else if (url == "courseSignup") {
        this._go2(
          {
            path: "/courseSignup",
            data: {
              CP_ID: data.CP_ID,
              RF_IS_GROUP: data.RF_IS_GROUP,
            },
          },
          "query"
        );
      }
    },

    SignUp(type) {

      if(this.IsNull(this.course.canSignin)==false){  
        alert('未開放報名')
        return
      }

      if (this.IsNull(this._sessionData("TokenID")) == "") {
        //alert("尚未登入");
        let fun1=this._storeData('LoginF1')
        if(this.IsNull(fun1)!=''){
          let path1=this.$route.path
          this._storeData('LoginFUN',(path2,err)=>{
            if(path1==path2){
              this.sessionF1("courseSignup", {
                CP_ID: this.course.CP_ID,
                RF_IS_GROUP: type,
              });
            }else if(this.IsNull(err)!=''){
              err();
            }
            this._storeData('LoginFUN','')
          })
          fun1()
        }
        return;
      }

      this.sessionF1("courseSignup", {
        CP_ID: this.course.CP_ID,
        RF_IS_GROUP: type,
      });
    },
     turnDate: function(date) {
       if(this.IsNull(date)==''){
         return''
       }
      let newDate = new Date( date );
      return newDate.getFullYear()+ "年" + (newDate.getMonth()+1) + "月" + newDate.getDate() + "日"
    },

    ReTime(time){
      if(this.IsNull(time)==''){
        return ''
      }
      let times=time.split(':')
      return times[0]+':'+times[1]
    },



    //===========================================================================================
    //api
    FunctionGetCourseFeriodSingleByIndex(data_in) {
      this.apiGetCourseFeriodSingleByIndex(data_in)
        .then((res) => {
          let json = JSON.parse(res.data);
          if (json.Status) {

            let data1 = JSON.parse(json.Data);
            let imgUrl = [];
            if(this.IsNull(data1)!=''){
              this.CourseIf2(data1)
              this.course = data1;

              if(this.IsNull(this.course.CA_IMAGE_URL)!=''){
                imgUrl.push( this.course.CA_IMAGE_URL );
              }

              if(this.IsNull(this.course.PROFESSOR!='')){
                for(let i in this.course.PROFESSOR){
                  let item=this.course.PROFESSOR[i]
                  if(this.IsNull(item.PROF_PHOTO_URL)!=''){
                    imgUrl.push(item.PROF_PHOTO_URL)
                  }
                }
              }
            }

            console.log(this.course)


            this.Table.data['date'].val=this.IsNull(this.course.CA_BEGIN_TIME)
            this.Table.data['date'].val2=this.IsNull(this.course.CA_END_TIME)

            if(this.IsNull(this.course.CA_BEGIN_TIME)!=''){
              let date1=this.date2str2(new Date(this.course.CA_BEGIN_TIME))
              this.Table.data['time'].val=[date1['time'][0],date1['time'][1]].join(':')
            }
            if(this.IsNull(this.course.CA_END_TIME)!=''){
              let date1=this.date2str2(new Date(this.course.CA_END_TIME))
              this.Table.data['time'].val2=[date1['time'][0],date1['time'][1]].join(':')
            }
            this.Table.data['memo'].val=this.IsNull(this.course.CA_TIME_MEMO)


            this.Table.data['type'].val=''
            this.FunctionToken(this.FunctionGetCategoryListByIndex,{})
            if(this.IsNull(this.course.CS_IS_CREDIT)){
              this.Table.data['CS_CREDIT'].val=this.IsNull(this.course.CS_CREDIT)
            }else{
              this.Table.data['CS_CREDIT'].show=false
            }
            this.Table.data['CA_HOURS'].val=this.IsNull(this.course.CA_HOURS)
            if(this.IsNull(this.course.CA_UNLIMITED)){
              this.Table.data['CA_NUMBER'].val=this.IsNull2(this.course.CA_NUMBER)
            }else{
              this.Table.data['CA_NUMBER'].show=false
            }
            this.Table.data['LOC_NAME'].val=this.IsNull(this.course.LOC_NAME)
            this.Table.data['LOC_CLASSROOM_NAME'].val=this.IsNull(this.course.LOC_CLASSROOM_NAME)
            this.Table.data['CA_SUITABLE'].val=this.IsNull(this.course.CA_SUITABLE)
            this.Table.data['CA_QUALIFICATIONS'].val=this.IsNull(this.course.CA_QUALIFICATIONS)
            this.Table.data['date2'].val=this.IsNull(this.course.CA_BEGIN_DATE)
            this.Table.data['date2'].val2=this.IsNull(this.course.CA_END_DATE)
            this.Table.data['price'].val=this.IsNull2(this.course.CA_TUITION)
            if(this.course.courseStatus.indexOf('早鳥優惠')!=-1){
              this.Table.data['price'].off=this.IsNull2(this.course.CA_EARLY_TUITION)
            }
            this.Table.data['price2'].val=this.IsNull2(this.course.CA_TUITION)
            if(this.course.CA_IS_GROUP){
              this.Table.data['price2'].off=this.IsNull2(this.course.CA_GROUP_TUITION)
              this.Table.data['price2'].num=this.IsNull2(this.course.CA_GROUP_NUMBER)
            }

            if(this.IsNull(this.course['IDENTITY_DISCOUNT_COURSE_ADMISSIONS'])!=''&&
               this.course['IDENTITY_DISCOUNT_COURSE_ADMISSIONS'].length!=0){
               let options={}
               for(let i in this.course['IDENTITY_DISCOUNT_COURSE_ADMISSIONS']){
                 let item = this.course['IDENTITY_DISCOUNT_COURSE_ADMISSIONS'][i]
                 this.identityData[item.IDCA_ID]=item
                 let price =Number(this.IsNull(this.Table.data['price'].off,this.Table.data['price'].val))
                 let val=price
                 if(item.IDCA__TYPE==1){
                   val='NT$'+Number(item.IDCA__DISCOUNT)
                 }else{
                  let val2=item.IDCA__DISCOUNT+''
                  if(val2.length==1){
                    val=`0${val2}折`
                  }else if(val2.length==2){
                    if(val2[val2.length-1]=='0'){
                      val2=val2[0]
                    }
                    val=`${val2}折`
                  }
                
                }
                
                options[item.IDCA_ID]={
                  text:this.IsNull(item.ID_NAME),
                  val,
                  data:item
                  }

              }
              this.Table.data['identity'].options=options
              
            }else{
              this.Table.data['identity'].show=false
            }
            


            
            



						
            //測試用
            //this.course.canSignin=true
            

						if (imgUrl.length != 0) {
              this.FunctionToken( this.FunctionGetArchive, {
                MC_ID: imgUrl,
              });
            }
            
          }
        })
        .catch((err) => {
          this.Elog(err);
        });
    },

		FunctionGetArchive(data_in) {
      this.apiGetArchive(data_in)
        .then((res) => {
          let json = JSON.parse(res.data);
          if (json.Status) {
						let data1 = JSON.parse(json.Data);
            for (let i in data1) {
              let item = data1[i];
              this.files[item.MC_ID] = [item.SP_PATH_WEB, item.MC_FILE].join(
                ""
              );
            }
            this._watcher.run();
          }
        })
        .catch((err) => {
          console.log(err);
        });
    },

    FunctionGetCategoryListByIndex(data_in){
      this.apiGetCategoryListByIndex(data_in)
      .then(res=>{
        let json=JSON.parse(res.data)
        if(json.Status){
          let data1=JSON.parse(json.Data)
          for(let i in data1){
            let item=data1[i]
            this.categoriesName[item.CAT_ID]=item
          }
          if(this.IsNull(this.course['CA_CATEGORY_ID'])!=''){
            this.Table.data['type'].val=
            this.IsNullKeys(this.categoriesName,[this.course['CA_CATEGORY_ID'],'CAT_NAME'])
          }
        }
      })
      .catch(err=>{
        this.Elog(err)
      })
    }

   

  },
};
</script>
