<template>
    <div></div>
</template>

<!--<script src="https://apis.google.com/js/api.js" ></script>-->

<script>

export default {
    name:'LoginV1',
    props:{
        data_in:{
            type:[Object],
            default(){return{}}
        },
        val:{
            type:[String],
            default(){return ""}
        }
    },
    data(){
        return{}
    },
    mounted(){
        this.data_in[this.val]=this
    },
    methods:{
        GoogleModel(){
            
            let out={}
            out.id='23262750184-nk42e814jvif9pvfhlo3ddk1h4rqnj5o.apps.googleusercontent.com'
            out.star=()=>{
                gapi.load('client:auth2',{
                    callback:function(){
                        gapi.client.init({
                            clientId:out.id,
                            scope:' '
                        }).then(
                            function(success){
                                console.log(success)
                                console.log('Ge initialized successfully')
                            },
                            function(err){
                                console.log(err)
                            }
                        )
                    },
                    onerror:function(){
                        console.log('Failed to load libraries')
                    },
                })
            }

            out.login=(fun)=>{
                gapi.auth2.getAuthInstance().signIn()
                .then(res=>{
                    fun(res)
                })
                .catch(err=>{console.log(err)})
            }
            out.get=(fun)=>{
                gapi.client.request({path:'https://people.googleapis.com/v1/people/me?requestMask.includeField=person.emailAddresses'})
                .then(res=>{
                    /*
                    let data1=JSON.parse(res.body)
                    console.log(data1['emailAddresses'][0].value)
                    */
                    fun(res)
                })
                .catch(err=>{console.log(err)})
            }
            out.signOut=(fun)=>{
                gapi.auth2.getAuthInstance().signOut()
                .then(res=>{
                    console.log('signOut')
                    fun(res)
                })
                .catch(err=>{console.log(err)})
            }
            out.star();
            return out;
        },
        FBModel(){
           let out={}
           out.id='493961965774864' 
           out.var='v13.0'
           out.star=()=>{
               window.fbAsyncInit = function() {
                    FB.init({
                    appId      : out.id,
                    cookie     : true,
                    xfbml      : true,
                    version    : out.var
                    });
                    
                    FB.AppEvents.logPageView();   
                };
                (function(d, s, id){
                    var js, fjs = d.getElementsByTagName(s)[0];
                    if (d.getElementById(id)) {return;}
                    js = d.createElement(s); js.id = id;
                    js.src = "https://connect.facebook.net/zh-TW/sdk.js";
                    fjs.parentNode.insertBefore(js, fjs);
                }(document, 'script', 'facebook-jssdk'));
           }
           

           out.login=(fun)=>{
               FB.login(function(res){
                    fun(res)
               }, {
                    scope: 'email, public_profile',
                    return_scopes: true
                });
           }

           out.check=(fun)=>{
               FB.getLoginStatus(function(res) {
                fun(res)
               });
           }
           
           out.get=(fun)=>{
            FB.api("/me?fields=name,id,email", function (res){
                //user物件的欄位：https://developers.facebook.com/docs/graph-api/reference/user
                fun(res)
            }); 
           }

           out.star()
     

           return out

        }
    }
}
</script>