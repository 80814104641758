import Vue from 'vue'
import VueRouter from 'vue-router'
import Home from '../views/Home.vue'
import Courses from '../views/Courses.vue'
import Course from '../views/Course.vue'
import CourseSignup from '../views/CourseSignup.vue'
import MultiRegistration from '../views/MultiRegistration.vue'
import RegistrationComplete from '../views/RegistrationComplete.vue'

// 使用者
import Profile from '../views/user/Profile.vue'
import MyCourses from '../views/user/Courses.vue'
import MyRegistration from '../views/user/Registration.vue'
import MyRegistrationDetailed from '../views/user/RegistrationDetailed.vue'

// 最新消息
import News from '../views/News.vue'
import NewsDetail from '../views/NewsDetail.vue'

// 系列課程
import CourseSeries from '../views/CourseSeries.vue'
import CourseSeriesDetails from '../views/CourseSeriesDetails.vue'


Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'Home',
    component: Home
  },
  {
    path: '/courses',
    name: 'courses',
    component: Courses
  },
  {
    path: '/course',
    name: 'course',
    component: Course
  },
  {
    path: '/courseSignup',
    name: 'courseSignup',
    component: CourseSignup
  },
  {
    path: '/multiRegistration',
    name: 'multiRegistration',
    component: MultiRegistration
  },
  {
    path: '/registrationComplete',
    name: 'registrationComplete',
    component: RegistrationComplete
  },
  {
    path: '/about',
    name: 'about',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '../views/About.vue')
  },
  {
    path: '/qanda',
    name: 'QandA',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '../views/QandA.vue')
  },

  // 使用者
  {
    path: '/profile',
    name: 'Profile',
    component: Profile
  },
  {
    path: '/myCourses',
    name: 'MyCourses',
    component: MyCourses
  },
  {
    path: '/myRegistration',
    name: 'MyRegistration',
    component: MyRegistration
  },
  {
    path: '/myRegistrationDetailed',
    name: 'MyRegistrationDetailed',
    component: MyRegistrationDetailed
  },


  // 最新消息
  {
    path: '/news',
    name: 'News',
    component: News
  },
  {
    path: '/newsDetail',
    name: 'NewsDetail',
    component: NewsDetail
  },
  
  // 系列課程
  {
    path: '/courseSeries',
    name: 'CourseSeries',
    component: CourseSeries
  },
  {
    path: '/courseSeriesDetails',
    name: 'CourseSeriesDetails',
    component: CourseSeriesDetails
  },
  {
    path: '/Reload',
    name: 'Reload',
    component: ()=>import("../views/Reload.vue")
  },
  {
    path: '/Forget',
    name: 'Forget',
    component: ()=>import("../views/Forget.vue")
  },
  {
    path: '/RegistrationComplete',
    name: 'RegistrationComplete',
    component: ()=>import("../views/RegistrationComplete.vue")
  },
  {
    path:'/:pathMatch(.*)*',
    redirect:{name:'Home'}
  },


]

const router = new VueRouter({
  //mode: 'history',
  base: process.env.BASE_URL,
  routes
})


router.afterEach(()=>{
  document.body.scrollTop = document.documentElement.scrollTop = 0;
})

export default router
