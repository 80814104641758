<template>
  <div class="news">
    
    <b-container>
      <header class="pl-4 mb-3">
        <b-link class="mb-3" to="news"  variant="outline-primary"><b-icon-arrow-left-circle-fill></b-icon-arrow-left-circle-fill> 返回列表</b-link>
        <h1 class="title">{{ IsNull(data.NE_TITLE) }}。{{IsNull(typeName[data.NE_TYPE])}}</h1>
        <b-icon-clock></b-icon-clock> {{IsNull(data.JS_DATE) }}
      </header>
      <div class="detail">
        <b-img
          class="mb-3"
          v-show="IsNull(files[data.NE_MCID]) != ''"
          :src="IsNull(files[data.NE_MCID]) "
          fluid
        >
        </b-img>
        <!-- {{ typeName }} -->
        <div v-html="IsNull(data.NE_CONTENT)"></div>
       
      </div>
       <div class="text-center mb-5">
          <div class="newsBtg">
            <b-button variant="link" @click="btnGo('l')"
              ><b-icon-arrow-left></b-icon-arrow-left> 上一則
            </b-button>
            
            <b-button variant="link" @click="btnGo('r')"
              >下一則<b-icon-arrow-right></b-icon-arrow-right>
            </b-button>
          </div>
        </div>
    </b-container>
  </div>
</template>
<script>
export default {
  name: "NewsDetail",
  components: {},
  data() {
    return {
      files: {},
     
      data_in: {},
      typeName: {
        "1": "公告",
        "2": "重要公告",
        "3": "課程訊息",
        "4": "推廣教育小組",
        "5": "疫情公告",
        "6": "疫情公告",
      },
      data:{},
      data_all: [],
      
      head: {
        title: "消息標題",
        data: {
          date: "2020-04-04",
          type: "重要公告",
          title: "出入教育學習場所請配戴口罩",
        },
      },
      body: {
        title: "消息內容",
        data: {
          img: '',
          html: "",
        },
      },
    };
  },
  mounted() {
    let navBgSwitch=this._storeData('navBgSwitch')
    if(this.IsNull(navBgSwitch)!=''){
      navBgSwitch(false)
    }
    this.FunctionToken(this.FunctionGetNewsIndex, {});
    this.sessionF1();
  },
  methods: {
    sessionF1(url) {
      if (this.IsNull(url) == "") {
        let data1=this._go2({key:'NE_ID'},'query')
        if(this.IsNull(data1)!=''){
          this.data_in.NE_ID=this.IsNull(data1)
          this.FunctionToken(this.FunctionGetNewsSingle, {
            NE_ID: this.data_in.NE_ID,
          });
        }else{
          this._go('/')
        }
        
      }
    },
    btnGo(type) {
      if (
        this.IsNull(this.data_all) == "" ||
        this.data_all.length == 0 ||
        this.data_all.length == 1
      ) {
        return;
      }
      let index1 = this.data_all.findIndex(x => x.NE_ID == this.data.NE_ID);
      if (index1 == -1) {
        return;
      }

      if (type == "l") {
        if (index1 == 0) {
          let item = this.data_all[this.data_all.length - 1];
          this.FunctionToken(this.FunctionGetNewsSingle, {
            NE_ID: item.NE_ID,
          });
        } else {
          let item = this.data_all[index1 - 1];
          this.FunctionToken(this.FunctionGetNewsSingle, {
            NE_ID: item.NE_ID,
          });
        }
      } else if (type == "r") {
        if (index1 == this.data_all.length - 1) {
          let item = this.data_all[0];
          this.FunctionToken(this.FunctionGetNewsSingle, {
            NE_ID: item.NE_ID,
          });

        } else {
          let item = this.data_all[index1 + 1];
          this.FunctionToken(this.FunctionGetNewsSingle, {
            NE_ID: item.NE_ID,
          });
        }
      }
    },

    //======================================================
    //api
    FunctionGetNewsSingle(data_in) {
      this.apiGetNewsSingle(data_in)
        .then((res) => {
          let json = JSON.parse(res.data);
          if (json.Status) {
            let data1 = JSON.parse(json.Data);
            data1.JS_DATE=''
            if(this.IsNull(data1.NE_DATE)!=''){
              let date1=this.date2str(new Date(data1.NE_DATE))
              data1.JS_DATE=date1[0]+'年'+date1[1]+'月'+date1[2]+'日'
            }
            if(this.IsNull(data1.NE_MCID)!=''){
              this.FunctionToken(this.FunctionGetArchive, {
                MC_ID: [data1.NE_MCID],
              });
            }
            this.data=data1
            document.body.scrollTop = document.documentElement.scrollTop = 0;
          }
        })
        .catch((err) => {
          this.Elog(err);
        });
    },
    FunctionGetNewsIndex(data_in){
      this.apiGetNewsIndex(data_in)
      .then(res=>{
        let json=JSON.parse(res.data)
        if(json.Status){
          let data1=JSON.parse(json.Data)
          this.data_all=data1
        }
      })
      .catch(err=>{
        this.Elog(err)
      })
    },

    FunctionGetArchive(data_in) {
      this.apiGetArchive(data_in)
        .then((res) => {
          let json = JSON.parse(res.data);
          if (json.Status) {
            let data1 = JSON.parse(json.Data);
            for (let i in data1) {
              let item = data1[i];
              this.files[item.MC_ID] = [item.SP_PATH_WEB, item.MC_FILE].join( "");
            }
            this._watcher.run();
          }
        })
        .catch((err) => {
          this.Elog(err);
        });
    },
  },
};
</script>
