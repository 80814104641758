<template>
  <div class="page">
    <!-- 課程選單 -->
    <!--*********************************************************************************-->
    <header>
      <b-container> 
        <div v-show="filter2['categories'].show==false">
          <p class="mb-0">課程關鍵字：<span class="text-success">{{IsNull(filter2['keyword'])}}</span> </p>
          您可於上方重新搜尋或是 <b-link class="mb-2" @click="categoryShow()">檢視所有課程</b-link>
        </div>
        <div class="sec-title">
          <b-row class="pb-2" style="padding-top:80px" align-v="center">
            <b-col cols="auto">
              <h4 class="mb-0" v-if="IsNull(filter2['categories'].val) == ''">課程類別名稱</h4>
              <h4 class="mb-0" v-for="category in filter2['categories'].data" :key="category.CAT_ID">
                {{ (filter2['categories'].val == category.CAT_ID) ? category.CAT_NAME : '' }}
              </h4>
            </b-col>
            <b-col cols="auto">
              <b-button @click="cFilter = !cFilter"> <b-icon icon="filter"></b-icon> 過濾</b-button>
            </b-col>
          </b-row>
        </div>
        <div class="d-none d-sm-block mb-5" >
        <b-row align-v="center" v-show="filter2['categories'].show">
          <b-col cols="12" md="9">
            <b-nav pills class="categories atcourses">
              <b-nav-item
                class="catalog"
                v-for="category in filter2['categories'].data "
                :key="category.CAT_ID"
                @click="categoryClick(category)"
                :active="filter2['categories'].val == category.CAT_ID"
              >
                <div class="icon" :style="{ 'background-image': 'url(' +IsNull(images[category.CAT_ICON_URL])+ ')' }"></div>
                <!-- <img
                  :src=" IsNull(images[category.CAT_ICON_URL]) == '' ? '' : images[category.CAT_ICON_URL]
                  "
                /> -->
                <span style="display:block">{{ category.CAT_NAME }}</span>
              </b-nav-item>
            </b-nav>
          </b-col>
          <b-col class="d-none d-sm-block" md="3">
            <b-img :src="IsNull(images[IsNullKeys(categoriesName,[filter2.categories.val,'CAT_IMAGE_URL'])])" fluid></b-img>
          </b-col>
        </b-row>
        </div>
        
        <!--<b-button  squared   v-b-toggle.course-filter variant="primary" v-show="false" >進階搜尋</b-button>-->
      </b-container>
    </header>
    <!--*********************************************************************************-->
    
    <div v-bind:class="{ 'd-block': cFilter  }" class="box image2 c-d-none" v-show="filter2['courseStatus'].options.length>0 && filter2['hashtags'].options.length>0 && filter2['courseType'].options.length>0">
      <b-container>
        <b-row class="justify-content-center" >
          <b-col cols="auto">
            <h4 class="mb-4" style="font-weight: bold;" @click="filter2.show=!filter2.show"  >進階搜尋</h4>
            
          </b-col>
        </b-row>
        
          <div v-show="filter2.show" >
            <b-row class="mb-2" v-show="filter2['hashtags'].options.length>0" >
          <b-col cols="12" class="mb-1" md="auto" >課程標籤</b-col>
          <b-col >
            <b-form-group
              v-slot="{ ariaDescribedby }"
              class="filterItem"
            >
              <b-form-checkbox-group
              @change="filterF1()"
                v-model="filter2['hashtags'].val"
                :options="filter2['hashtags'].options"
                :aria-describedby="ariaDescribedby"
                name="buttons-1"
                button-variant="outline-primary"
                buttons
              ></b-form-checkbox-group>
            </b-form-group>
          </b-col>
        </b-row>
            <b-row class="mb-2" v-show="filter2['courseType'].options.length>0">
              <b-col cols="12" class="mb-1" md="auto">課程類型</b-col>
              <b-col>
                <b-form-group
                  v-slot="{ ariaDescribedby }"
                  class="filterItem"
                >
                  <b-form-checkbox-group
                  @change="filterF1()"
                    v-model="filter2['courseType'].val"
                    :options="filter2['courseType'].options"
                    :aria-describedby="ariaDescribedby"
                    name="buttons-1"
                    button-variant="outline-primary"
                    buttons
                  ></b-form-checkbox-group>
                </b-form-group>
              </b-col>
            </b-row>


            <b-row class="mb-2"  v-show="filter2['courseStatus'].options.length>0">
              <b-col cols="12" class="mb-1" md="auto">課程狀態</b-col>
              <b-col>
                  <b-form-group
                    v-slot="{ ariaDescribedby }"
                    class="filterItem"
                  >
                  <b-form-checkbox-group
                  @change="filterF1()"
                    v-model="filter2['courseStatus'].val"
                    :options="filter2['courseStatus'].options"
                    :aria-describedby="ariaDescribedby"
                    name="buttons-1"
                    button-variant="outline-primary"
                    buttons
                  ></b-form-checkbox-group>
                </b-form-group> 
              </b-col>
            </b-row>
          </div>
          
        
      </b-container>
    </div>
    
    <!--*********************************************************************************-->
    <b-container class="pt-3 pt-md-5">

      <template v-if="Courses.load==false">
        <div class="loading">
          <b-icon icon="three-dots" animation="cylon" font-scale="2" variant="white"></b-icon>
        </div>
      </template>
      <template v-else-if="Courses.data.length == 0">
        <b-alert variant="warning" class="text-center" show>您查詢的類別或關鍵字沒有可顯示的課程。</b-alert>
      </template>
      <template v-else-if="Courses.data.length > 0">
        <!-- <b-row align-v="center" class="mb-2">
          <b-col cols="auto mr-auto">
            <b-form-group>
              <b-form-radio-group
                id="btn-radios-1"
                v-model="Courses.displayType"
                name="radios-btn-default"
                buttons
                button-variant="secondary"
              >
              <b-form-radio value="grid"><b-icon-grid></b-icon-grid> 表格</b-form-radio>
              <b-form-radio value="list"><b-icon-list></b-icon-list> 列表</b-form-radio>
              </b-form-radio-group>
            </b-form-group>
          </b-col>
          <b-col cols="auto"><span class="fs-4">{{ Courses.data.length }}</span> 堂課程 </b-col>
        </b-row> -->
        <b-row  
          :cols-lg="Courses.displayType == 'grid' ? 3 : 1"
          class="courses"
          :class="Courses.displayType"
        >
          <b-col class="mb-5" v-for="(course, index) in page.reData(page,Courses.data)" :key="index">
            <b-card
              img-alt="Image"
              tag="article"
              class="course"
              :img-left="Courses.displayType == 'list'"
              no-body
            >
              <div class="state d-none">
                <template v-for="(item, index) in course.courseStatus">
                  <b-badge pill :key="index"
                    v-bind="IsNull(courseStatusStyle[IsNull(item)],courseStatusStyle[''])"
                  >{{IsNull(item)}}</b-badge>
                </template>
              </div>

              <b-link class="mainImage" @click="TableGo(course.CP_ID)">
                <figure>
                <b-card-img
                  :src=" IsNull(images[course.CA_IMAGE_URL]) == '' ? '' : images[course.CA_IMAGE_URL] "
                ></b-card-img>
                </figure>
              </b-link>
              
              <b-card-body>
                <div class="mb-2">
                <b-badge class="mr-1" v-for="(j2,j1) in (IsNull(course.CA_HASHTAG_ID)==''?[]:course.CA_HASHTAG_ID.split(','))"  :key="j1">
                  {{IsNull(j2)}}
                  </b-badge>
                </div>
                <b-card-title title-tag="h5">
                  {{ IsNull(course.CS_TITLE) }} <br>
                  <!-- <small> 第{{IsNull(course.CA_PERIOD)}}期 </small>  -->
                  <small style="color: #888;" v-html="Re_html(course.CA_ABSTRACT)"></small>
                </b-card-title>
                
                
            
                <!-- <b-card-text class="description" v-html="IsNull(course.CA_INTRODUCTION)"></b-card-text> -->
                <ul class="list-unstyled mb-0" v-show="false">

                  <li
                    v-for="professor in course.PROFESSOR"
                    :key="professor.PROF_ID"
                    class="professor"
                  >
                    {{ professor.PROF_NAME }}
                  </li>

                  <li>
                    開課日期：
                    {{ turnDate(course.CA_BEGIN_TIME) }} 
                    <span class="end">～ 
                    {{ turnDate(course.CA_END_TIME) }}
                    </span> 
                  </li>

                  <li>上課時數：{{IsNull(course.CA_HOURS)}}</li>

                  <li>
                    課程類別：<span v-if="course.CA_IS_ENTRUS">委訓合作</span><span v-if="course.CS_IS_CREDIT">學分班</span><span v-else>非學分班</span>
                  </li>      
                              
                  <li v-if="course.CS_IS_CREDIT">學分：{{ IsNull(course.CS_CREDIT)}}</li>

                  <li class="begin-signin" 
                      v-if="course.CA_IS_BEGIN_DATE && course.CA_OPEN">
                    開放報名：{{ turnDate(course.CA_BEGIN_DATE) }}
                  </li>

                  <li class="end-signin" 
                      v-if="course.CA_IS_END_DATE && course.CA_OPEN">
                    截止報名：{{turnDate(course.CA_END_DATE) }}
                  </li>

                </ul>
                
              </b-card-body>

              <b-card-footer>
                
                <div class="signin">
                  <ul class="list-unstyled">
                    <li>
                      原價 <span class="price" style="text-decoration:line-through;color: #888;"> NT${{ IsNull2(course.CA_ORIGIN_TUITION) }}</span> 
                      <span class="price"> <span class="number">NT${{ IsNull2(course.CA_TUITION) }}</span></span> 
                    </li>
                  </ul>
                  <b-button v-if="course.canSignin" block variant="primary" class="mb-2" @click="butGo(0, course)">我要報名</b-button>
                  <p v-if="course.CA_IS_GROUP">團體報名 <span class="price"><span class="number"> NT${{ IsNull2(course.CA_GROUP_TUITION,IsNull2(course.CA_TUITION)) }}</span></span></p> 
                  <b-button  v-if="course.canSignin && course.CA_IS_GROUP" variant="primary" block @click="butGo(1, course)">團體報名</b-button>
                </div>
              </b-card-footer>

              

            </b-card>

          </b-col>
        </b-row>

        <b-row v-show="page.count<=Courses.data.length">
          <b-col>
            <b-pagination
            v-model="page.val"
            :total-rows="page.rows"
            :per-page="page.perPage"
          ></b-pagination>  

          </b-col>
        </b-row>
         

      </template>


    


      


    </b-container>
    <!--*********************************************************************************-->
    <!-- <div class="alert alert-primary" role="alert">
      {{ resultCourses }}
      {{keyword}}
    </div> -->
  </div>
</template>

<script>

export default {
  name: "coursesPa",
 
  data() {
    return {
      cFilter: false,
      data_in:{},
      images: {
        'MC_categoriesAll':'',
        'MC_categoriesAll2':'',
      },
      hashtagsName:[],
      categoriesName:{},
      courseStatusStyle:{
        '':{class:'mr-1',variant:'primary'},
        '規劃中':{class:'mr-1',variant:'primary'},
        '已截止':{class:'mr-1',variant:'primary'},
        '招生中':{class:'mr-1',variant:'primary'},
        '已成班':{class:'mr-1',variant:'primary'},
        '開課中':{class:'mr-1',variant:'primary'},
        '早鳥優惠':{class:'mr-1',variant:'primary'},
        '仍可報名':{class:'mr-1',variant:'primary'},
        '已結束':{class:'mr-1',variant:'primary'},
        '最新課程':{class:'mr-1',variant:'primary'},
        '熱門課程':{class:'mr-1',variant:'danger'},
      },

      categoriesAll:{
        CAT_DESCRIPTION:'全部',
        CAT_DISPLAY:1,
        CAT_ICON_URL:'MC_categoriesAll',
        CAT_ID:'JS',
        CAT_IMAGE_URL:'MC_categoriesAll2',
        CAT_ISDEL:0,
        CAT_NAME:'全部',
        CAT_SORT:','
      },

      filter2:{
        show:true,
        categories:{
          categoriesBg:'',
          val:'',
          data:[],
          show:true,
        },
        hashtags:{
          val:[],
          options:[],
          optionsAll:[],
          set2(th1,if_list){
            let options=[]
            //let val=[]
            for(let i in th1.optionsAll){
              let item=th1.optionsAll[i]
              if(if_list.indexOf(item.value)!=-1){
                options.push(item)
              }
              /*
              if(th1.val.indexOf(item.value)!=-1){
                val.push(item.value)
              }
               */
            }
            //th1.val=val
            th1.options=options
          },
        },
        courseType:{
          val:[],
          options:[],
          optionsAll:[
             { text: '非學分班課程', value: '非學分班課程' },
             { text: '學分班課程', value: '學分班課程' },
             { text: '委訓班', value: '委訓班' }
          ],
          set2(th1,if_list){
            let options=[]
            //let val=[]
            for(let i in th1.optionsAll){
              let item=th1.optionsAll[i]
              if(if_list.indexOf(item.value)!=-1){
                options.push(item)
              }
              /*
              if(th1.val.indexOf(item.value)!=-1){
                val.push(item.value)
              }
              */
            }
            ///th1.val=val
            th1.options=options
          },
        },
        courseStatus:{
          val:[],
          options:[],
          optionsAll:[
            { text: '規劃中', value: '規劃中' },
            { text: '已截止', value: '已截止' },
            { text: '招生中', value: '招生中' },
            { text: '已成班', value: '已成班' },
            { text: '開課中', value: '開課中' },
            { text: '早鳥優惠', value: '早鳥優惠' },
            { text: '仍可報名', value: '仍可報名' },
            { text: '已結束', value: '已結束' },
            { text: '最新課程', value: '最新課程' },
            { text: '熱門課程', value: '熱門課程' },
          ],
          set2(th1,if_list){
            let options=[]
            //let val=[]
            for(let i in th1.optionsAll){
              let item=th1.optionsAll[i]
              if(if_list.indexOf(item.value)!=-1){
                options.push(item)
              }
              /*
              if(th1.val.indexOf(item.value)!=-1){
                val.push(item.value)
              }
              */
            }
            //th1.val=val
            th1.options=options
          },
        },
        keyword:'',
      },
      Courses:{
        title:'課程資料',
        load:false,
        displayType:'grid',
        dataAll:[],
        data:[]
      },
       page:{
          val:1,
          rows:3,
          perPage:1,
          count:21,
          reData(th1,data){
            let list=[]
            let n1=th1.count*(th1.val-1)
            for(let i=n1 ; i<(n1+th1.count);i++){
              if(i>(data.length-1)){
                break;
              }
              list.push(data[i])
            }
            return list
          },
          set(th1,length,count){
            th1.val=1
            th1.rows=Math.ceil(length/count)
            th1.count=count
          },
        },

    }
  },
  created() {
    
  },
  mounted() {
    let navBgSwitch=this._storeData('navBgSwitch')
    if(this.IsNull(navBgSwitch)!=''){
      navBgSwitch(false)
    }
    this.filter2['categories'].val=this.OnlyData['CAT'][0]['CAT_ID']
    this.sessionF1();
    this.FunctionToken(this.FunctionGetCategoryListByIndex, {}); 
    this.FunctionToken(this.FunctionGetCourseFeriodListByIndex_mini, {});
   
  },
  methods: {
    // session
    sessionF1(url, data) {
      if (this.IsNull(url) == "") {
        //let data1 = this._go2({ key: "CAT_ID" }, "query");
        let data1 = this._sessionData('CAT_ID') 
        if (this.IsNull(data1) != "") {
          this.data_in['CAT_ID'] =data1;
        }
        //let data2=this._go2({ key: "keyword" }, "query");
        let data2 = this._sessionData('keyword') 
        if(this.IsNull(data2)!=''){
          this.data_in['keyword']=data2
          this.filter2['keyword']=data2
          this.categoryClose()
        }
        let data3=this._sessionData('courseStatus') 
        if(this.IsNull(data3)!=''){
          this.data_in['courseStatus']=data3
          this.filter2['courseStatus'].val.push(data3)
        }
        this._sessionData('CAT_ID','')
        this._sessionData('keyword','')
        this._sessionData('courseStatus','') 

      } else if (url == "course") {
        let url=this.LocaPath.default+'course?CP_ID={CP_ID}'
        this._go1(this.Str_Replace(url,'{CP_ID}',data),1)
      } else if (url == "courseSignup") {
        this._go2(
          {
            path: "/courseSignup",
            data: { CP_ID: data.CP_ID, RF_IS_GROUP: data.RF_IS_GROUP },
          },
          "query"
        );
      }
    },

    filterF1(){
  
      let filter2={
        hashtags:[],
        courseType:[],
        courseStatus:[]
      }
      if((this.IsNull(this.filter2['categories'].val)==''  ||
          this.OnlyData['CAT'].findIndex(x=>x.CAT_ID==this.filter2['categories'].val)!=-1) &&
          this.filter2['hashtags'].val.length==0 &&
          this.filter2['courseType'].val.length==0 &&
          this.filter2['courseStatus'].val.length==0 && 
          this.IsNull(this.filter2['keyword'])==''
        ){
          this.page.set(this.page,this.Courses.dataAll.length,this.page.count)
          this.Courses.data=this.Courses.dataAll
          for(let i in this.Courses.data){
            let item=this.Courses.data[i]
            if(this.IsNull(item.CA_HASHTAG_ID)!=''){
              filter2['hashtags']=filter2['hashtags'].concat(item.CA_HASHTAG_ID.split(','))
            }
            filter2['courseType']=filter2['courseType'].concat(item.courseType)
            filter2['courseStatus']=filter2['courseStatus'].concat(item.courseStatus)
          }
          this.filter2['hashtags'].set2(this.filter2['hashtags'],filter2['hashtags'])
          this.filter2['courseType'].set2(this.filter2['courseType'],filter2['courseType'])
          this.filter2['courseStatus'].set2(this.filter2['courseStatus'],filter2['courseStatus'])
          return
      }

      let data1=[]
      for(let i in this.Courses.dataAll){
        let item=this.Courses.dataAll[i]
        let if1=0,if2=0;


        if(this.IsNull(this.filter2['keyword'])!=''){
          if1+=1

          let keyword=this.filter2['keyword'].toLowerCase()
          let CS_TITLE=this.IsNull(item.CS_TITLE).toLowerCase()
          let CA_HASHTAG_IDs=this.IsNull(item.CA_HASHTAG_ID).split(',')
          CA_HASHTAG_IDs.forEach((i2,i1)=>{CA_HASHTAG_IDs[i1]=i2.toLowerCase()})

          if((this.IsNull(item.CS_TITLE)!='' && CS_TITLE.indexOf(keyword)!=-1) ||
            (this.IsNull(item.CA_HASHTAG_ID)!='' &&CA_HASHTAG_IDs.indexOf(keyword)!=-1)){
            if2+=1
            if(this.IsNull(item.CA_HASHTAG_ID)!=''){
              filter2['hashtags']=filter2['hashtags'].concat(item.CA_HASHTAG_ID.split(','))
            }
            filter2['courseType']=filter2['courseType'].concat(item.courseType)
            filter2['courseStatus']=filter2['courseStatus'].concat(item.courseStatus)
          }
        }else{
          if(this.IsNull(this.filter2['categories'].val)!='' && 
            this.OnlyData['CAT'].findIndex(x=>x.CAT_ID==this.filter2['categories'].val)==-1) {
            if1+=1
            if(item.CA_CATEGORY_ID==this.filter2['categories'].val){
              if2+=1
              if(this.IsNull(item.CA_HASHTAG_ID)!=''){
                filter2['hashtags']=filter2['hashtags'].concat(item.CA_HASHTAG_ID.split(','))
              }
              filter2['courseType']=filter2['courseType'].concat(item.courseType)
              filter2['courseStatus']=filter2['courseStatus'].concat(item.courseStatus)
            }
          }else{
            if(this.IsNull(item.CA_HASHTAG_ID)!=''){
              filter2['hashtags']=filter2['hashtags'].concat(item.CA_HASHTAG_ID.split(','))
            }
            filter2['courseType']=filter2['courseType'].concat(item.courseType)
            filter2['courseStatus']=filter2['courseStatus'].concat(item.courseStatus)
          }

        }
        

        

        if(this.filter2['hashtags'].val.length!=0){
          if1+=1
          if(this.IsNull(item.CA_HASHTAG_ID)!=''){
            if(item.CA_HASHTAG_ID.split(',').filter(x=>this.filter2['hashtags'].val.indexOf(x)!=-1).length!=0){
              if2+=1
            }
          }
        }

        if(this.filter2['courseType'].val.length!=0){
          if1+=1
          if(item.courseType.filter(x=>this.filter2['courseType'].val.indexOf(x)!=-1).length!=0){
            if2+=1
          }
        }

        if(this.filter2['courseStatus'].val.length!=0){
          if1+=1
          if(item.courseStatus.filter(x=>this.filter2['courseStatus'].val.indexOf(x)!=-1).length!=0){
            if2+=1
          }
        }

        
        if(if1==if2){
          data1.push(item)
          
        }
      }
      console.log(filter2)
      this.page.set(this.page,data1.length,this.page.count)
      this.Courses.data=data1
      this.filter2['hashtags'].set2(this.filter2['hashtags'],filter2['hashtags'])
      this.filter2['courseType'].set2(this.filter2['courseType'],filter2['courseType'])
      this.filter2['courseStatus'].set2(this.filter2['courseStatus'],filter2['courseStatus'])
      
    },


    // 日期樣態調整
    turnDate: function(date) {
      if(this.IsNull(date)==''){
        return ''
      }
      let newDate = new Date( date );
      return newDate.getFullYear()+ "年" + (newDate.getMonth()+1) + "月" + newDate.getDate() + "日"
    },
    categoryClick(data){
      this.filter2['categories'].val=data.CAT_ID
      this.filter2['hashtags'].val=[]
      this.filter2['courseType'].val=[]
      this.filter2['courseStatus'].val=[]
      this.filterF1()
    },
    categoryShow(){
      this.filter2['keyword']=''
      this.filter2['categories'].show=true
      this.filterF1()
    },
    categoryClose(){
      this.filter2['categories'].show=false
    },
    

     // 來去報名
    butGo(type, data) {
      if(data.canSignin==false){
        alert('未開放報名')
        return
      }
      if (this.IsNull(this._sessionData("TokenID")) == "") {
        //alert("尚未登入");
        let fun1=this._storeData('LoginF1')
        if(this.IsNull(fun1)!=''){
          let path1=this.$route.path
          this._storeData('LoginFUN',(path2,err)=>{
            if(path1==path2){
              this.sessionF1('courseSignup',{
                CP_ID:data.CP_ID,
                RF_IS_GROUP:type
              })
            }else if(this.IsNull(err)!=''){
              err();
            }
            this._storeData('LoginFUN','')
          })
          fun1()
        }
        return;
      }
      this.sessionF1('courseSignup',{
        CP_ID:data.CP_ID,
        RF_IS_GROUP:type
      })
    },

    // 來去看課程詳細資訊
    TableGo(data) {
      this.sessionF1("course", data);
    },

    Re_html(val){
      if(this.IsNull(val)===''){
        return ''
      }

      return this.Str_Replace2(val,[{old:'\r\n',new:'<br>'},{old:'\n',new:'<br>'}])
      
    },
    //===============================================================================
    // API
    // 取得課程
      FunctionGetCourseFeriodListByIndex_mini(data_in) {
      this.apiGetCourseFeriodListByIndex_mini(data_in)
        .then((res) => {
          let json = JSON.parse(res.data);
          if (json.Status) {
            let courses=JSON.parse(json.Data)
            if(this.IsNull(courses)!='' && courses.length!=0){
              //let curr = Date.parse(new Date().toDateString());
              let imgUrl = [];
              for(let i in courses){
                let course = courses[i]
                
                if(this.IsNull(course.CA_IMAGE_URL)!=''){
                  imgUrl.push(course.CA_IMAGE_URL);
                }
                if(this.IsNull(course.CA_HASHTAG_ID)!=''){

                  let item2=course.CA_HASHTAG_ID.split(',')

                  for(let j in item2){
                    if(this.hashtagsName.indexOf(item2[j])==-1){
                      this.hashtagsName.push(item2[j])
                      this.filter2['hashtags'].optionsAll.push(
                        {value:item2[j],text:item2[j]}
                      )
                    }
                    
                  }
                }
                this.CourseIf2(course)
                /*
                let begin = Date.parse(course.CA_BEGIN_TIME).valueOf();
                let end = Date.parse(course.CA_END_TIME).valueOf();
                let beginSignin = Date.parse(course.CA_BEGIN_DATE).valueOf();
                let endSignin = Date.parse(course.CA_END_DATE).valueOf();

                course.courseStatus = [];
                course.courseType = [];
                course.canSignin = false;

                



                if (course.CA_IS_ENTRUS == 1) {
                  course.courseType.push("委訓合作");
                }
                if (course.CS_IS_CREDIT == 1) {
                  course.courseType.push("學分班課程");
                } else {
                  course.courseType.push("非學分班課程");
                }

                //-----------------------------------------------------------------
                if( curr < begin && course.CA_OPEN =="0" ) {
                  // 未達開課時間、未開放報名
                  course.courseStatus.push('規劃中')
                } else if (curr < begin) {
                  // 未達開課時間
                  if (course.CA_IS_END_DATE && curr > endSignin) {
                    course.courseStatus.push("已截止");
                  } else {
                    // 課程未開始招生中
                    if (course.CA_IS_BEGIN_DATE && curr > beginSignin) {
                      course.courseStatus.push("招生中");
                      course.canSignin = true;
                    } else if (course.CA_IS_BEGIN_DATE && curr < beginSignin) {
                      course.courseStatus.push("規劃中");
                    } else {
                      course.courseStatus.push("招生中");
                      course.canSignin = true;
                    }
                  }
                  // 早鳥訊息
                  if ( this.IsNull(course.CA_IS_EARLY) && curr < course.CA_EARLY_END_DATE) {
                    course.courseStatus.push("早鳥優惠");
                  }
                  // 已成班
                  if (this.IsNull(course.CA_IS_DEFINITE)) {
                    course.courseStatus.push("已成班");
                  }
                } else if (curr > begin && curr < end) {
                  // 課程進行中
                  course.courseStatus.push("開課中");
                  // 仍可報名
                  if (this.IsNull(course.CA_EXTEND)) {
                    course.courseStatus.push("仍可報名");
                    course.canSignin = true;
                  }
                } else {
                  course.courseStatus.push("已結束");
                }
                //-----------------------------------------------------------------
                */
                if (imgUrl.length != 0) {
                  this.FunctionToken(this.FunctionGetArchive, {
                    MC_ID: imgUrl,
                  });
                }
              }
              this.page.set(this.page,courses.length,this.page.count)
              
              this.Courses.data=courses
              this.Courses.dataAll=courses
              this.filterF1()

              if (imgUrl.length != 0) {
                this.FunctionToken(this.FunctionGetArchive, {
                  MC_ID: imgUrl,
                });
              }
            }
            this.Courses.load=true

          }
        })
        .catch((err) => {
          console.log(err);
        });
    },
    // 取得課程顯示類別
    FunctionGetCategoryListByIndex(data_in) {
      this.apiGetCategoryListByIndex(data_in)
        .then((res) => {
          let json = JSON.parse(res.data);
          if (json.Status) {
            let categories = JSON.parse(json.Data);
            let iconUrl = [];
            //this.filter2['categories'].data=[this.categoriesAll].concat(categories)
            //this.categoriesName['']=this.categoriesAll
            this.filter2['categories'].data=categories
            for (let i = 0; i < categories.length; i++) {
              let item=categories[i]
              this.categoriesName[item.CAT_ID]=item
              if (this.IsNull(categories[i].CAT_ICON_URL) != "") {
                iconUrl.push(categories[i].CAT_ICON_URL);
              }
              if (this.IsNull(categories[i].CAT_IMAGE_URL) != "") {
                iconUrl.push(categories[i].CAT_IMAGE_URL);
              }

              if(this.IsNull(this.data_in.CAT_ID)!='' && 
                 this.data_in.CAT_ID==categories[i].CAT_ID){
                 this.filter2['categories'].val=this.data_in.CAT_ID
              }
            }
            
            if (iconUrl.length != 0) {
              this.FunctionToken(this.FunctionGetArchive, {
                MC_ID: iconUrl,
              });
            }
          }
        })
        .catch((err) => {
          console.log(err);
        });
    },
    // 取得圖片
    FunctionGetArchive(data_in) {
      this.apiGetArchive(data_in)
        .then((res) => {
          let json = JSON.parse(res.data);
          if (json.Status) {
            let data1 = JSON.parse(json.Data);
            for (let i in data1) {
              let item = data1[i];
              this.images[item.MC_ID] = encodeURI([item.SP_PATH_WEB, item.MC_FILE].join(""));
            }
            this._watcher.run();
          }
        })
        .catch((err) => {
          console.log(err);
        });
    },
  },
}
</script>

<style lang="scss" scoped>
  .page {
    min-height: 100vh;
  }
  .loading {
    background-color: rgba($color: #000000, $alpha: 0.65);
    justify-content:center;
    align-items:center;
    position: fixed;
    display: flex;
    left: 0;
    top: 0;
    z-index: 1005;
    min-width: 100vw;
    min-height: 100vh;
  }
  header{
    // background-color: #e5f1f8;
    // border-bottom: 1px solid #cbe4f2;
    >.container {
      // padding-top: 7rem;
      // padding-bottom: 2rem;
      background-size: auto 90%;
      background-repeat: no-repeat;
      background-position: right center;
    }
  }

</style>