<template>
    <div>
        <header class="course-header" v-bind:style="{ backgroundImage: 'url(' + course.mainImage + ')' }">
            <b-container style="z-index: 1001; position: relative;">
                <p>
                     <b-badge v-for="(i2,i1) in badge.data" :key="i1" 
                            pill :variant="i2.style1" :class="i2.class">
                            {{i2.text}}
                    </b-badge>
                </p>
                <h1 class="course-title">{{head.text}}
                    <b-badge style="vertical-align: text-bottom;">{{head.text3}}</b-badge>
                </h1>
                <p>{{head.text2}}</p>
            </b-container>
            <div class="mask"></div>
        </header>
        <!--***************************************************************************************-->
        <b-container style="top:-6px; z-index: 1000; position: relative;">
            <div class="Course p-5">
                <p>{{form1.title}}</p>
                <b-form-group id="input-group-1" label="學員人數">
                    <InputV1 :type="form1.data['num'].type" :data_in="form1.data['num']"></InputV1>
                    
                </b-form-group>
                <h3 class="mb-3">{{form1.title2}}</h3>

                <template v-for="(i2,i1) in form1.datas">
                    <div class="border shadow px-5  pt-4 pb-3 mb-5" :key="i1" >
                        <b-row>
                            <b-col cols="auto">
                                {{i1+1}}
                            </b-col>
                            <b-col>

                                 <b-row cols="2">
                                     <b-col v-show="i2['RF_NAME'].show">
                                        <b-form-group label="學員姓名">
                                            <InputV1 :type="i2['RF_NAME'].type" :data_in="i2['RF_NAME']"></InputV1>
                                        </b-form-group>
                                    </b-col>
                                    <b-col v-show="i2['RF_SEX'].show">
                                        <b-form-group label="性別">
                                            <InputV1 :type="i2['RF_SEX'].type" :data_in="i2['RF_SEX']"></InputV1>
                                        </b-form-group>
                                    </b-col>

                                    <b-col v-show="i2['RF_NID'].show">
                                        <b-form-group label="身份證號">
                                            <InputV1 :type="i2['RF_NID'].type" :data_in="i2['RF_NID']"></InputV1>
                                        </b-form-group>
                                    </b-col>

                                    <b-col v-show="i2['RF_PHONE'].show">
                                        <b-form-group label="行動電話">
                                            <InputV1 :type="i2['RF_PHONE'].type" :data_in="i2['RF_PHONE']"></InputV1>
                                        </b-form-group>
                                    </b-col>

                                     <b-col v-show="i2['RF_TEL'].show">
                                        <b-form-group label="聯絡電話">
                                            <InputV1 :type="i2['RF_TEL'].type" :data_in="i2['RF_TEL']"></InputV1>
                                        </b-form-group>
                                    </b-col>

                                    <b-col v-show="i2['RF_MAIL'].show">
                                        <b-form-group label="電子信箱">
                                            <InputV1 :type="i2['RF_MAIL'].type" :data_in="i2['RF_MAIL']"></InputV1>
                                        </b-form-group>
                                    </b-col>

                                    <b-col v-show="i2['RF_BDATE'].show">
                                        <b-form-group label="出生日期">
                                            <InputV1 :type="i2['RF_BDATE'].type" :data_in="i2['RF_BDATE']"></InputV1>
                                        </b-form-group>
                                    </b-col>

                                    <b-col v-show="i2['RF_ADDRESS'].show">
                                        <b-form-group label="地址">
                                            <InputV1 :type="i2['RF_ADDRESS'].type" :data_in="i2['RF_ADDRESS']"></InputV1>
                                        </b-form-group>
                                    </b-col>

                                 </b-row>

                            </b-col>
                        </b-row>
                    </div>

                </template>

               
                <div class="text-center mb-5">
                    <!--<b-button to="courseSignup">下一步</b-button>-->
                    <b-button @click="form1Save()">下一步</b-button>
                </div>
            </div>

        </b-container>
        <!--***************************************************************************************-->
    </div>
</template>
<script>
    import InputV1 from '../components/InputV1.vue'
    export default {
        name:'MultiRegistration',
        components:{
            InputV1
        },
        data() {
            return {
                 badge:{
                    title:'',
                    data:[
                        {style1:'primary',text:'PHP',class:'mr-1'},
                        {style1:'secondary',text:'Website',class:'mr-1'},
                        {style1:'success',text:'Laravel',calss:''},
                    ]
                },
                head:{
                    text:'無人機實務飛行特訓班',
                    text2:'用最輕鬆、最直覺、最好理解的方式，學會寫程式的核心基礎',
                    text3:'梯次'
                },
                view:{type:''},

                form1:{
                    title:'報名人數說明',
                    title2:'學員資料',
                    data:{
                        num:{type:'select2',val:'1',text:'',only:false,
                          options:{
                          '1':{val:'1',text:'1'},
                          '2':{val:'2',text:'2'},
                          '3':{val:'3',text:'3'},
                          '4':{val:'4',text:'4'},
                          '5':{val:'5',text:'5'},
                          '6':{val:'6',text:'6'},
                          '7':{val:'7',text:'7'},
                          '8':{val:'8',text:'8'},
                          '9':{val:'9',text:'9'},
                          '10':{val:'10',text:'10'},
                          },
                          msg:'',
                          }
                    },
                    datas:[
                        
                    ]
                },

                numberOfStudent: 3,
                course: {
                    mainImage: '',
                },
                studentNumber: 1,
            }
        },
        mounted(){
            this.form1.data['num'].change=this.form1numChange
            this.form1numChange()
        },
        methods:{
            form1numChange(){
                let list=[]
                for(let i =0;i<this.form1.data['num'].val;i++){
                    list.push(this.form1Creat())
                }
                this.form1.datas=list
            },
            form1Creat(){
                let json1={
                    RF_NAME:{type:'text',val:'',text:'請輸入學員姓名',only:false,msg:'',show:true},
                    RF_SEX:{type:'text',val:'',text:'請輸入性別',only:false,msg:'',show:true},
                    RF_NID:{type:'text',val:'',text:'請輸入身份證號',only:false,msg:'',show:true},
                    RF_PHONE:{type:'text',val:'',text:'請輸入行動電話',only:false,msg:'',show:true},
                    RF_TEL:{type:'text',val:'',text:'請輸入聯絡電話',only:false,msg:'',show:true},
                    RF_MAIL:{type:'text',val:'',text:'請輸入電子信箱',only:false,msg:'',show:true},
                    RF_BDATE:{type:'date',val:'',only:false,msg:'',show:true},
                    RF_ADDRESS:{type:'text',val:'',text:'請輸入地址',only:false,msg:'',show:true},
                }
                return json1
            },
           
        }
    }
</script>