<template>
    <div>
        <header class="mb-3 text-primary" style="padding-top:80px">
            <b-container>
                <h3 class="ml-3">課程報名</h3>
                <!-- ：{{head.text}} -->
            </b-container>
        </header>
        <!--***************************************************************************************-->
        <b-modal id="modal-m1"  scrollable  >
                    <template #modal-title> {{form2.title}}</template>
                    <b-form-group  v-show="form2.data['RF_NAME'].show">
                        <template #label>
                            <b-badge variant="danger  mt-1 mr-2">必填</b-badge>學員姓名
                        </template>
                            <InputV1 
                            :type="form2.data['RF_NAME'].type" 
                            :data_in="form2.data['RF_NAME']">
                            </InputV1>
                    </b-form-group>
                    <b-form-group  v-show="form2.data['RF_SEX'].show">
                        <template #label>
                            <b-badge variant="danger  mt-1 mr-2">必填</b-badge>性別
                        </template>
                            <InputV1 
                            :type="form2.data['RF_SEX'].type" 
                            :data_in="form2.data['RF_SEX']">
                            </InputV1>
                    </b-form-group>
                    <b-form-group  v-show="form2.data['RF_NID'].show">
                        <template #label>
                            <b-badge variant="danger  mt-1 mr-2">必填</b-badge>身份證號
                        </template>
                            <InputV1 
                            :type="form2.data['RF_NID'].type" 
                            :data_in="form2.data['RF_NID']">
                            </InputV1>
                    </b-form-group>
                    <b-form-group  v-show="form2.data['RF_PHONE'].show">
                        <template #label>
                            <b-badge variant="danger  mt-1 mr-2">必填</b-badge>行動電話
                        </template>
                            <InputV1 
                            :type="form2.data['RF_PHONE'].type" 
                            :data_in="form2.data['RF_PHONE']">
                            </InputV1>
                    </b-form-group>
                    <b-form-group  v-show="form2.data['RF_TEL'].show">
                        <template #label>
                            <b-badge variant="danger  mt-1 mr-2">必填</b-badge>聯絡電話
                        </template>
                            <InputV1 
                            :type="form2.data['RF_TEL'].type" 
                            :data_in="form2.data['RF_TEL']">
                            </InputV1>
                    </b-form-group>
                    <b-form-group  v-show="form2.data['RF_MAIL'].show">
                        <template #label>
                            <b-badge variant="danger  mt-1 mr-2">必填</b-badge>電子信箱
                        </template>
                            <InputV1 
                            :type="form2.data['RF_MAIL'].type" 
                            :data_in="form2.data['RF_MAIL']">
                            </InputV1>
                    </b-form-group>
                    <b-form-group  v-show="form2.data['RF_BDATE'].show">
                        <template #label>
                            <b-badge variant="danger  mt-1 mr-2">必填</b-badge>出生日期
                        </template>
                            <InputV1 
                            :type="form2.data['RF_BDATE'].type" 
                            :data_in="form2.data['RF_BDATE']">
                            </InputV1>
                    </b-form-group>
            
                    <b-form-group  v-show="form2.data['RF_ADDRESS'].show">
                        <template #label>
                            <b-badge variant="danger  mt-1 mr-2">必填</b-badge>地址
                        </template>
                        <b-row>
                            <b-col cols="3">
                                 <InputV1 
                                 :type="form2.data['RF_CITYID'].type" 
                                 :data_in="form2.data['RF_CITYID']">
                                 </InputV1>
                            </b-col>
                            <b-col cols="3">
                                 <InputV1 
                                 :type="form2.data['RF_TOWNID'].type" 
                                 :data_in="form2.data['RF_TOWNID']">
                                 </InputV1>
                            </b-col>
                            <b-col cols="6">
                                 <InputV1 
                                 :type="form2.data['RF_ADDRESS'].type" 
                                 :data_in="form2.data['RF_ADDRESS']">
                                 </InputV1>
                            </b-col>
                        </b-row>
                       
                    </b-form-group>
                     
                    


                    <template #modal-footer>
                        <b-button variant="primary"  @click="form2Obj['Save']()">確定</b-button>
                        <b-button  @click="modal['form2'].hide()">取消</b-button>
                    </template>
                    
                </b-modal>

        <!--***************************************************************************************-->
        <b-container style="top:-6px; z-index: 1000; position: relative;" v-show="view1.type==0">
            <div class="Course p-3 p-md-5 bg-white ">

                <!--***************************************************************************************-->
                <section>
                    <h4 class="mb-3">課程資訊</h4>
                     <!--***************************************************************************************-->
                    <b-table-simple  bordered caption-top responsive  v-if="Table2.show">
                        <b-thead head-variant="light">
                            <b-tr>
                                <b-th>項目（個人報名用這表格）</b-th>
                                <b-th class="text-center">金額</b-th>
                            </b-tr>
                        </b-thead>
                        <b-tbody>
                            <b-tr>
                                <b-td>{{IsNull2(Table2.course)}}</b-td>
                                <b-td class="text-right" v-if="Table2.off.show">
                                    （早鳥優惠）NT${{IsNull2(Table2.off.val)}}<br />
                                    <span style="text-decoration:line-through;">
                                        NT${{IsNull2(Table2.price)}}
                                    </span> 
                                </b-td>
                                <b-td class="text-right" v-else>
                                    NT${{IsNull2(Table2.price)}}
                                </b-td>
                            </b-tr>


                            <b-tr v-if="Table2.off2.show && Table2.off2.type==0">
                                <b-td>身份優惠-折扣（{{IsNull2(Table2.off2.identity)}}）</b-td>
                                <b-td class="text-right">x {{IsNull2(Table2.off2.val)}}</b-td>
                            </b-tr>

                            <b-tr v-if="Table2.off2.show && Table2.off2.type==1">
                                <b-td>身份優惠-減免（{{IsNull2(Table2.off2.identity)}}）</b-td>
                                <b-td class="text-right">- NT${{IsNull2(Table2.off2.val)}}</b-td>
                            </b-tr>

                            

                        </b-tbody>
                        <b-tfoot>
                            <b-tr>
                                <b-td colspan="3" variant="light" class="text-right">
                                    結帳總金額 <b>NT${{Math.floor(Number(IsNull2(Table2.sum)))}}</b>
                                </b-td>
                            </b-tr>
                        </b-tfoot>
                    </b-table-simple>
                     <!--***************************************************************************************-->

                    <b-table-simple  bordered caption-top responsive v-if="Table3.show">
                        <b-thead head-variant="light">
                            <b-tr>
                                <b-th>項目（團體報名用這表格）</b-th>
                                <!-- <b-th>{{Table1.col[0].text}}</b-th> -->
                                <b-th class="text-center">數量</b-th>
                                <b-th class="text-center">金額</b-th>
                                <b-th class="text-center">小計</b-th>
                            </b-tr>
                        </b-thead>
                        <b-tbody>
                            <b-tr>
                                <b-td>{{IsNull(Table3.course)}}</b-td>
                                <b-td class="text-center">
                                    {{IsNull2(Table3.num)}}
                                </b-td>
                                <b-td class="text-right" v-if="Table3.off.show">
                                    （團體優惠）NT${{IsNull2(Table3.off.val)}}<br />
                                    <span style="text-decoration:line-through;">
                                        NT${{IsNull2(Table3.price)}}
                                    </span> 
                                </b-td>
                                <b-td class="text-right" v-else>
                                    NT${{IsNull2(Table3.price)}}
                                </b-td>
                                <b-td class="text-right">
                                    NT${{IsNull2(Table3.sum)}}
                                </b-td>
                            </b-tr>
                        </b-tbody>

                         <b-tfoot>
                            <b-tr>
                                <b-td colspan="4" variant="light" class="text-right">
                                    結帳總金額 <b>NT${{Math.floor(Number(IsNull2(Table3.sum)))}}</b>
                                </b-td>
                            </b-tr>
                        </b-tfoot>
                    </b-table-simple>
                </section>
                 <!--***************************************************************************************-->
                <section v-if="Table4.show &&
                            Object.keys(this.IsNull(Table4['RF_IDID'].options,{})).length!=0">
                <h4 class="mb-3" >身分優惠</h4>
                    <b-row>
                        <b-col>
                             <InputV1 
                             :type="Table4['RF_IDID'].type" 
                             :data_in="Table4['RF_IDID']">
                             </InputV1>
                        </b-col>
                    </b-row>
                    <b-alert show variant="secondary">
                        身份優惠說明（後台身份優惠說明)
                        <hr>

                        <template v-if="Table4.type=='2'">
                            <p>
                                {{Table4.text}}:
                                <span v-if="Table4.if1" class="text-success" >成功</span> 
                                <span v-else class="text-danger" >尚未驗證</span> 
                            </p>
                            <b-form inline >
                                <InputV1
                                :type="Table4['NID_val'].type"
                                :data_in="Table4['NID_val']"
                                ></InputV1>

                                <b-button size="sm" 
                                class="mx-2" 
                                @click="Table4Obj['Save']()" >驗證</b-button>
                            </b-form>
                        </template>

                        <template v-else-if="Table4.type=='0' || Table4.type=='1'">
                            <p>
                                {{Table4.text}}:
                                <span v-if="Table4.if1" class="text-success" >成功</span> 
                                <span v-else class="text-danger" >尚未驗證</span> 
                            </p>
                        </template>

                    </b-alert>
                </section>



                <!--***************************************************************************************-->
                <section v-show="form1.show">
                <h4 class="mb-3">{{form1.title}}
                    <span style="font-size: 1rem;">
                        (<b-link target="_blank" href="https://www.fcu.edu.tw/privacy/">個資保護政策</b-link>)
                    </span>
                </h4>
                <b-row cols="1" cols-md="2">
                    <b-col v-show="form1.data['RF_NAME'].show">
                        <b-form-group >
                            <template #label>
                                <b-badge variant="danger  mt-1 mr-2">必填</b-badge>學員姓名
                            </template>
                            <InputV1 
                            :type="form1.data['RF_NAME'].type" 
                            :data_in="form1.data['RF_NAME']">
                            </InputV1>
                        </b-form-group>
                    </b-col>

                    <b-col v-show="form1.data['RF_SEX'].show">
                        <b-form-group >
                            <template #label>
                                <b-badge variant="danger  mt-1 mr-2">必填</b-badge>性別
                            </template>
                            <InputV1 
                            :type="form1.data['RF_SEX'].type" 
                            :data_in="form1.data['RF_SEX']">
                            </InputV1>
                        </b-form-group>
                    </b-col>

                    <b-col v-show="form1.data['RF_NID'].show">
                        <b-form-group >
                            <template #label>
                                <b-badge variant="danger  mt-1 mr-2">必填</b-badge>身份證號
                            </template>
                            <InputV1 
                            :type="form1.data['RF_NID'].type" 
                            :data_in="form1.data['RF_NID']">
                            </InputV1>
                        </b-form-group>
                    </b-col>

                    <b-col v-show="form1.data['RF_PHONE'].show">
                        <b-form-group >
                            <template #label>
                                <b-badge variant="danger  mt-1 mr-2">必填</b-badge>行動電話
                            </template>
                            <InputV1 
                            :type="form1.data['RF_PHONE'].type" 
                            :data_in="form1.data['RF_PHONE']">
                            </InputV1>
                        </b-form-group>
                    </b-col>

                    <b-col v-show="form1.data['RF_TEL'].show">
                        <b-form-group >
                            <template #label>
                                <b-badge variant="danger  mt-1 mr-2">必填</b-badge>聯絡電話
                            </template>
                            <InputV1 
                            :type="form1.data['RF_TEL'].type" 
                            :data_in="form1.data['RF_TEL']">
                            </InputV1>
                        </b-form-group>
                    </b-col>

                    <b-col v-show="form1.data['RF_MAIL'].show">
                        <b-form-group >
                            <template #label>
                                <b-badge variant="danger  mt-1 mr-2">必填</b-badge>電子信箱
                            </template>
                            <InputV1 
                            :type="form1.data['RF_MAIL'].type" 
                            :data_in="form1.data['RF_MAIL']">
                            </InputV1>
                        </b-form-group>
                    </b-col>

                    <b-col v-show="form1.data['RF_BDATE'].show">
                        <b-form-group >
                            <template #label>
                                <b-badge variant="danger  mt-1 mr-2">必填</b-badge>出生日期
                            </template>
                            <InputV1 
                            :type="form1.data['RF_BDATE'].type" 
                            :data_in="form1.data['RF_BDATE']">
                            </InputV1>
                        </b-form-group>
                    </b-col>

                    <b-col v-show="form1.data['RF_ADDRESS'].show">
                        <b-form-group >
                            <template #label>
                                <b-badge variant="danger  mt-1 mr-2">必填</b-badge>地址
                            </template>
                            <b-row>
                                <b-col cols="3">
                                    <InputV1 
                                    :type="form1.data['RF_CITYID'].type" 
                                    :data_in="form1.data['RF_CITYID']">
                                    </InputV1>
                                </b-col>
                                <b-col cols="3">
                                    <InputV1 
                                    :type="form1.data['RF_TOWNID'].type" 
                                    :data_in="form1.data['RF_TOWNID']">
                                    </InputV1>
                                </b-col>
                                <b-col cols="6">
                                    <InputV1 
                                    :type="form1.data['RF_ADDRESS'].type" 
                                    :data_in="form1.data['RF_ADDRESS']">
                                    </InputV1>
                                </b-col>
                            </b-row>
                        </b-form-group>
                    </b-col>
                </b-row>
                </section>  
                
                <!--***************************************************************************************-->
                <section v-show="TablePage.show">
                    <h4 class="mb-3">{{TablePage.title}}
                        <span style="font-size: 1rem;">
                            (<b-link target="_blank" href="https://www.fcu.edu.tw/privacy/">個資保護政策</b-link>)
                        </span>
                    </h4>
                    <p>
                    <b-link @click="view1Obj['Close']()">重新編輯學員名單</b-link>
                    </p>
                    <BTableV1 :TablePage="TablePage" :cell="['col','RF_SEX','RF_ADDRESS','tool']">
                        <template #col="{row}">{{row.index+1}}</template>
                        <template #RF_SEX="{row}">
                            {{row.item.RF_SEX==1?'女':'男'}}
                        </template>

                        <template #RF_ADDRESS="{row}">
                            {{[IsNullKeys(cityName,[row.item.RF_CITYID,'CT_NAME']),
                               IsNull(townName.filter(x=>x.TW_TOWN_ID==row.item.RF_TOWNID),[{TW_TOWN_NAME:''}])[0]['TW_TOWN_NAME'],
                               IsNull(row.item.RF_ADDRESS),
                              ].join('')
                            }}
                        </template>
                        <template #tool="{row}">
                            <b-link @click="form2Obj['Show'](row.item,row.index)" class="mr-3"> <b-icon-pencil></b-icon-pencil> 編輯</b-link>
                            <b-link v-if="row.index>1" @click="form4Obj['Del'](row.index)"><b-icon-trash></b-icon-trash>刪除</b-link>
                        </template>
                    </BTableV1>
                </section>

                <!--***************************************************************************************-->
                
                
                <section v-show="form3.show" >

                <h4 class="mb-3">{{form3.title}}</h4>
                
                <div class="payment">
                <b-row  class="item" no-gutters v-show="form3.data['PAY_TYPE'].options[0].show">
                    <b-col cols="2">
                        <b-form-radio 
                        name="test-radios" 
                        id="payment-1"
                        v-model="form3.data['PAY_TYPE'].val"
                        :disabled="form3.data['PAY_TYPE'].only"
                        :value="form3.data['PAY_TYPE'].options[0].value"
                        >{{form3.data['PAY_TYPE'].options[0].text}}
                        </b-form-radio> 
                    </b-col>
                    <b-col>
                        <label for="payment-1">{{form3.data['PAY_TYPE'].options[0].text2}}</label>
                    </b-col>
                </b-row>
                <b-row class="item" no-gutters v-show="form3.data['PAY_TYPE'].options[1].show">
                    <b-col cols="2">
                        <b-form-radio 
                        name="test-radios" 
                        id="payment-2"
                        v-model="form3.data['PAY_TYPE'].val"
                        :disabled="form3.data['PAY_TYPE'].only"
                        :value="form3.data['PAY_TYPE'].options[1].value"
                        >{{form3.data['PAY_TYPE'].options[1].text}}
                        </b-form-radio> 
                    </b-col>
                    <b-col>
                        <label for="payment-2">{{form3.data['PAY_TYPE'].options[1].text2}}</label>
                    </b-col>
                </b-row>
                </div>

                </section>

                <!--***************************************************************************************-->
                
                
                <section v-show="form3.show">
                <h4 class="mb-3">{{form3.title2}}</h4>
                <b-row cols="1" cols-md="2">
                    <b-col>
                        <b-form-group >
                            <template #label>收據抬頭</template>
                             <InputV1 
                             :type="form3.data['PAY_RECEIPT_TITLE'].type" 
                             :data_in="form3.data['PAY_RECEIPT_TITLE']">
                             </InputV1>
                        </b-form-group>
                    </b-col>
                    <b-col>
                        <b-form-group >
                            <template #label>統一編號</template>
                             <InputV1 
                             :type="form3.data['PAY_UNICODE_NUMBER'].type" 
                             :data_in="form3.data['PAY_UNICODE_NUMBER']">
                             </InputV1>
                        </b-form-group>
                    </b-col>
                </b-row>
                </section>

                
                <b-alert show variant="primary" v-show="form3.show">
                    <h4 class="alert-heading">{{form3.title3}}</h4>
                    <div v-html="form3.text"></div>
           
                    <hr>
                    <p class="mb-0">
                        <InputV1 
                        :type="form3.data['if2'].type" 
                        :data_in="form3.data['if2']">
                        </InputV1>
                    </p>
                </b-alert>
                <!--***************************************************************************************-->
                <div class="text-center mb-5">
                    <b-button variant="primary" @click="view1Obj['Save']()" v-show="form3.data['PAY_TYPE'].val!='0'">完成報名</b-button>
                    <b-button variant="primary" @click="view1Obj['Save']()" v-show="form3.data['PAY_TYPE'].val!='1'">前往付款</b-button>
                </div>
            </div>

        </b-container>
        <!--***************************************************************************************-->
        <b-container style="top:-6px; z-index: 1000; position: relative;" v-show="view1.type==1">
            <div class="Course p-1 p-md-5 bg-white ">
          
                <h4>課程名稱</h4>
                <p> <strong>{{form4.course}}</strong> </p>
                <h4 v-show="form4.off.show">團體報名優惠</h4>
                <p v-show="form4.off.show">報名人數滿 <strong>{{form4.off.num}}</strong>人，課程費用為 <strong>NT${{form4.off.price}}</strong> （人） </p>
                <!--
                <h4>報名人數</h4>
                <b-form-group id="input-group-1" label="">
                    <InputV1 :type="form4.data['num'].type" :data_in="form4.data['num']"></InputV1>
                </b-form-group>
                -->

                <h4 class="mb-3">{{form4.title2}}
                    <span style="font-size: 1rem;">
                        (<b-link target="_blank" href="https://www.fcu.edu.tw/privacy/">個資保護政策</b-link>)
                    </span>
                </h4>
                <b-button class="mb-3" @click="form4Obj['Add']()">新增</b-button>
                <template v-for="(i2,i1) in form4.datas">
                    <div class=" Multiplayer" :key="i1" >
                        <b-row>
                            <b-col cols="auto">
                                <div class="h-100 number">
                                    {{i1+1}}
                                </div>    
                            </b-col>
                            <b-col class="pt-3">

                                 <b-row cols="2">
                                     <b-col v-show="i2['RF_NAME'].show">
                                        <b-form-group >
                                            <template #label>
                                                <b-badge variant="danger  mt-1 mr-2">必填</b-badge>學員姓名
                                            </template>
                                            <InputV1 
                                            :type="i2['RF_NAME'].type" 
                                            :data_in="i2['RF_NAME']">
                                            </InputV1>
                                        </b-form-group>
                                    </b-col>
                                    <b-col v-show="i2['RF_SEX'].show">
                                        <b-form-group label="性別">
                                            <template #label>
                                                <b-badge variant="danger  mt-1 mr-2">必填</b-badge>學員姓名
                                            </template>
                                            <InputV1 
                                            :type="i2['RF_SEX'].type" 
                                            :data_in="i2['RF_SEX']">
                                            </InputV1>
                                        </b-form-group>
                                    </b-col>

                                    <b-col v-show="i2['RF_NID'].show">
                                        <b-form-group >
                                            <template #label>
                                                <b-badge variant="danger  mt-1 mr-2">必填</b-badge>身份證號
                                            </template>
                                            <InputV1 
                                            :type="i2['RF_NID'].type" 
                                            :data_in="i2['RF_NID']">
                                            </InputV1>
                                        </b-form-group>
                                    </b-col>

                                    <b-col v-show="i2['RF_PHONE'].show">
                                        <b-form-group >
                                            <template #label>
                                                <b-badge variant="danger  mt-1 mr-2">必填</b-badge>行動電話
                                            </template>
                                            <InputV1 
                                            :type="i2['RF_PHONE'].type" 
                                            :data_in="i2['RF_PHONE']">
                                            </InputV1>
                                        </b-form-group>
                                    </b-col>

                                     <b-col v-show="i2['RF_TEL'].show">
                                        <b-form-group >
                                            <template #label>
                                                <b-badge variant="danger  mt-1 mr-2">必填</b-badge>聯絡電話
                                            </template>
                                            <InputV1 
                                            :type="i2['RF_TEL'].type" 
                                            :data_in="i2['RF_TEL']">
                                            </InputV1>
                                        </b-form-group>
                                    </b-col>

                                    <b-col v-show="i2['RF_MAIL'].show">
                                        <b-form-group >
                                            <template #label>
                                                <b-badge variant="danger  mt-1 mr-2">必填</b-badge>電子信箱
                                            </template>
                                            <InputV1 
                                            :type="i2['RF_MAIL'].type" 
                                            :data_in="i2['RF_MAIL']">
                                            </InputV1>
                                        </b-form-group>
                                    </b-col>

                                    <b-col v-show="i2['RF_BDATE'].show">
                                        <b-form-group >
                                            <template #label>
                                                <b-badge variant="danger  mt-1 mr-2">必填</b-badge>出生日期
                                            </template>
                                            <InputV1 
                                            :type="i2['RF_BDATE'].type" 
                                            :data_in="i2['RF_BDATE']">
                                            </InputV1>
                                        </b-form-group>
                                    </b-col>

                                    <b-col v-show="i2['RF_ADDRESS'].show">
                                        <b-form-group >
                                            <template #label>
                                                <b-badge variant="danger  mt-1 mr-2">必填</b-badge>地址
                                            </template>
                                            <b-row >
                                                <b-col cols="3">
                                                    <InputV1 
                                                    :type="i2['RF_CITYID'].type" 
                                                    :data_in="i2['RF_CITYID']">
                                                    </InputV1>
                                                </b-col>
                                                <b-col cols="3">
                                                    <InputV1 
                                                    :type="i2['RF_TOWNID'].type" 
                                                    :data_in="i2['RF_TOWNID']">
                                                    </InputV1>
                                                </b-col>
                                                <b-col cols="6">
                                                    <InputV1 
                                                    :type="i2['RF_ADDRESS'].type" 
                                                    :data_in="i2['RF_ADDRESS']">
                                                    </InputV1>
                                                </b-col>
                                            </b-row>
                                            
                                            
                                            
                                        </b-form-group>
                                    </b-col>

                                 </b-row>

                            </b-col>
                            <b-col cols="auto" >
                                <div class="del" >
                                <b-button v-if="i1>1" variant="danger" size="sm" @click="form4Obj['Del'](i1)">移除</b-button>
                                <b-button v-else variant="danger" size="sm" style="visibility: hidden;">移除</b-button>
                                </div>
                            </b-col>
                        </b-row>
                    </div>

                </template>

               
                <div class="text-center mb-5">
                    <!--<b-button to="courseSignup">下一步</b-button>-->
                    <b-button @click="view1Obj['Show']()">下一步</b-button>
                </div>
            </div>

        </b-container>



        <!--***************************************************************************************-->
    </div>
</template>
<script>
import InputV1 from '../components/InputV1.vue'
import BTableV1 from '../components/BTableV1.vue'
    export default {
        name:'',
        components:{
            InputV1,
            BTableV1
        },
        data() {
            return {
                data_in:{},
                courseData:{},
                AccountData:{},
                identityData:{},
                cityName:{},
                townName:[],
                TempObj:{
                    data:{},
                    fun:{},
                },
                modal_set:[
                    {name:'form2',id:'modal-m1'},
                ],
                typeName:{
                    RF_NAME:'學員姓名',
                    RF_SEX:'性別',
                    RF_NID:'身份證號',
                    RF_PHONE:'行動電話',
                    RF_TEL:'聯絡市話',
                    RF_MAIL:'電子信箱',
                    RF_BDATE:'出生日期',
                    RF_ADDRESS:'通訊地址',
                },
                typeName2:{
                    '0':'無驗證',
                    '1':'臨櫃驗證',
                    '2':'NID驗證'
                },
                badge:{
                    title:'',
                    data:[
                        /*
                        {style1:'primary',text:'PHP',class:'mr-1'},
                        {style1:'secondary',text:'Website',class:'mr-1'},
                        {style1:'success',text:'Laravel',calss:''},
                        */
                    ]
                },
                head:{
                    text:'無人機實務飛行特訓班',
                    text2:'',
                    text3:'梯次'
                },
                
                Table1:{
                    title:'課程資訊',
                    
                    col:[{key:'s1',text:'上課日期'},
                         {key:'s2',text:'數量'},
                         {key:'s3',text:'金額'},
                        ],
                    data:[
                        {
                            //s1:'上課日期 2021-03-24週三 10:20~12:20',
                            s1:'',
                            s2:'1',
                            s3:'2,000'}
                    ],
                    sumtext:'結帳總金額',
                    sum:'2,000'
                },

                Table2:{
                    show:false,
                    title:'個人報名',
                    course:'AI大數據',
                    price:1,
                    off:{
                        text:'早鳥優惠',
                        show:false,
                        val:1
                    },
                    off2:{
                        text:'身分優惠',
                        show:false,
                        type:1,
                        identity:'身分名稱',
                        val:1,
                    },
                    sum:0
                },
                Table2Obj:{},

                Table3:{
                    show:false,
                    title:'團體報名',
                    course:'AI大數據',
                    num:1,
                    price:1,
                    off:{
                        text:'團體優惠',
                        show:true,
                        val:1
                    },
                    sum:1,
                },
                Table3Obj:{},

                Table4:{
                    show:false,text:'',type:'',if1:false,
                    title:'身分優惠',
                    data:{},
                    RF_IDID:{type:'select2',val:'',text:'請選擇身份優惠',show:'',only:false,
                    options:{},
                    msg:'',
                    change:()=>{},
                    },
                    nid:'',
                    NID_val:{type:'text',val:'',text:'請輸入NID',only:false,msg:'',show:false,},
                },
                Table4Obj:{},

                form1:{
                    title:'學員資料',show:true,
                    data:{
                        RF_NAME:{type:'text',val:'',text:'請輸入學員姓名',only:false,msg:'',show:false},
                        RF_SEX:{type:'b-radio-group',val:'',text:'請輸入性別',only:false,
                                options:[{text:'男',value:0},{text:'女',value:1}],
                                msg:'',show:false},
                        RF_NID:{type:'text',val:'',text:'請輸入身份證號',only:false,msg:'',show:false},
                        RF_PHONE:{type:'text',val:'',text:'請輸入行動電話',only:false,msg:'',show:false},
                        RF_TEL:{type:'text',val:'',text:'請輸入聯絡電話',only:false,msg:'',show:false},
                        RF_MAIL:{type:'text',val:'',text:'請輸入電子信箱',only:false,msg:'',show:false},
                        RF_BDATE:{type:'date',val:'',only:false,msg:'',show:false},
                        RF_ADDRESS:{type:'text',val:'',text:'請輸入地址',only:false,msg:'',show:false},
                        RF_IDID:{type:'select2',
                            val:'',text:'請選擇身份優惠',only:false,show:false,
                            options:{},
                            msg:'',
                        },
                        RF_CITYID:{type:'select2',val:'',text:'縣市',only:false,
                        options:{},
                        msg:'',show:true},
                        RF_TOWNID:{type:'select2',val:'',text:'鄉鎮',only:false,
                        options:{},
                        msg:'',show:true},
                    }
                },
                form1Obj:{},
                TablePage:{
                    title:'學員名單',class:'',show:false,
                    BT_style:{'hover':true,'outlined':true,'striped':true,'small':true},
                    PG_style:{'pills':true,'align':'center','size':'sm'},
                    itemsAll:[],
                    items:[],
                    id:"Table1",currentPage:'',rows:'',page:'',
                    sortBy:'',sortDesc:false,sortDirection:"asc",
                    filter:null,filterOn:[],
                    fields: [
                        {key: 'col', label:'序號', tdClass:'text-center'},
                        {key: 'RF_NAME', label:'姓名',thClass: 'd-none', tdClass: 'd-none',},
                        {key: 'RF_SEX', label:'性別',thClass: 'd-none', tdClass: 'd-none'},
                        {key: 'RF_NID', label:'身分證號',thClass: 'd-none', tdClass: 'd-none'},
                        {key: 'RF_PHONE', label:'行動電話',thClass: 'd-none', tdClass: 'd-none'},
                        {key: 'RF_TEL', label:'聯絡電話',thClass: 'd-none', tdClass: 'd-none'},
                        {key: 'RF_MAIL', label:'電子信箱',thClass: 'd-none', tdClass: 'd-none'},
                        {key: 'RF_BDATE', label:'出生年月日',thClass: 'd-none', tdClass: 'd-none'},
                        {key: 'RF_ADDRESS', label:'地址',thClass: 'd-none', tdClass: 'd-none'},
                        {key: 'tool', label:'操作'},
                    ],
                    TNum:[   
                            {text:'列出近10筆記錄',val:10},
                            {text:'列出近20筆記錄',val:20},
                            {text:'列出近50筆記錄',val:50},
                            {text:'列出近100筆記錄',val:100},],
                    TIndex(th1,index){
                    return ((th1.currentPage-1)*th1.page)+index
                    },
                    clear(th1){
                    th1.itemsAll=[];
                    th1.items=[];
                    th1.rows=0;
                    th1.currentPage=1
                    },
                    set(th1,data){
                    th1.itemsAll=JSON.parse(JSON.stringify(data));
                    th1.items=JSON.parse(JSON.stringify(data));
                    th1.rows=data.length;
                    
                    },
                    set2(th1,data){
                    th1.items=JSON.parse(JSON.stringify(data));
                    th1.rows=data.length;
                    th1.currentPage=1;
                    },
                    set3(th1,data){
                    th1.items=JSON.parse(JSON.stringify(data));
                    th1.rows=data.length;
                    th1.currentPage=1;
                    }
                },
                TablePageObj:{},
                form2:{
                    title:'學員資料編輯',id:'modal-m1',index:'',
                    data:{
                        RF_NAME:{type:'text',val:'',text:'請輸入學員姓名',only:false,msg:'',show:false},
                        RF_SEX:{type:'b-radio-group',val:'',text:'請輸入性別',only:false,
                                options:[{text:'男',value:0},{text:'女',value:1}],
                                msg:'',show:false},
                        RF_NID:{type:'text',val:'',text:'請輸入身份證號',only:false,msg:'',show:false},
                        RF_PHONE:{type:'text',val:'',text:'請輸入行動電話',only:false,msg:'',show:false},
                        RF_TEL:{type:'text',val:'',text:'請輸入聯絡電話',only:false,msg:'',show:false},
                        RF_MAIL:{type:'text',val:'',text:'請輸入電子信箱',only:false,msg:'',show:false},
                        RF_BDATE:{type:'date',val:'',only:false,msg:'',show:false},
                        RF_ADDRESS:{type:'text',val:'',text:'請輸入地址',only:false,msg:'',show:false},
                        RF_CITYID:{type:'select2',val:'',text:'縣市',only:false,
                          options:{},
                          msg:'',show:true},
                        RF_TOWNID:{type:'select2',val:'',text:'鄉鎮',only:false,
                          options:{},
                          msg:'',show:true},
                    }
                },
                form2Obj:{},

                form3:{
                    title:'付款方式',show:true,
                    title2:'收據資訊',
                    title3:'退費規定：',
                    text:'<p>依教育部「專科以上學校推廣教育實施辦法」規定，學員完成報名繳費後，因故申請退費，應依下列方式辦理：</p><ol><li>學員自報名繳費後至開班上課日前申請退費者，退還已繳學分費、雜費等各項費用之九成。自開班上課之日起算未逾全期三分之一申請退費者，退還已繳學分費、雜費等各項費用之半數。開班上課時間已逾全期三分之一始申請退費者，不予退還。</li><li>已繳代辦費應全額退還。但已購置成品者，發給成品。 </li><li>學校因故未能開班上課，應全額退還已繳費用。 </li></ol>',
                    data:{
                        PAY_TYPE:{
                        val:'0',
                        only:false,stacked:true,
                        options:[
                            {value:'0',text:'逢甲PAY',show:true,
                             text2:'提供 ATM轉帳、LINE Pay、街口支付、台灣Pay、國泰KOKO及支付寶等方式付款',},
                            {value:'1',text:'臨櫃付款',show:true,
                             text2:'請於一周內至逢甲大學推廣教育處辦公室完成繳費程序'},
                        ],
                        },
                        PAY_RECEIPT_TITLE:{type:'text',val:'',text:'請輸入收據抬頭',only:false,msg:''},
                        PAY_UNICODE_NUMBER:{type:'text',val:'',text:'請輸入統一編號',only:false,msg:''},
                        if2:{type:'b-checkbox-group',
                        val:[],only:false,stacked:false,
                                 options:[{value:'val1',text:'本人已詳細閱讀並同意本網站之相關條款'},
                                          ],
                                 msg:''
                        }
                    }
                },
                form3Obj:{},
                //view2
                form4:{
                    title:'報名人數說明',
                    title2:'學員名單',
                    course:'AI大數據',
                    off:{
                        show:false,
                        num:5,
                        price:3000,
                    },
                    datas:[
                        
                    ]
                },
                form4Obj:{},
                view1:{type:0},
                view1Obj:{},
            }
        },
        mounted(){
            this.modal_init(this.modal_set)
            let navBgSwitch=this._storeData('navBgSwitch')
            if(this.IsNull(navBgSwitch)!=''){
                navBgSwitch(false)
            }
            
            
            //this.form4.data['num'].change=this.form4numChange
            
            this.Table2Obj=this.Table2Obj_init()
            this.Table3Obj=this.Table3Obj_init()
            this.Table4Obj=this.Table4Obj_init()
            this.form1Obj=this.form1Obj_init()
            this.TablePageObj=this.TablePageObj_init()
            this.form2Obj=this.form2Obj_init()
            this.form3Obj=this.form3Obj_init()
            this.form4Obj=this.form4Obj_init()
            this.view1Obj=this.view1Obj_init()
            this.formCheckSet()


            this.sessionF1()
            
        },
        methods:{
            sessionF1(url){
                if(this.IsNull(url)==''){
                    let data1=this._go2({key:'CP_ID'},'query')
                    let data2=this._go2({key:'RF_IS_GROUP'},'query')

                    if(this.IsNull(data1)=='' || this.IsNull(data2+'')==''){
                        this._go('/')
                    }
                    if(this.IsNull(this._sessionData('TokenID'))==''){
                        alert('尚未登入')
                        this._go('/')
                        return
                    }
                    
                    this.form1Obj['Clear']()
                    this.form2Obj['Clear']()
                    this.form3Obj['Clear']()
                    this.form4.datas=[]
                    this.TablePage.clear(this.TablePage)

                    this.data_in={CP_ID:data1,RF_IS_GROUP:data2}
                    this.FunctionToken(this.FunctionGetCourseFeriodSingleByIndex,{
                        CP_ID:this.data_in.CP_ID
                    })
                    this.FunctionToken(this.FunctionMyACCOUNT,{})
                    this.FunctionToken(this.FunctionSelectCity,{})
                    this.FunctionToken(this.FunctionSelectTown,{})

                    if(this.data_in.RF_IS_GROUP==1){
                        
                        this.view1.type=1
                        this.form1.show=false
                        this.TablePage.show=true
                        this.form3.data['PAY_TYPE'].val=1
                        this.form3.data['PAY_TYPE'].only=true
                        this.form3.data['PAY_TYPE'].options[0].show=false
                        this.Table3.show=true
                    }else{
                        
                        this.form1.data['RF_IDID'].text='無'
                        this.view1.type=0
                        this.form1.show=true
                        this.TablePage.show=false
                        this.Table2.show=true
                        this.Table4.show=true
                    }
                }
            },
            formCheckSet(){
                for(let i in this.form1.data){
                    let item=this.form1.data[i]
                    if(i=='RF_CITYID'){
                        item.change=(data)=>{
                            this.form1Obj['Check'](i)
                            this.form1.data['RF_TOWNID'].val=''
                            if(data.val==''){
                                this.form1.data['RF_TOWNID'].options={}
                            }else{
                                let data1=this.townName.filter(x=>x.TW_CITY_ID==data.val)
                                let options={}
                                for(let i in data1){
                                    let item=data1[i]
                                    options[item.TW_TOWN_ID]={
                                        text:this.IsNull(item.TW_TOWN_NAME),
                                        val:item.TW_TOWN_ID
                                    }
                                }
                                this.form1.data['RF_TOWNID'].options=options
                            }
                            this.form1Obj['Check']('RF_TOWNID')
                        }
                    }else if(item.type=='text'){
                        this.$watch(
                            ()=>{return item.val},
                            ()=>{
                                this.form1Obj['Check'](i)
                            }
                        )

                    }else{
                        item.change=()=>{
                            this.form1Obj['Check'](i)
                        }
                    }
                }


                for(let i in this.form2.data){
                    let item=this.form2.data[i]
                    if(i=='RF_CITYID'){
                        item.change=(data)=>{
                            this.form2Obj['Check'](i)
                            this.form2.data['RF_TOWNID'].val=''
                            if(data.val==''){
                                this.form2.data['RF_TOWNID'].options={}
                            }else{
                                let data1=this.townName.filter(x=>x.TW_CITY_ID==data.val)
                                let options={}
                                for(let i in data1){
                                    let item=data1[i]
                                    options[item.TW_TOWN_ID]={
                                        text:this.IsNull(item.TW_TOWN_NAME),
                                        val:item.TW_TOWN_ID
                                    }
                                }
                                this.form2.data['RF_TOWNID'].options=options
                            }
                            this.form2Obj['Check']('RF_TOWNID')
                        }
                    }else if(item.type=='text'){
                        this.$watch(
                            ()=>{return item.val},
                            ()=>{
                                this.form2Obj['Check'](i)
                            }
                        )
                    }else{
                        item.change=()=>{
                            this.form2Obj['Check'](i)
                        }
                    }
                }

                this.Table4['RF_IDID'].change=(data)=>{
                    data.msg=''
                    this.Table4.text=''
                    this.Table4.type=''
                    this.Table4.if1=false
                    this.Table4['NID_val'].val=''
                    this.Table4['NID_val'].show=false

                    if(this.IsNull(data.val)==''){
                        this.Table4.text=''
                        this.Table2.off2.show=false
                        this.Table2Obj['Update']()

                        if(this.data_in.RF_IS_GROUP!=1){
                            this.form3.data['PAY_TYPE'].only=false
                            this.form3.data['PAY_TYPE'].options[0].show=true
                        }

                    }else{
                        let data2=this.Table4.data[data.val]
                        this.Table4.text=[
                            this.IsNull(data2.IDCA__MEMO),
                            '(',
                            this.IsNull(data2.ID_NAME),
                            ')'
                        ].join('')
                        

                        if(this.courseData['courseStatus'].indexOf('早鳥優惠')!=-1 &&
                            this.courseData['CA_EARLY_SHARED']!=1){
                            console.log('未啟用優惠共用')
                        }else{
                            if(data2.IDCA__TYPE==1){
                                this.Table2.off2.type=1
                                this.Table2.off2.val=Number(data2.IDCA__DISCOUNT)
                            }else{
                                this.Table2.off2.type=0
                                this.Table2.off2.val=Number(data2.IDCA__DISCOUNT)*0.01
                            }

                            this.Table2.off2.identity=this.IsNull(data2.ID_NAME)
                            this.Table2.off2.show=true
                            this.Table2Obj['Update']()
                        }
                        

                        let data3=Object.values(this.identityData)
                        let item=data3.filter(x=>x.IDCA_IDID==data.val)
                        if(item.length!=0){
                            if(item[0].IDCA__ON_SPOT==1){
                                this.form3.data['PAY_TYPE'].val=1
                                this.form3.data['PAY_TYPE'].only=true
                                this.form3.data['PAY_TYPE'].options[0].show=false
                            }else{
                                if(this.data_in.RF_IS_GROUP!=1){
                                    this.form3.data['PAY_TYPE'].only=false
                                    this.form3.data['PAY_TYPE'].options[0].show=true
                                }
                            }
                        }

                        let item2=this.OnlyData['ID'].filter(x=>x.ID_ID==data.val)
                        if(item2.length!=0){
                            this.Table4['NID_val'].text=item2[0]['Identity']==3?'請輸入ID':'請輸入NID'
                            this.Table4.type='2'
                        }else if(item.length!=0 && item[0].IDCA__ON_SPOT==1){
                            this.Table4.type='1'
                        }else{
                            this.Table4.type='0'
                            this.Table4.if1=true
                        }
                        

                    }


                }

                this.$watch(
                    ()=>{ return this.Table4['NID_val'].val},
                    ()=>{
                        this.Table4['NID_val'].msg=''
                        if(this.Table4['NID_val'].val==''){
                            this.Table4['NID_val'].msg={type:false,texts:[]}
                        }
                        this.Table4.if1=false
                    }
                )
            },
            Table2Obj_init(){
                let Obj={}
                Obj['Table2']=this.Table2
                Obj['Update']=()=>{
                    this.Table2.off.show=false
                    this.Table2.sum=Number(this.Table2.price)
                    let sum=Number(this.Table2.price)
                    if(this.courseData['CA_IS_EARLY']&& 
                        this.courseData['courseStatus'].indexOf('早鳥優惠')!=-1){
                        this.Table2.off.show=true
                        sum=Number(this.Table2.off.val)
                    }
                    if(this.Table2.off2.show){
                        if(this.Table2.off2.type==1){
                            sum-=Number(this.Table2.off2.val)
                        }else{
                            sum=sum*Number(this.Table2.off2.val)
                        }
                    }
                    this.Table2.sum=sum
                }
                return Obj
            },
            Table3Obj_init(){
                let Obj={}

                Obj['Table3']=this.Table3

                Obj['Update']=()=>{
                    this.Table3.off.show=false
                    this.Table3.sum=Number(this.Table3.price)*Number(this.Table3.num)
                    if(this.IsNull(this.courseData['CA_GROUP_NUMBER'])!=''){
                        if(this.Table3.num>=Number(this.courseData['CA_GROUP_NUMBER'])){
                            this.Table3.off.show=true
                            this.Table3.sum=Number(this.Table3.off.val)*Number(this.Table3.num)
                        }
                    }
                }
                return Obj
            },
            Table4Obj_init(){
                let Obj={}

                Obj['Table4']=this.Table4

                Obj['Check']=(data)=>{
                    this.Clog(data)
                    if(data.data.IDCA__TYPE==1){
                        this.Table2.off2.type=1
                        this.Table2.off2.val=Number(data.data.IDCA__DISCOUNT)
                    }else{
                        this.Table2.off2.type=0
                        this.Table2.off2.val=Number(data.data.IDCA__DISCOUNT)*0.01
                    }
                    this.Table2.off2.identity=this.IsNull(data.data.ID_NAME)
                    this.Table2.off2.show=true

                    this.Table2Obj['Update']()
                    data.val=true
                    this.Table4.val=data.data.IDCA_IDID
                }

                Obj['Save']=()=>{
                    if(this.Table4.type!='2'){
                        return
                    }
                    
                    if(this.IsNull(this.Table4.NID_val.val)==''){
                        alert('未填寫NID')
                        return
                    }

                    this.Table4.nid=this.Table4['NID_val'].val

                    let item=this.OnlyData['ID'].filter(x=>x.ID_ID==this.Table4['RF_IDID'].val)
                    item=this.IsNullKeys(item,[0])
                    if(item['Identity']=='3'){
                        this.FunctionToken(
                            Obj['FunctionCheckFcuAuth'],{
                                TYPE:'CHECK',
                                NID:'',
                                ID:this._Base64('str',this.Table4['NID_val'].val),
                                JSval:item
                            }
                        )
                    }else{
                        this.FunctionToken(
                            Obj['FunctionCheckFcuAuth'],{
                                TYPE:'CHECK',
                                NID:this._Base64('str',this.Table4['NID_val'].val),
                                ID:'',
                                JSval:item
                            }
                        )
                    }
                }

                //====================================
                //api
                Obj['FunctionCheckFcuAuth']=(data_in)=>{
                    this.apiCheckFcuAuth(data_in)
                    .then(res=>{
                        let json=JSON.parse(res.data)
                        if(json.Status){
                            let data1=JSON.parse(json.Data)
                            if(data1['Identity']==data_in.JSval['Identity']){
                                this.Table4['RF_IDID'].msg=''
                                this.Table4.if1=true
                                alert('驗證成功')
                            }else{
                                alert('驗證失敗')
                            }

                        }else{
                            alert('驗證失敗')
                        }
                    })
                    .catch(err=>{
                        this.Elog(err)
                        alert('驗證失敗')
                    })
                }





                return Obj
            },
            form1Obj_init(){
                let Obj={}
                Obj['form1']=this.form1

                Obj['Clear']=()=>{
                    for(let i in this.form1.data){
                        let item=this.form1.data[i]
                        if(i=='RF_SEX'){
                            item.val=0
                        }else{
                            item.val=''
                        }
                        item.only=false
                        item.msg=''
                    }
                }

                Obj['Check']=(id)=>{
                    let msg=''
                    if(['RF_CITYID','RF_TOWNID'].indexOf(id)!=-1 ){
                        if(this.view1Obj['Filter']('RF_ADDRESS')==false){
                            return msg
                        }
                    }else{
                        if(this.view1Obj['Filter'](id)==false && id!=='RF_MAIL'){
                            return msg
                        }
                    }
                    
                    if(id=='RF_NAME'){
                        this.form1.data['RF_NAME'].msg=''
                        if(this.IsNull(this.form1.data['RF_NAME'].val)==''){
                            msg+='未填寫學員姓名\n'
                            this.form1.data['RF_NAME'].msg={
                                type:false,
                                texts:['未填寫學員姓名']
                            }
                        }
                    }else if(id=='RF_SEX'){
                        this.form1.data['RF_SEX'].msg=''
                        if(this.IsNull(this.form1.data['RF_SEX'].val)==''&&
                            this.form1.data['RF_SEX'].val!==0){
                            msg+='未填選性別\n'
                            this.form1.data['RF_SEX'].msg={
                                type:false,
                                texts:['未填選性別']
                            }
                        }
                    }else if(id=='RF_NID'){
                        this.form1.data['RF_NID'].msg=''
                        if(this.IsNull(this.form1.data['RF_NID'].val)==''){
                            msg+='未填寫身份證號\n'
                            this.form1.data['RF_NID'].msg={
                                type:false,
                                texts:['未填寫身份證號']
                            }
                        }else if(this.VerifyModel()['nid'](this.form1.data['RF_NID'].val)==false){
                            msg+='身分證號格式不符\n'
                            this.form1.data['RF_NID'].msg={
                                type:false,
                                texts:['身分證號格式不符']
                            }
                        }
                    }else if(id=='RF_PHONE'){
                        this.form1.data['RF_PHONE'].msg=''
                        if(this.IsNull(this.form1.data['RF_PHONE'].val)==''){
                            msg+='未填寫行動電話\n'
                            this.form1.data['RF_PHONE'].msg={
                                type:false,
                                texts:['未填寫行動電話']
                            }
                        }else if(this.VerifyModel()['phone'](this.form1.data['RF_PHONE'].val)==false){
                            msg+='行動電話格式不符\n'
                            this.form1.data['RF_PHONE'].msg={
                                type:false,
                                texts:['行動電話格式不符']
                            }
                        }
                    }else if(id=='RF_TEL'){
                        this.form1.data['RF_TEL'].msg=''
                        if(this.IsNull(this.form1.data['RF_TEL'].val)==''){
                            msg+='未填寫聯絡電話\n'
                            this.form1.data['RF_TEL'].msg={
                                type:false,
                                texts:['未填寫聯絡電話']
                            }
                        }else if(this.VerifyModel()['tel'](this.form1.data['RF_TEL'].val)==false){
                            msg+='聯絡電話格式不符\n'
                            this.form1.data['RF_TEL'].msg={
                                type:false,
                                texts:['聯絡電話格式不符']
                            }
                        }
                    }else if(id=='RF_MAIL'){
                        this.form1.data['RF_MAIL'].msg=''
                        if(this.IsNull(this.form1.data['RF_MAIL'].val)==''){
                            msg+='未填寫電子信箱\n'
                            this.form1.data['RF_MAIL'].msg={
                                type:false,
                                texts:['未填寫電子信箱']
                            }
                        }else if(this.VerifyModel()['email'](this.form1.data['RF_MAIL'].val)==false){
                            msg+='電子信箱格式不符\n'
                            this.form1.data['RF_MAIL'].msg={
                                type:false,
                                texts:['電子信箱格式不符']
                            }
                        }
                    }else if(id=='RF_BDATE'){
                        this.form1.data['RF_BDATE'].msg=''
                        if(this.IsNull(this.form1.data['RF_BDATE'].val)==''){
                            msg+='未填寫出生日期\n'
                            this.form1.data['RF_BDATE'].msg={
                                type:false,
                                texts:['未填寫出生日期']
                            }
                        }
                    }else if(id=='RF_ADDRESS'){
                        this.form1.data['RF_ADDRESS'].msg=''
                        if(this.IsNull(this.form1.data['RF_ADDRESS'].val)==''){
                            msg+='未填寫地址\n'
                            this.form1.data['RF_ADDRESS'].msg={
                                type:false,
                                texts:['未填寫地址']
                            }
                        }
                    }else if(id=='RF_CITYID'){
                        this.form1.data['RF_CITYID'].msg=''
                        if(this.IsNull(this.form1.data['RF_CITYID'].val)==''){
                            msg+='未填選縣市\n'
                            this.form1.data['RF_CITYID'].msg={
                                type:false,
                                texts:['未填選縣市']
                            }
                        }
                    }else if(id=='RF_TOWNID'){
                        this.form1.data['RF_TOWNID'].msg=''
                        if(this.IsNull(this.form1.data['RF_TOWNID'].val)==''){
                            msg+='未填選鄉鎮\n'
                            this.form1.data['RF_TOWNID'].msg={
                                type:false,
                                texts:['未填選鄉鎮']
                            }
                        }
                    }
                    return msg
                }
                return Obj
            },
            TablePageObj_init(){
                let Obj={}
                Obj['TablePage']=this.TablePage

                //=================================
                //api
                Obj['FunctionGetCourseFeriodSingleByIndex']=
                this.FunctionGetCourseFeriodSingleByIndex

                return Obj
            },
            form2Obj_init(){
                let Obj={}
                
                Obj['form2']=this.form2

                Obj['Clear']=()=>{
                    for(let i in this.form2.data){
                        let item=this.form2.data[i]
                        if(i=='RF_SEX'){
                            item.val=0
                        }else{
                            item.val=''
                        }
                        item.only=false
                        item.msg=''
                    }
                    this.form2.index=''
                }

                Obj['Show']=(data,index)=>{
                    Obj['Clear']()
                    this.form2.index=index
                    for(let i in this.form2.data){
                        if(this.view1Obj['Filter'](i)==false){
                            continue
                        }
                        
                        if(i=='RF_ADDRESS'){
                            this.form2.data['RF_CITYID'].val=data['RF_CITYID']
                            this.form2.data['RF_CITYID'].change(this.form2.data['RF_CITYID'])
                            this.form2.data['RF_TOWNID'].val=data['RF_TOWNID']
                            Obj['Check']('RF_TOWNID')
                        }
                        this.form2.data[i].val=data[i]
                        console.log(Obj)
                        Obj['Check'](i)
                    }
                    this.modal['form2'].show()
                }

                Obj['Check']=(id,type='form2',val)=>{
                    if(type=='TablePage'){
                        if(this.IsNull(this.form2.data[id])!=''){
                            this.form2.data[id].val=this.IsNull(val)
                            if(id=='RF_SEX' && val===0){
                                this.form2.data[id].val=0
                            }
                        }
                    }
                    let msg=''

                    if(['RF_CITYID','RF_TOWNID'].indexOf(id)!=-1 ){
                        if(this.view1Obj['Filter']('RF_ADDRESS')==false){
                            return msg
                        }
                    }else{
                        if(this.view1Obj['Filter'](id)==false && id!=='RF_MAIL'){
                            return msg
                        }
                    }


                    if(id=='RF_NAME'){
                        this.form2.data['RF_NAME'].msg=''
                        if(this.IsNull(this.form2.data['RF_NAME'].val)==''){
                            msg+='未填寫學員姓名\n'
                            this.form2.data['RF_NAME'].msg={
                                type:false,
                                texts:['未填寫學員姓名']
                            }
                        }
                    }else if(id=='RF_SEX'){
                        this.form2.data['RF_SEX'].msg=''
                        if(this.IsNull(this.form2.data['RF_SEX'].val)==''&&
                            this.form2.data['RF_SEX'].val!==0){
                            msg+='未填選性別\n'
                            this.form2.data['RF_SEX'].msg={
                                type:false,
                                texts:['未填選性別']
                            }
                        }
                    }else if(id=='RF_NID'){
                        this.form2.data['RF_NID'].msg=''
                        if(this.IsNull(this.form2.data['RF_NID'].val)==''){
                            msg+='未填寫身份證號\n'
                            this.form2.data[''].msg={
                                type:false,
                                texts:['未填寫身份證號']
                            }
                        }else if(this.VerifyModel()['nid'](this.form2.data['RF_NID'].val)==false){
                            msg+='身分證號格式不符\n'
                            this.form2.data['RF_NID'].msg={
                                type:false,
                                texts:['身分證號格式不符']
                            }
                        } 
                    }else if(id=='RF_PHONE'){
                        this.form2.data['RF_PHONE'].msg=''
                        if(this.IsNull(this.form2.data['RF_PHONE'].val)==''){
                            msg+='未填寫行動電話\n'
                            this.form2.data['RF_PHONE'].msg={
                                type:false,
                                texts:['未填寫行動電話']
                            }
                        }else if(this.VerifyModel()['phone'](this.form2.data['RF_PHONE'].val)==false){
                            msg+='行動電話格式不符\n'
                            this.form2.data['RF_PHONE'].msg={
                                type:false,
                                texts:['行動電話格式不符']
                            }
                        }
                    }else if(id=='RF_TEL'){
                        this.form2.data['RF_TEL'].msg=''
                        if(this.IsNull(this.form2.data['RF_TEL'].val)==''){
                            msg+='未填寫聯絡電話\n'
                            this.form2.data['RF_TEL'].msg={
                                type:false,
                                texts:['未填寫聯絡電話']
                            }
                        }else if(this.VerifyModel()['tel'](this.form2.data['RF_TEL'].val)==false){
                            msg+='聯絡電話格式不符\n'
                            this.form2.data['RF_TEL'].msg={
                                type:false,
                                texts:['聯絡電話格式不符']
                            }
                        }
                    }else if(id=='RF_MAIL'){
                        this.form2.data['RF_MAIL'].msg=''
                        if(this.IsNull(this.form2.data['RF_MAIL'].val)==''){
                            msg+='未填寫電子信箱\n'
                            this.form2.data['RF_MAIL'].msg={
                                type:false,
                                texts:['未填寫電子信箱']
                            }
                        }else if(this.VerifyModel()['email'](this.form2.data['RF_MAIL'].val)==false){
                            msg+='電子信箱格式不符\n'
                            this.form2.data['RF_MAIL'].msg={
                                type:false,
                                texts:['電子信箱格式不符']
                            }
                        }
                    }else if(id=='RF_BDATE'){
                        this.form2.data['RF_BDATE'].msg=''
                        if(this.IsNull(this.form2.data['RF_BDATE'].val)==''){
                            msg+='未填寫出生日期\n'
                            this.form2.data['RF_BDATE'].msg={
                                type:false,
                                texts:['未填寫出生日期']
                            }
                        }
                    }else if(id=='RF_ADDRESS'){
                        this.form2.data['RF_ADDRESS'].msg=''

                        if(this.IsNull(this.form2.data['RF_ADDRESS'].val)==''){
                            msg+='未填寫地址\n'
                            this.form2.data['RF_ADDRESS'].msg={
                                type:false,
                                texts:['未填寫地址']
                            }
                        }
                    }else if(id=='RF_CITYID'){
                        this.form2.data['RF_CITYID'].msg=''
                        if(this.IsNull(this.form2.data['RF_CITYID'].val)==''){
                            msg+='未填選縣市\n'
                            this.form2.data['RF_CITYID'].msg={
                                type:false,
                                texts:['未填選縣市']
                            }
                        }
                    }else if(id=='RF_TOWNID'){
                        this.form2.data['RF_TOWNID'].msg=''
                        if(this.IsNull(this.form2.data['RF_TOWNID'].val)==''){
                            msg+='未填選鄉鎮\n'
                            this.form2.data['RF_TOWNID'].msg={
                                type:false,
                                texts:['未填選鄉鎮']
                            }
                        }
                    }
                    return msg
                }

                Obj['Save']=()=>{
                    
                    let msg=''
                    for(let i in this.form2.data){
                        msg+=Obj['Check'](i)
                    }
                    if(msg!=''){
                        console.log(msg)
                        alert('請確認報名欄位皆已填寫')
                        return
                    }

                    let index=this.form2.index
                    let data1=this.form4.datas[index]

                    let data2=this.TablePage.items[index]
                    let data3=this.TablePage.itemsAll[index]

                    for(let i in this.form2.data){
                        let item=this.form2.data[i]
                        if(this.view1Obj['Filter'](i)==false){
                            continue;
                        }

                        if(i=='RF_ADDRESS'){
                            data1[i].val=this.form2.data['RF_CITYID'].val
                            data2[i]=this.form2.data['RF_CITYID'].val
                            data3[i]=this.form2.data['RF_CITYID'].val

                            data1[i].val=this.form2.data['RF_TOWNID'].val
                            data2[i]=this.form2.data['RF_TOWNID'].val
                            data3[i]=this.form2.data['RF_TOWNID'].val
                        }

                        data1[i].val=item.val
                        data2[i]=item.val
                        data3[i]=item.val
                    }

                }

                return Obj
            },
            form3Obj_init(){
                let Obj={}

                Obj['form3']=this.form3

                Obj['Clear']=()=>{
                    for(let i in this.form3.data){
                        let item=this.form3.data[i]

                        if(i=='PAY_TYPE'){
                            item.val=0
                        }else if(i=='if2'){
                            item.val=[]
                        }else{
                            item.val=''
                        }
                        item.msg=''
                        item.only=false
                    }
                }

                return Obj
            },
            form4Obj_init(){
                let Obj={}

                Obj['form4']=this.form4


                Obj['Check']=(id,data)=>{
                    let msg=''
                    if(['RF_CITYID','RF_TOWNID'].indexOf(id)!=-1 ){
                        if(this.view1Obj['Filter']('RF_ADDRESS')==false){
                            return msg
                        }
                    }else{
                        if(this.view1Obj['Filter'](id)==false && id!=='RF_MAIL'){
                            return msg
                        }
                    }

                    if(id=='RF_NAME'){
                        data.msg=''
                        if(this.IsNull(data.val)==''){
                            msg+='未填寫學員姓名\n'
                            data.msg={
                                type:false,
                                texts:['未填寫學員姓名']
                            }
                        }
                    }else if(id=='RF_SEX'){
                        data.msg=''
                        if(this.IsNull(data.val)==''&&
                            data.val!==0){
                            msg+='未填選性別\n'
                            data.msg={
                                type:false,
                                texts:['未填選性別']
                            }
                        }
                    }else if(id=='RF_NID'){
                        data.msg=''
                        if(this.IsNull(data.val)==''){
                            msg+='未填寫身份證號\n'
                            data.msg={
                                type:false,
                                texts:['未填寫身份證號']
                            }
                        }else if(this.VerifyModel()['nid'](data.val)==false){
                            msg+='身分證號格式不符\n'
                            data.msg={
                                type:false,
                                texts:['身分證號格式不符']
                            }
                        }
                    }else if(id=='RF_PHONE'){
                        data.msg=''
                        if(this.IsNull(data.val)==''){
                            msg+='未填寫行動電話\n'
                            data.msg={
                                type:false,
                                texts:['未填寫行動電話']
                            }
                        }else if(this.VerifyModel()['phone'](data.val)==false){
                            msg+='行動電話格式不符\n'
                            data.msg={
                                type:false,
                                texts:['行動電話格式不符']
                            }
                        }
                    }else if(id=='RF_TEL'){
                        data.msg=''
                        if(this.IsNull(data.val)==''){
                            msg+='未填寫聯絡電話\n'
                            data.msg={
                                type:false,
                                texts:['未填寫聯絡電話']
                            }
                        }else if(this.VerifyModel()['tel'](data.val)==false){
                            msg+='聯絡電話格式不符\n'
                            data.msg={
                                type:false,
                                texts:['聯絡電話格式不符']
                            }
                        }
                    }else if(id=='RF_MAIL'){
                        data.msg=''
                        if(this.IsNull(data.val)==''){
                            msg+='未填寫電子信箱\n'
                            data.msg={
                                type:false,
                                texts:['未填寫電子信箱']
                            }
                        }else if(this.VerifyModel()['email'](data.val)==false){
                            msg+='電子信箱格式不符\n'
                            data.msg={
                                type:false,
                                texts:['電子信箱格式不符']
                            }
                        }
                    }else if(id=='RF_BDATE'){
                        data.msg=''
                        if(this.IsNull(data.val)==''){
                            msg+='未填寫出生日期\n'
                            data.msg={
                                type:false,
                                texts:['未填寫出生日期']
                            }
                        }
                    }else if(id=='RF_ADDRESS'){
                        data.msg=''
                        if(this.IsNull(data.val)==''){
                            msg+='未填寫地址\n'
                            data.msg={
                                type:false,
                                texts:['未填寫地址']
                            }
                        }
                    }else if(id=='RF_CITYID'){
                        data.msg=''
                        if(this.IsNull(data.val)==''){
                            msg+='未填選縣市\n'
                            data.msg={
                                type:false,
                                texts:['未填選縣市']
                            }
                        }
                    }else if(id=='RF_TOWNID'){
                        data.msg=''
                        if(this.IsNull(data.val)==''){
                            msg+='未填選鄉鎮\n'
                            data.msg={
                                type:false,
                                texts:['未填選鄉鎮']
                            }
                        }
                    }
                    return msg
                }

                Obj['Creat']=()=>{
                    let json1={
                        RF_NAME:{type:'text',val:'',text:'請輸入學員姓名',only:false,msg:'',show:false,
                            change:(data)=>{
                                Obj['Check']('RF_NAME',data)
                            }
                        },
                        RF_SEX:{type:'b-radio-group',
                            val:0,text:'請輸入性別',
                            options:[{text:'男',value:0},{text:'女',value:1}],
                            only:false,msg:'',show:false,
                            change:(data)=>{
                                Obj['Check']('RF_SEX',data)
                            }
                        },
                        RF_NID:{type:'text',val:'',text:'請輸入身份證號',only:false,msg:'',show:false,
                            change:(data)=>{
                                Obj['Check']('RF_NID',data)
                            }
                        },
                        RF_PHONE:{type:'text',val:'',text:'請輸入行動電話',only:false,msg:'',show:false,
                            change:(data)=>{
                                Obj['Check']('RF_PHONE',data)
                            }
                        },
                        RF_TEL:{type:'text',val:'',text:'請輸入聯絡電話',only:false,msg:'',show:false,
                            change:(data)=>{
                                Obj['Check']('RF_TEL',data)
                            }
                        },
                        RF_MAIL:{type:'text',val:'',text:'請輸入電子信箱',only:false,msg:'',show:false,
                            change:(data)=>{
                                Obj['Check']('RF_MAIL',data)
                            }
                        },
                        RF_BDATE:{type:'date',val:'',only:false,msg:'',show:false,
                            change:(data)=>{
                                Obj['Check']('RF_BDATE',data)
                            }
                        },
                        RF_ADDRESS:{type:'text',val:'',text:'請輸入地址',only:false,msg:'',show:false,
                            change:(data)=>{
                                Obj['Check']('RF_ADDRESS',data)
                            }
                        },
                        RF_CITYID:{type:'select2',val:'',text:'縣市',only:false,
                            options:{},
                            msg:'',show:true,
                            change:(data)=>{
                                Obj['Check']('RF_CITYID',data)
                                json1['RF_TOWNID'].val=''
                                if(data.val==''){   
                                    json1['RF_TOWNID'].options={}
                                }else{
                                    let data1=this.townName.filter(x=>x.TW_CITY_ID==data.val)
                                    let options={}
                                    for(let i in data1){
                                        let item=data1[i]
                                        options[item.TW_TOWN_ID]={
                                            text:this.IsNull(item.TW_TOWN_NAME),
                                            val:item.TW_TOWN_ID
                                        }
                                    }
                                    json1['RF_TOWNID'].options=options
                                }
                                Obj['Check']('RF_TOWNID',json1['RF_TOWNID'])
                            }
                        },
                        RF_TOWNID:{type:'select2',val:'',text:'鄉鎮',only:false,
                            options:{},
                            msg:'',show:true,
                            change:(data)=>{
                                Obj['Check']('RF_TOWNID',data)
                            }
                        },
                    }
                    json1['RF_CITYID'].options=this.DeepCopy(this.form1.data['RF_CITYID'].options)

                    return json1
                }

                Obj['Add']=()=>{
                    if(Object.keys(this.courseData).length==0){
                        return
                    }
                    let data1=Obj['Creat']()
                    for(let i in data1){
                        let item=data1[i]
                        item.show=this.view1Obj['Filter'](i)
                    }
                    this.form4.datas.push(data1)
                }

                Obj['Del']=(index)=>{
                    this._listdel(this.form4.datas,index)
                    if((this.TablePage.items.length-1)>=index){
                        this._listdel(this.TablePage.items,index)
                        this._listdel(this.TablePage.itemsAll,index)
                        this.Table3.num=this.TablePage.items.length
                        this.Table3Obj['Update']()
                    }
                }
                return Obj
            },
            view1Obj_init(){
                let Obj={}
                
                Obj['view1']=this.view1

                Obj['Filter']=(id,type='id')=>{
                    if(this.IsNull(this.courseData.CA_REGISTRATION_FORM)==''){
                        return false
                    }
                    if(type=='id'){
                        let text = this.typeName[id]
                        if(this.IsNull(text)==''){
                            return false
                        }else if(this.courseData.CA_REGISTRATION_FORM.indexOf(text)==-1){
                            return false
                        }else{
                            return true
                        }
                    }else if(type=='text'){
                        let texts=this.courseData.CA_REGISTRATION_FORM.split(',')
                        if(texts.indexOf(id)==-1){
                            return false
                        }else{
                            return false
                        }
                    }
                }

                Obj['Show']=()=>{
                    if(Object.keys(this.courseData).length==0){
                        return
                    }
                    let msg=''
                    for(let i in this.form4.datas){
                        let item=this.form4.datas[i]
                        for(let j in item){
                            msg+=this.form4Obj['Check'](j,item[j])
                        }
                    }
                    if(msg!=''){
                        this.Clog(msg)
                        alert('請確認報名欄位皆已填寫')
                        return
                    }


                    let data1=[]
                    for(let i in this.form4.datas){
                        let item=this.form4.datas[i]
                        let json1={}
                        for(let j in item){

                            if(Obj['Filter'](j)==false){
                                continue;
                            }

                            if(j=='RF_ADDRESS'){
                                json1['RF_CITYID']=item['RF_CITYID'].val 
                                json1['RF_TOWNID']=item['RF_TOWNID'].val 
                            }
                            json1[j]=item[j].val

                        }
                        data1.push(json1)
                    }

                    this.TablePage.set(this.TablePage,data1)
                    this.Table3.num=this.TablePage.items.length
                    this.Table3Obj['Update']()
                    this.view1.type=0
                }

                Obj['Close']=()=>{
                    this.view1.type=1
                }

                Obj['Save']=()=>{
                    if(Object.keys(this.courseData).length==0){
                        return
                    }
                    if(this.form3.data['if2'].val.length==0){
                        alert('未勾選詳細閱讀並同意本網站之相關條款')
                        return
                    }

                    if(this.data_in.RF_IS_GROUP==1){
                        let msgs=[]
                        this.TablePage.items.forEach((i2,i1)=>{
                            let msg=''
                            for(let j in i2){
                                msg+=this.form2Obj['Check'](j,'TablePage',i2[j])
                            }
                            if(msg!=''){
                                msg=`第${(Number(i1)+1)}筆學員[${this.IsNull(i2.RF_NAME)}]:\n`+msg
                                msgs.push(msg)
                            }
                        })
                        if(msgs.length!=0){
                            alert(msgs.join('===================================\n'))
                            return 
                        }
                    }else{
                        let msg=''
                        for(let i in this.form1.data){
                            msg+=this.form1Obj['Check'](i)
                        }
                        if(msg!=''){
                            this.Clog(msg)
                            alert('請確認報名欄位皆已填寫')
                            return
                        }


                        if(this.Table4.type=='2' && 
                            this.Table4.if1==false){
                            this.Table4['RF_IDID'].msg={type:false,texts:[]}
                            this.Table4['NID_val'].msg={type:false,texts:[]}
                            alert('身分優惠，NID未驗證')
                            return
                        }
                    }

                    let json1={}
                    json1['RF_CP_ID']=this.data_in.CP_ID
                    json1['RF_IS_GROUP']=this.data_in.RF_IS_GROUP
                    json1['PAY_TYPE']=this.form3.data['PAY_TYPE'].val
                    json1['PAY_RECEIPT_TITLE']=this.form3.data['PAY_RECEIPT_TITLE'].val
                    json1['PAY_UNICODE_NUMBER']=this.form3.data['PAY_UNICODE_NUMBER'].val
                    
                    //json1['RF_IDID']=''
                    json1['RF_IDID']=this.IsNull(this.Table4['RF_IDID'].val)
                    json1['PAY_DISCOUNT_IDENTITY']=this.IsNull(this.Table4['RF_IDID'].val)==''?0:1
                    json1['list']=[]

                    

                    if(this.data_in.RF_IS_GROUP==1){
                        json1['RF_IDID']=''
                        json1['PAY_TYPE']='1'
                        
                        this.TablePage.items.forEach(x=>{
                            let json2={}
                            for(let i in x){
                                if(Obj['Filter'](i)==false){
                                    continue;
                                }
                                if(i=='RF_ADDRESS'){
                                    json2['RF_CITYID']=x['RF_CITYID']
                                    json2['RF_TOWNID']=x['RF_TOWNID']
                                }
                                json2[i]=x[i]
                            }
                            json1['list'].push(json2)
                        })
                    }else{
                        let json2={}
                        for(let i in this.form1.data){
                            let item=this.form1.data[i]
                            if(Obj['Filter'](i)==false){
                                continue;
                            }
                            if(i=='RF_ADDRESS'){
                                json2['RF_CITYID']=this.form1.data['RF_CITYID'].val
                                json2['RF_TOWNID']=this.form1.data['RF_TOWNID'].val
                            }
                            json2[i]=item.val
                        }
                        if(this.Table4.type=='2'){
                            json2['RF_NID2']=this.Table4.nid
                        }
                        json1['list'].push(json2)
                    }
                    
                    this.FunctionToken(Obj['FunctionAddRegistration'],json1)
                }


                Obj['Go']=(data)=>{
                    this.Clog(data)
                }

                //==================================
                //api
                Obj['FunctionAddRegistration']=(data_in)=>{
                    this.apiAddRegistration(data_in)
                    .then(res=>{
                        let json=JSON.parse(res.data)
                        if(json.Status){
                            alert('報名成功')
                            let data1=JSON.parse(json.Data)
                            this.Clog(data1)
                            if(data1[0].RF_IS_GROUP=='0' && this.form3.data['PAY_TYPE'].val!='1'){
                                let url=this.SignUp_url+'?RF_ID='
                                this._go1(url+data1[0].RF_ID)
                            }else{
                                this._go('/courses')  
                            }
                        }else{
                            alert(json.Data)
                        }
                    })
                    .catch(err=>{
                        alert('報名失敗')
                        console.log(err)
                    })
                }

                return Obj
            },
            //=====================================================
            //api
            FunctionGetCourseFeriodSingleByIndex(data_in){
                this.apiGetCourseFeriodSingleByIndex(data_in)
                .then(res=>{
                    let json=JSON.parse(res.data)
                    if(json.Status){
                        let data1=JSON.parse(json.Data)
                        this.Clog(data1)
                        if(this.IsNull(data1)==''){
                            this._go('/')
                        }
                        this.CourseIf2(data1)
                        this.courseData=data1
                        if(this.courseData.canSignin==false){
                            alert('未開放報名')
                            this._go('/')
                            return
                        }
                        

                        for(let i in this.form1.data){
                            let item=this.form1.data[i]
                            item.show=this.view1Obj['Filter'](i)
                        }
                        for(let i in this.form2.data){
                            let item=this.form2.data[i]
                            item.show=this.view1Obj['Filter'](i)
                        }
                        for(let i in this.TablePage.fields){
                            let item=this.TablePage.fields[i]
                            if(this.view1Obj['Filter'](item.key)){
                                item.thClass=''
                                item.tdClass=''
                            }
                        }

                        this.form4.datas=[]
                        this.form4Obj['Add']()
                        this.form4Obj['Add']()

                        this.badge.data=[]

                        if(this.IsNull(this.courseData.CA_HASHTAG_ID)!=''){
                            let tag1=this.courseData.CA_HASHTAG_ID.split(',')
                            for(let i in tag1){
                                this.badge.data.push(
                                    {style1:'primary',text:tag1[i],class:'mr-1'}
                                )
                            }
                        }
                        


                        this.head.text=this.IsNull(this.courseData['CS_TITLE'])
                        this.head.text3='梯次'+this.IsNull(this.courseData.CA_PERIOD)

                       this.Table2.course=this.IsNull(this.courseData['CS_TITLE'])
                       this.Table2.price=this.IsNull2(this.courseData['CA_TUITION'])
                       if(this.courseData['CA_IS_EARLY'] && 
                          this.courseData['courseStatus'].indexOf('早鳥優惠')!=-1){
                          this.Table2.off.val=this.IsNull2(this.courseData['CA_EARLY_TUITION'])
                          this.Table2.off.show=true
                       }
                       this.Table2Obj['Update']()


                       this.Table3.course=this.IsNull(this.courseData['CS_TITLE'])
                       this.Table3.price=this.IsNull2(this.courseData['CA_TUITION'])
                       if(this.courseData['CA_IS_GROUP']){
                           this.Table3.off.val=this.IsNull2(this.courseData['CA_GROUP_TUITION'])
                       }

                       this.form4.course=this.IsNull(this.courseData['CS_TITLE'])
                       if(this.courseData['CA_IS_GROUP']){
                           this.form4.off.show=true
                           this.form4.off.num=this.IsNull2(this.courseData['CA_GROUP_NUMBER'])
                           this.form4.off.price=this.IsNull2(this.courseData['CA_GROUP_TUITION'])
                       }
                       
                        if(this.data_in.RF_IS_GROUP!=1){
                            this.form1.data['RF_IDID'].show=true
                            if(this.IsNull(this.courseData['IDENTITY_DISCOUNT_COURSE_ADMISSIONS'])!='' &&
                                this.courseData['IDENTITY_DISCOUNT_COURSE_ADMISSIONS'].length!=0){
                                let json1={}
                                for(let i in this.courseData['IDENTITY_DISCOUNT_COURSE_ADMISSIONS']){
                                    let item=this.courseData['IDENTITY_DISCOUNT_COURSE_ADMISSIONS'][i]
                                    json1[item.IDCA_IDID]={val:item.IDCA_IDID,text:item.IDCA__MEMO}
                                    
                                }
                                this.form1.data['RF_IDID'].options=json1
                            
                            }
                        }

                        if(this.IsNull(this.courseData['IDENTITY_DISCOUNT_COURSE_ADMISSIONS'])!=''){
                            let options={}
                            for(let i in this.courseData['IDENTITY_DISCOUNT_COURSE_ADMISSIONS']){
                                let item=this.courseData['IDENTITY_DISCOUNT_COURSE_ADMISSIONS'][i]
                                this.identityData[item.IDCA_ID]=item
                                this.Table4.data[item.IDCA_IDID]=item
                                if(item.IDCA__ON_SPOT==1){
                                    options[item.IDCA_IDID]={
                                        text:this.IsNull(item.ID_NAME)+' [臨櫃驗證]',val:item.IDCA_IDID
                                    }
                                }else if(this.OnlyData['ID'].findIndex(x=>x.ID_ID==item.IDCA_IDID)!=-1){
                                    options[item.IDCA_IDID]={
                                        text:this.IsNull(item.ID_NAME)+' [NID驗證]',val:item.IDCA_IDID
                                    }
                                }else{
                                    options[item.IDCA_IDID]={
                                        text:this.IsNull(item.ID_NAME),val:item.IDCA_IDID
                                    }
                                }
                                
                            
                            }
                            this.Table4['RF_IDID'].options=options
                        }




                        if(this.courseData['courseStatus'].indexOf('早鳥優惠')!=-1 &&
                            this.courseData['CA_EARLY_SHARED']!=1){
                            console.log('未啟用優惠共用')
                            this.Table4.show=false
                        }


                        
                    }else{
                        this._go('/')
                    }
                })
                .catch(err=>{
                    this.Elog(err)
                    this._go('/')
                })
            },
            FunctionMyACCOUNT(data_in){
                this.apiMyACCOUNT(data_in)
                .then(res=>{
                    let json=JSON.parse(res.data)
                    if(json.Status){
                        let data1=JSON.parse(json.Data)
                        if(this.IsNull(data1)!=''){
                            this.AccountData=data1
                            if(this.IsNull(this.AccountData['AC_USERNAME'])!=''){
                                this.form1.data['RF_NAME'].val=this.AccountData['AC_USERNAME']
                            }
                            if(this.IsNull(this.AccountData['AC_SEX'])!=''){
                                this.form1.data['RF_SEX'].val=this.AccountData['AC_SEX']
                                
                            }
                            if(this.IsNull(this.AccountData['AC_IDNUM'])!=''){
                                this.form1.data['RF_NID'].val=this.AccountData['AC_IDNUM']
                            }
                            if(this.IsNull(this.AccountData['AC_MOBILE'])!=''){
                                this.form1.data['RF_PHONE'].val=this.AccountData['AC_MOBILE']
                            }
                            if(this.IsNull(this.AccountData['AC_TEL'])!=''){
                                this.form1.data['RF_TEL'].val=this.AccountData['AC_TEL']
                            }
                            if(this.IsNull(this.AccountData['AC_EMAIL'])!=''){
                                this.form1.data['RF_MAIL'].val=this.AccountData['AC_EMAIL']
                            }
                            if(this.IsNull(this.AccountData['AC_BIRTHDAY'])!=''){
                                let date1=this.date2str(new Date(this.AccountData['AC_BIRTHDAY']))
                                this.form1.data['RF_BDATE'].val=date1.join('-')
                            }
                            if(this.IsNull(this.AccountData['AC_CITYID'])!=''){
                                this.form1.data['RF_CITYID'].val=this.AccountData['AC_CITYID']
                                if(this.IsNull(this.townName)!=''){
                                    let data1=this.townName.filter(x=>x.TW_CITY_ID==this.form1.data['RF_CITYID'].val)
                                    let options={}
                                    for(let i in data1){
                                        let item=data1[i]
                                        options[item.TW_TOWN_ID]={
                                            text:this.IsNull(item.TW_TOWN_NAME),
                                            val:item.TW_TOWN_ID
                                        }
                                    }
                                    this.form1.data['RF_TOWNID'].options=options
                                }
                            }
                            if(this.IsNull(this.AccountData['AC_TOWNID'])!=''){
                                this.form1.data['RF_TOWNID'].val=this.AccountData['AC_TOWNID']
                            }
                            if(this.IsNull(this.AccountData['AC_ADDRESS'])!=''){
                                this.form1.data['RF_ADDRESS'].val=this.AccountData['AC_ADDRESS']
                            }


                        }
                    }
                })
                .catch(err=>{
                    this.Elog(err)
                })
            },
            FunctionSelectCity(data_in){
                this.apiSelectCity(data_in)
                .then(res=>{
                    let json=JSON.parse(res.data)
                    if(json.Status){
                    let data1=JSON.parse(json.Data)
                    this.Clog(data1)
                    let options={}
                    for(let i in data1){
                        let item=data1[i]
                        this.cityName[item.CT_CITY_ID]=item
                        options[item.CT_CITY_ID]={
                            text:this.IsNull(item.CT_NAME),
                            val:item.CT_CITY_ID
                        }
                    }
                    this.form1.data['RF_CITYID'].options=options
                    this.form2.data['RF_CITYID'].options=options
                    
                    if(this.form4.datas.length!=0){
                        for(let i in this.form4.datas){
                            let item=this.form4.datas[i]
                            item['RF_CITYID'].options=options
                        }
                    }

                    }
                })
                .catch(err=>{
                    this.Elog(err)
                })
            },
            FunctionSelectTown(data_in){
                this.apiSelectTown(data_in)
                .then(res=>{
                    let json=JSON.parse(res.data)
                    if(json.Status){
                    let data1=JSON.parse(json.Data)
                    this.Clog(data1)
                    this.townName=data1
                    if(this.form1.data['RF_CITYID'].val!=''){
                        let data1=this.townName.filter(x=>x.TW_CITY_ID==this.form1.data['RF_CITYID'].val)
                        let options={}
                        for(let i in data1){
                            let item=data1[i]
                            options[item.TW_TOWN_ID]={
                                text:this.IsNull(item.TW_TOWN_NAME),
                                val:item.TW_TOWN_ID
                            }
                        }
                        this.form1.data['RF_TOWNID'].options=options
                    }

                    }
                })
                .catch(err=>{
                    this.Elog(err)
                })
            },

        }
    }
</script>
<style lang="scss">
    .Course {
        section {
            margin-bottom: 3rem;
        }
    }
    .payment {
        border-top: 1px solid #ccc;
        .item {
            border-bottom: 1px solid #ccc;
            padding-top: 1rem;
            padding-bottom: 1rem;
        }
    }
    .Multiplayer {
        border: 1px solid #cbe4f2;
        margin-bottom: 1rem;
        .number {
            display: flex;
            background-color:#cbe4f2;
            align-items:center;
            justify-content:center;
            width: 30px;
        }
        .del {

            padding-top: 1rem;
            padding-right: 1rem;
        }
    }
</style>