<template>
  <b-modal id="pips" centered body-class="p-0" hide-footer :active="data_in.form4.show">
    <template #modal-header>
      <div class="mx-auto">
        <h5>{{ data_in.form4.title }}</h5>
      </div>
    </template>
    <b-card>
      <div class="">
        <p>{{ data_in.form4.data["SS_TEXT"].val }}</p>
      </div>
    </b-card>
    <slot name='form4'> </slot>
  </b-modal>
</template>

<script>
export default {
  name: "Login",
  components: {},
  props: {
    data_in: {
      type: [Object],
      default() {
        return {}
      }
    }
  },
  data() {
    return {
      isLoading: false,
      imageCode: "",
      loginData: {
        Id: "",
        Type: "login",
        DecryptionPass: "",
        CodePass: "",
        DecryptionCodePass: "",

        TokenID: "",
        Token: "",
        Page: "",
      },
      creatData: {
        AC_USERNAME: "",
        AC_USERID: "",
        AC_USERBIR: "",
        AC_USERMAIL: "",
        AC_TEL: "",
        AC_PWD: "",
        AC_PWD_Comfirm: "",
        AC_CodePass: "",
        AC_DecryptionCodePass: "",

        TokenID: "",
        Token: "",
        Page: "",
      },
      lastpass: "",
      checkA: false,
      errormsg: "",
    };
  },
  created() {
    this.getImageCode();
  },
  mounted() {
  },
  computed: {
  },
  methods: {
    funIf(fun) {
      if (this.IsNull(fun) == '') {
        return
      } else (
        fun()
      )
    },
    evenF1(type, data, event) {
      if (type == 'keypress') {
        if (this.IsNull(data.keypress) == '') {
          return false
        } else {
          data.keypress(event, data)
        }
      } else if (type == 'change') {
        if (this.IsNull(data.change) == '') {
          return false
        } else {
          data.change(data)
        }
      }

    },
  },

};
</script>