<template>
    <b-table    
                          
        v-bind="TablePage.BT_style"
        :class="TablePage.class"
        :caption="TablePage.title"
        :id="TablePage.id"
        :items="TablePage.items"
        :fields="TablePage.fields"
        :current-page="TablePage.currentPage"
        :per-page="TablePage.page"
        :filter="TablePage.filter"
        :filterIncludedFields="TablePage.filterOn"
        :sort-by.sync="TablePage.sortBy"
        :sort-desc.sync="TablePage.sortDesc"
        :sort-direction="TablePage.sortDirection">
        <template #[re_cell(i2)]="row" v-for="(i2,i1) in  cell " >
        <div :key="i1" v-show="false"></div>
        <slot :name="re_cell(i2,'name')" :row="row" >
        </slot>
        </template>
        <template #[re_head(i2)] v-for="(i2,i1) in  head " >
        <div :key="i1" v-show="false"></div>
        <slot :name="'h_'+re_head(i2,'name')"  >
        </slot>
        </template>
        <template #custom-foot>
            <slot name="custom-foot"></slot>
        </template>

                        
                         
        </b-table>

</template>
<script>
export default {
    name:'BTableV1',
    props:{
        TablePage:{
            type:[Object],
            default(){
                return{
                    title:'',class:'',
                    BT_style:{'hover':true,'outlined':true,'responsive':true,'head-variant':'light'},
                    PG_style:{'pills':true,'align':'center','size':'sm'},
                    itemsAll:[],items:[],
                    id:"Table1",currentPage:1,rows:1,page:10,
                    sortBy:'',sortDesc:false,sortDirection:"asc",
                    filter:null,filterOn:[],
                    fields: [],
                    TNum:[   
                            {text:'列出近10筆記錄',val:10},
                            {text:'列出近20筆記錄',val:20},
                            {text:'列出近50筆記錄',val:50},
                            {text:'列出近100筆記錄',val:100},],
                    TIndex(th1,index){
                    return ((th1.currentPage-1)*th1.page)+index
                    },
                    clear(th1){
                    th1.itemsAll=[];
                    th1.items=[];
                    th1.rows=0;
                    th1.currentPage=1
                    },
                    set(th1,data){
                    th1.itemsAll=JSON.parse(JSON.stringify(data));
                    th1.items=JSON.parse(JSON.stringify(data));
                    th1.rows=data.length;
                    
                    },
                    set2(th1,data){
                    th1.items=JSON.parse(JSON.stringify(data));
                    th1.rows=data.length;
                    th1.currentPage=1;
                    },
                    set3(th1,data){
                    th1.items=JSON.parse(JSON.stringify(data));
                    th1.rows=data.length;
                    th1.currentPage=1;
                    }
                }
            },
        },
        
        cell:{
            type:[Array,Object],
            default(){return []}
        },
        head:{
            type:[Array,Object],
            default(){return []}
        }
    },
    data(){
        return{}
    },
    mounted(){

    },
    methods:{
        re_cell(data,type){
            if(type=='name'){
                if(this.IsNull(data.key)==''){
                    return data
                }else{
                    return data.key
                }
                
            }else{
                if(this.IsNull(data.key)==''){
                    return 'cell('+data+')'
                }else{
                    return 'cell('+data.key+')'
                }
            }
        },
        re_head(data,type){
            if(type=='name'){
                if(this.IsNull(data.key)==''){
                    return data
                }else{
                    return data.key
                }
                
            }else{
                if(this.IsNull(data.key)==''){
                    return 'head('+data+')'
                }else{
                    return 'head('+data.key+')'
                }
            }
        }
    }
}
</script>