<template>
    <div class="header">
        <Signin :data_in="{ form1, form2 }">
            <template #form1>
                <b-button pill class="mb-3" variant="primary" block @click="form1Save()">登入</b-button>
                <p class="text-center"><b-link @click="form3Show()">忘記密碼</b-link></p>
                <p v-show="false" class="text-center" @click="form1Go()">
                    <b-link>詳細資料</b-link>
                </p>
                <hr />
                <!--<b-button class="mb-3" variant="outline-primary" block @click="form1Login('fb')">
          <b-icon-facebook></b-icon-facebook> 使用 Facebook 帳號登錄
        </b-button>-->
                <b-button class="mb-3" variant="outline-danger" block @click="form1Login('google')">
                    <b-icon-google></b-icon-google> 使用 Google 帳號登錄
                </b-button>
            </template>
            <template #form2>
                <b-button @click="form2Save()" pill class="mb-0" type="submit" variant="primary" block>註冊
                </b-button>
                <hr />
                <b-form-checkbox @input="form4Show()" id="checkbox-1" name="checkbox-1" v-model="form2.data['if1'].val"
                    :disabled="form2.data['if1'].only">
                    註冊即表示您同意我們的
                    <b-link target="_blank" href="https://www.fcu.edu.tw/privacy/">個資保護政策</b-link>。
                </b-form-checkbox>
            </template>
        </Signin>
        <PIPS :data_in="{ form4 }">
            <template #form4>
            </template>
        </PIPS>
        <!--*********************************************************************************************-->
        <b-modal id="forget" centered body-class="p-4">
            <template #modal-title>忘記密碼</template>
            <b-card no-body style="border: 0px;">
                <!--
          <b-form-group class="course-search">
              <b-input-group>
                  <template #prepend>
                      <b-input-group-text>
                          <b-icon-person></b-icon-person>
                      </b-input-group-text>
                  </template>
                  <b-form-input 
                      :state="IsNull(form3.data['Id'].msg,{type:null}).type"
                      id="username"
                      type="search"
                      :disabled="form3.data['Id'].only"
                      :placeholder="form3.data['Id'].text"
                      v-model="form3.data['Id'].val"
                      @keypress="form3.data['Id'].keypress($event)"
                      @change="form3.data['Id'].change(form3.data['Id'])"
                  ></b-form-input>

                      <b-form-valid-feedback >
                          <template v-for="(i2,i1) in IsNull(form3.data['Id'].msg,{texts:[]}).texts">
                              <template v-if="i1==0">{{i2}}</template>
                              <template v-else> <br :key="i1">{{i2}}</template>
                          </template>
                        </b-form-valid-feedback>

                        <b-form-invalid-feedback >
                          <template v-for="(i2,i1) in IsNull(form3.data['Id'].msg,{texts:[]}).texts">
                              <template v-if="i1==0">{{i2}}</template>
                              <template v-else> <br :key="i1">{{i2}}</template>
                          </template>
                        </b-form-invalid-feedback>
                        
              </b-input-group>
          </b-form-group>
          -->

                <b-form-group class="course-search mb-0" description="※重新寄送帳號啟用通知信，請輸入申請帳號所填入資料">
                    <b-input-group>
                        <template #prepend>
                            <b-input-group-text>
                                <b-icon-envelope></b-icon-envelope>
                            </b-input-group-text>
                        </template>
                        <b-form-input :state="IsNull(form3.data['AC_EMAIL'].msg, { type: null }).type" id="email"
                            type="email" :disabled="form3.data['AC_EMAIL'].only" :placeholder="form3.data['AC_EMAIL'].text"
                            v-model="form3.data['AC_EMAIL'].val" @keypress="form3.data['AC_EMAIL'].keypress($event)"
                            @change="form3.data['AC_EMAIL'].change(form3.data['AC_EMAIL'])"></b-form-input>
                        <b-form-valid-feedback>
                            <template v-for="(i2, i1) in IsNull(form3.data['AC_EMAIL'].msg, { texts: [] }).texts">
                                <template v-if="i1 == 0">{{ i2 }}</template>
                                <template v-else> <br :key="i1">{{ i2 }}</template>
                            </template>
                        </b-form-valid-feedback>
                        <b-form-invalid-feedback>
                            <template v-for="(i2, i1) in IsNull(form3.data['AC_EMAIL'].msg, { texts: [] }).texts">
                                <template v-if="i1 == 0">{{ i2 }}</template>
                                <template v-else> <br :key="i1">{{ i2 }}</template>
                            </template>
                        </b-form-invalid-feedback>
                    </b-input-group>
                </b-form-group>

            </b-card>

            <template #modal-footer>
                <b-button @click="modal['form3'].hide()">取消</b-button>
                <b-button variant="primary" @click="form3Save()">寄送密碼</b-button>
            </template>
        </b-modal>



        <!--*********************************************************************************************-->
        <!-- <b-nav align="right" class="top-nav">
      <b-nav-item to="/">首頁</b-nav-item>
      <b-nav-item to="news">最新消息</b-nav-item>
      <b-nav-item>常見問題</b-nav-item>
      <b-nav-item @click="form1Show()">{{form1.text}}</b-nav-item>
    </b-nav> -->
        <header class="bg-whitezzz" v-bind:class="{ min: isMin }">
            <b-navbar class="append" v-bind:class="{ 'bg-white': navBg }" toggleable="lg" type="light">
                <b-navbar-brand class="logo" to="/">
                    <b-img :src="nav.data['img']" fluid></b-img>
                </b-navbar-brand>
                <b-navbar-toggle class="mr-2" @click="navBg = !navBg" target="nav-collapse"
                    id="head-nav-toggle"></b-navbar-toggle>
                <b-collapse class="p-3 p-md-0" id="nav-collapse" is-nav>
                    <b-navbar-nav>
                        <b-nav-form class="mr-sm-2 mb-2 mb-sm-0 navform" v-show="nav.show" id="navForm1">
                            <b-input-group class="course-search ">
                                <b-form-input placeholder="想學什麼？" v-model="nav.data['val']" @keypress="navKeyOn($event)"
                                    list="my-list-id"></b-form-input>
                                <b-input-group-append>
                                    <b-button variant="primary round-r" @click="navGo()">
                                        <b-icon-search></b-icon-search>
                                    </b-button>
                                </b-input-group-append>


                                <datalist id="my-list-id">
                                    <option v-for="(i2, i1) in nav.options" :key="i1">{{ i2 }}</option>
                                </datalist>



                            </b-input-group>
                        </b-nav-form>
                        <b-nav-item class="catalog" :key="index" v-for="(item, index) in CategoryData"
                            @click="navGo(item); navBg = !navBg">
                            <div class="icon"
                                :style="{ 'background-image': 'url(' + IsNull(files[item.CAT_ICON_URL]) + ')' }"></div>
                            <strong>{{ IsNull(item.CAT_NAME) }}</strong>
                        </b-nav-item>
                        <b-button class="round mr-md-2 px-3 mb-2 mb-sm-0 d-none d-sm-block" variant="primary" pill
                            to="/courses">
                            <b-icon-book class="mr-2"></b-icon-book> 課程
                        </b-button>
                    </b-navbar-nav>
                    <!-- Right aligned nav items -->
                    <b-navbar-nav class="ml-auto">
                        <b-button class="round px-3 mr-md-2 mb-2 mb-sm-0 mt-2 mt-sm-0" variant="primary" pill
                            @click="form1Show()">
                            <b-icon-person class="mr-1"></b-icon-person> {{ form1.text }}
                        </b-button>
                    </b-navbar-nav>
                </b-collapse>
            </b-navbar>
        </header>

        <!-- <header class="d-none" v-bind:class="{ min: isMin }">
      <b-navbar class="append" type="light">
        <b-navbar-brand class="logo" to="/">
          <b-img :src="nav.data['img']" fluid></b-img>
        </b-navbar-brand>
        <b-navbar-toggle target="nav-collapse"></b-navbar-toggle>
        <b-collapse id="nav-collapse" is-nav>
          <b-navbar-nav>
            <b-button class="round mr-2 px-3" variant="primary" pill to="/courses">
              <b-icon-book class="mr-2"></b-icon-book> <span class="d-sm-none d-md-block">課程</span> 
            </b-button>
            <b-nav-form class="mr-2" v-show="nav.show" >
              <b-input-group class="course-search" >
                <b-form-input
                  placeholder="想學什麼？"
                  v-model="nav.data['val']"
                  @keypress="navKeyOn($event)"
                ></b-form-input>
                <b-input-group-append>
                <b-button
                  variant="primary round-r"
                  class="mr-2"
                  @click="navGo()"
                >
                  <b-icon-search></b-icon-search>
                </b-button>
                </b-input-group-append>
              </b-input-group>
            </b-nav-form>
          </b-navbar-nav>
          <b-navbar-nav class="ml-auto">
            <b-button class="round px-3" variant="primary" pill @click="form1Show()">
              <b-icon-person class="mr-1"></b-icon-person> {{form1.text}}
            </b-button>
            <b-button variant="primary"  pill v-b-toggle.sidebar-1>
              <b-icon-list></b-icon-list>
            </b-button>
          </b-navbar-nav>
        </b-collapse>
      </b-navbar>
    </header> -->
        <LoginV1 :data_in="LoginF1" :val="'f'"></LoginV1>
    </div>
</template>
<script>
import Signin from "@/components/Signin.vue";
import PIPS from "@/components/PIPS.vue"
import LoginV1 from "../components/LoginV1.vue"
export default {
    name: "page-header",
    components: {
        Signin,
        LoginV1,
        PIPS
    },
    data() {
        return {
            navBg: false,
            modal_set: [
                { name: "form1", id: "signin" },
                { name: "form3", id: "forget" },
                { name: "form4", id: "pips" }
            ],

            form1: {
                text: '學員',
                title: "登入",
                show: true,
                url: "profile",
                data: {
                    Id: { type: 'text', val: "", text: "帳號／電子郵件", only: false, msg: '' },
                    DecryptionPass: { type: 'password', val: "", text: "密碼", only: false, msg: '' },
                    CodePass: {
                        type: 'text',
                        val: "",
                        text: "驗證碼",
                        only: false,
                        msg: '',
                        img: { Code: "", Image: "" },
                    },
                },
            },
            form2: {
                title: "註冊",
                show: false,
                data: {
                    AC_USERNAME: { type: 'text', val: "", text: "姓名", only: false, msg: '' },
                    AC_USERID: { type: 'text', val: "", text: "身分證號", only: false, msg: '' },
                    AC_BIRTHDAY: { type: 'text', val: "", text: "出生年月日", only: false, msg: '' },
                    AC_EMAIL: { type: 'text', val: "", text: "電子郵件", only: false, msg: '' },
                    AC_TEL: { type: 'text', val: "", text: "行動電話", only: false, msg: '' },
                    AC_PWD: { type: 'password', val: "", text: "密碼", only: false, msg: '' },
                    AC_PWD2: { type: 'password', val: "", text: "確認密碼", only: false, msg: '' },
                    if1: { val: false, text: "是否同意", only: false },
                },
            },
            form3: {
                title: '忘記密碼',
                data: {
                    Id: {
                        val: "", text: "帳號／電子郵件", only: false, msg: '',
                        keypress: () => { }, change: () => { }
                    },
                    AC_EMAIL: {
                        val: "", text: "電子郵件", only: false, msg: '',
                        keypress: () => { }, change: () => { }
                    },
                }
            },
            form4: {
                title: '個資宣告',
                show: false,
                data: {
                    SS_TEXT: {
                        val: "逢甲大學產學經營與人才培育中心為「辦理推廣教育」之目的，本申請表所蒐集之個人資訊(C001辨識個人者、C003政府資料中之辨識者、C011個人描述)，於臺灣地區利用您的個人資料，將僅作為報名推廣教育課程之用，網站會永久保留您留下資訊，您得以下列聯絡方式行使查閱、更正、刪除等個人資料保護法第3條的當事人權利。如您提供的資料不完整或不確實，將無法完成本表單的申請。若您對於本聲明、或與個人資料有關之相關事項有任何疑問，或欲行使上述當事人權利時，可連絡：台中市西屯區文華路100號，電話(04)24517250分機2411。"
                        , text: "", only: false, msg: ''
                    }
                }
            },
            isMin: false,

            nav: {
                show: true,
                title: "搜尋",
                data: {
                    img: require("@/assets/長版LOGO2.png"),
                    val: "",
                },
                options: [],
            },
            LoginF1: {},
            LoginData: {},
            CategoryData: [],
            files: {},
        };
    },
    mounted() {
        window.addEventListener("scroll", this.handleScroll);
        this.modal_init(this.modal_set);
        this.formCheckSet()
        this.LoginData['google'] = this.LoginF1['f'].GoogleModel()
        this.LoginData['fb'] = this.LoginF1['f'].FBModel()

        this._storeData('LoginF1', this.form1Show)
        this._storeData('Header_form1', this.form1)
        this._storeData('navBgSwitch', this.navBgSwitch)

        if (this.IsNull(this._sessionData("TokenID")) != '') {
            this.form1.text = '學員資料'
        }
        this.FunctionToken(this.FunctionGetCategoryListByIndex, {});



        this.FunctionToken(
            this.FunctionGetCourseFeriodListByIndex_mini, {}
        )

    },
    methods: {

        navBgSwitch(val) {
            if (val != this.navBg) {
                document.getElementById("head-nav-toggle").click();
            }
        },
        navKeyOn(event) {
            if (event.charCode === 13) {
                this.navGo()
            }
        },
        sessionF1(url, data) {
            if (url == "courses") {
                /*
                this._go2({
                  path:'/courses',
                  data:{CAT_ID:data}
                },'query')
                */
                this._sessionData('CAT_ID', this.IsNull(data.CAT_ID))
                this._sessionData('courseStatus', this.IsNull(data.courseStatus))
                if (this.$route.path !== '/courses') {
                    this._go('/courses')
                } else {
                    this.$router.go()
                }

            } else if (url == "course") {
                this._go2({
                    path: '/course',
                    data: { CP_ID: data }
                }, 'query')
            } else if (url == "NewsDetail") {
                this._go2({
                    path: '/NewsDetail',
                    data: { NE_ID: data }
                }, 'query')
            } else if (url == 'courseSeriesDetails') {
                this._go2({
                    path: '/courseSeriesDetails',
                    data: { SS_ID: data },
                }, 'query')
            }
        },
        navGo(data) {
            console.log('header------------')
            console.log(data)
            console.log(this.$route)

            if (this.IsNull(data) != '') {
                this.sessionF1("courses", this.IsNull({ CAT_ID: data.CAT_ID }));
            } else {
                this._storeData('reload', () => {
                    /*
                    this._go2({
                      path: "/courses",
                      data: { keyword: this.IsNull(this.nav.data.val) },
                    },"query")
                    */
                    this._sessionData('keyword', this.IsNull(this.nav.data.val))
                    this._go('/courses')
                    this.nav.data.val = "";
                })
                this._go("Reload");
            }

            //
        },
        formCheckSet() {

            document.getElementById('navForm1').onsubmit = () => {
                return false
            }


            for (let i in this.form1.data) {
                let item = this.form1.data[i]
                item.change = () => {
                    this.form1Check(i)
                }
            }
            for (let i in this.form2.data) {
                let item = this.form2.data[i]
                if (i == 'AC_EMAIL') {
                    item.change = (data) => {
                        this.form2Check(i)
                        this.FunctionToken(this.FunctionGetMail, {
                            AC_EMAIL: data.val,
                            JSval: { email: true }
                        })
                    }
                } else {
                    item.change = () => {
                        this.form2Check(i)
                    }
                }
            }

            for (let i in this.form3.data) {
                let item = this.form3.data[i]
                item.keypress = (event) => {
                    if (event.charCode === 13) {
                        this.form3Save();
                    }
                }

                item.change = () => {
                    this.form3Check(i)
                }
            }


        },
        form1Keyup(event) {
            if (event.charCode === 13) {
                this.form1Save();
            }
        },
        form2Keyup(event) {
            if (event.charCode === 13) {
                this.form2Save();
            }
        },

        form1Clear() {
            for (let i in this.form1.data) {
                let item = this.form1.data[i];
                item.val = "";
                item.msg = ''
                item.keypress = this.form1Keyup;
            }

            this.form1.data["CodePass"].img = { Code: "", Image: "" };
            this.FunctionToken(this.FunctionGetImageCode, {
                JSval: { type: "form1" },
            });
        },
        form2Clear() {
            for (let i in this.form2.data) {
                let item = this.form2.data[i];
                if (i == "if1") {
                    item.val = false;
                } else {
                    item.val = "";
                    item.msg = ''
                    item.keypress = this.form2Keyup;
                    item.only = false
                }
            }
        },
        form1Check(id) {
            let msg = ''
            if (id == 'Id') {
                this.form1.data['Id'].msg = ''
                if (this.IsNull(this.form1.data['Id'].val) == '') {
                    msg += '未填寫帳號／電子郵件\n'
                    this.form1.data['Id'].msg = {
                        type: false,
                        texts: ['未填寫帳號／電子郵件']
                    }
                }
            } else if (id == 'DecryptionPass') {
                this.form1.data['DecryptionPass'].msg = ''
                if (this.IsNull(this.form1.data['DecryptionPass'].val) == '') {
                    msg += '未填寫密碼\n'
                    this.form1.data['DecryptionPass'].msg = {
                        type: false,
                        texts: ['未填寫密碼']
                    }
                }
            } else if (id == 'CodePass') {
                this.form1.data['CodePass'].msg = ''
                if (this.IsNull(this.form1.data['CodePass'].val) == '') {
                    msg += '未填寫驗證碼\n'
                    this.form1.data['CodePass'].msg = {
                        type: false,
                        texts: ['未填寫驗證碼']
                    }
                }
            }
            return msg
        },

        form2Check(id) {
            let msg = ''
            if (id == 'AC_USERNAME') {
                this.form2.data['AC_USERNAME'].msg = ''
                if (this.IsNull(this.form2.data['AC_USERNAME'].val) == '') {
                    msg += '未填寫姓名\n'
                    this.form2.data['AC_USERNAME'].msg = {
                        type: false,
                        texts: ['未填寫姓名']
                    }
                }
            } else if (id == 'AC_EMAIL') {
                this.form2.data['AC_EMAIL'].msg = ''
                if (this.IsNull(this.form2.data['AC_EMAIL'].val) == '') {
                    msg += '未填寫電子郵件\n'
                    this.form2.data['AC_EMAIL'].msg = {
                        type: false,
                        texts: ['未填寫電子郵件']
                    }
                } else if (this.VerifyModel()['email'](this.form2.data['AC_EMAIL'].val) == false) {
                    msg += '電子郵件格式不符\n'
                    this.form2.data['AC_EMAIL'].msg = {
                        type: false,
                        texts: ['電子郵件格式不符']
                    }
                }
            } else if (id == 'AC_TEL') {
                this.form2.data['AC_TEL'].msg = ''
                if (this.IsNull(this.form2.data['AC_TEL'].val) == '') {
                    msg += '未填寫行動電話\n'
                    this.form2.data['AC_TEL'].msg = {
                        type: false,
                        texts: ['未填寫行動電話']
                    }
                } else if (this.VerifyModel()['phone'](this.form2.data['AC_TEL'].val) == false) {
                    msg += '行動電話格式不符\n'
                    this.form2.data['AC_TEL'].msg = {
                        type: false,
                        texts: ['行動電話格式不符']
                    }
                }
            } else if (id == 'AC_PWD') {
                this.form2.data['AC_PWD'].msg = ''
                if (this.IsNull(this.form2.data['AC_PWD'].val) == '') {
                    msg += '未填寫密碼\n'
                    this.form2.data['AC_PWD'].msg = {
                        type: false,
                        texts: ['未填寫密碼']
                    }
                }
            } else if (id == 'AC_PWD2') {
                this.form2.data['AC_PWD2'].msg = ''
                if (this.IsNull(this.form2.data['AC_PWD2'].val) == '') {
                    msg += '未填寫確認密碼\n'
                    this.form2.data['AC_PWD2'].msg = {
                        type: false,
                        texts: ['未填寫確認密碼']
                    }
                } else if (this.IsNull(this.form2.data['AC_PWD'].val) != '' &&
                    this.form2.data['AC_PWD'].val != this.form2.data['AC_PWD2'].val) {
                    msg += '密碼與確認密碼不符\n'
                    this.form2.data['AC_PWD2'].msg = {
                        type: false,
                        texts: ['密碼與確認密碼不符']
                    }
                }
            }
            return msg
        },

        form1Show() {
            if (this.IsNull(this._sessionData("TokenID")) != "") {
                this.form1Go();
                return;
            }

            this.form1Clear();
            this.form2Clear();
            this.form1.show = true
            this.form2.show = false
            this.modal["form1"].show();
        },
        form2Show() {
            this.form1.show = false
            this.form2.show = true
        },

        form1Save() {
            let msg = "";
            for (let i in this.form1.data) {
                msg += this.form1Check(i)
            }
            if (msg != '') {
                this.Clog(msg)
                alert('尚有錯誤未修復')
                return
            }

            let json1 = {};
            for (let i in this.form1.data) {
                json1[i] = this.form1.data[i].val;
            }
            json1["DecryptionCodePass"] = this.form1.data["CodePass"].img.Code;
            this.Clog(json1);

            this.FunctionToken(this.FunctionLogin, json1);
        },
        form2Save() {
            let msg = ''
            for (let i in this.form2.data) {
                msg += this.form2Check(i)
            }
            if (msg != '') {
                this.Clog(msg)
                alert('尚有錯誤未修復')
                return
            }

            if (this.form2.data["if1"].val == false) {
                alert('未同意 個資保護政策')
                return
            }

            let json1 = {};
            for (let i in this.form2.data) {
                if (i == "AC_PWD2" || i == "if1") {
                    continue;
                }

                json1[i] = this.form2.data[i].val;
            }
            json1['AC_USERID'] = this.form2.data["AC_EMAIL"].val
            json1['AC_BIRTHDAY'] = '1911/01/01'
            json1["AC_IDNUM"] = this.form2.data["AC_USERID"].val;
            this.Clog(json1);

            this.FunctionToken(this.FunctionACCOUNTInsertByIndex, json1);
        },
        form1Go() {
            if (this.IsNull(this._sessionData("TokenID")) == "") {
                alert("尚未登入");
                return;
            }

            this.modal["form1"].hide();
            this._go("profile");
        },
        form1Login(type) {
            if (type == 'google') {
                this.Clog('google')
                this.LoginData['google'].login((data) => {
                    this.Clog(data)
                    this.Clog(data.getId())
                    let Auth = data.getAuthResponse(true)
                    this.Clog(Auth)
                    this.Clog(Auth.id_token)
                    this.LoginData['google'].get((data) => {
                        if (this.IsNull(data.body) != '') {
                            let data1 = JSON.parse(data.body)
                            this.Clog(data1)

                            if (this.IsNull(data1.emailAddresses) != '' &&
                                data1.emailAddresses.length != 0) {
                                let email = this.IsNull(data1.emailAddresses[0].value)
                                this.Clog(email)
                                if (this.IsNull(email) != '') {
                                    this.FunctionToken(this.FunctionMailLogin, {
                                        TYPE: 'GOOGLE',
                                        ID: Auth.id_token,
                                        AC_EMAIL: email
                                    })
                                }
                            }
                        }
                    })

                })


            } else if (type == 'fb') {


                this.LoginData['fb'].login((data) => {
                    this.Clog(data)
                    this.LoginData['fb'].check((data) => {
                        let data1 = this.IsNull(data.authResponse)
                        this.Clog(data1.accessToken)
                        this.FunctionToken(this.FunctionMailLogin2, {
                            TYPE: 'FB',
                            ID: data1.accessToken,
                        })
                        //this.FunctionTEST2({id:data1.accessToken})
                    })
                    this.LoginData['fb'].get((data) => { this.Clog(data) })
                })


            }
        },

        form3Keyup(event) {
            if (event.charCode === 13) {
                this.form3Save();
            }
        },

        form3Clear() {
            for (let i in this.form3.data) {
                let item = this.form3.data[i]
                item.val = ''
                item.msg = ''
            }
        },
        form3Check(id) {
            let msg = ''

            if (id == 'AC_EMAIL') {
                this.form3.data['AC_EMAIL'].msg = ''
                if (this.IsNull(this.form3.data['AC_EMAIL'].val) == '') {
                    msg += '未填寫電子郵件\n'
                    this.form3.data['AC_EMAIL'].msg = {
                        type: false,
                        texts: ['未填寫電子郵件']
                    }
                }
            }
            return msg
        },

        form3Show() {
            this.form3.data['Id'].val = ''
            this.form3.data['AC_EMAIL'].val = ''
            this.modal['form1'].hide()
            this.modal['form3'].show()
        },
        form3Save() {
            let msg = ''
            for (let i in this.form3.data) {
                msg += this.form3Check(i)
            }
            if (msg != '') {
                console.log(msg)
                alert('尚有錯誤未修正')
                return
            }


            this.FunctionToken(this.FunctionLastPass, {
                AC_USERID: this.form3.data['AC_EMAIL'].val,
                AC_EMAIL: this.form3.data['AC_EMAIL'].val
            })

        },
        form4Show() {
            console.log(this.form2.data['if1'].val)
            if (this.form2.data['if1'].val) {
                console.log(this.modal["form4"])
                this.modal["form4"].show()
            }
        },

        handleScroll() {
            let scrollTop =
                window.pageYOffset ||
                document.documentElement.scrollTop ||
                document.body.scrollTop;
            //console.log(scrollTop); //页面滚动距离
            if (scrollTop > 50) {
                this.isMin = true;
            } else {
                this.isMin = false;
            }
        },
        //================================================================
        //api
        FunctionGetImageCode(data_in) {
            this.apiGetImageCode(data_in)
                .then((res) => {
                    let json = JSON.parse(res.data);
                    if (json.Status) {
                        let data1 = JSON.parse(json.Data);
                        this.Clog(data1);

                        if (data_in.JSval.type == "form1") {
                            this.form1.data["CodePass"].val = "";
                            this.form1.data["CodePass"].img.Code = this.IsNull(data1.Code);
                            this.form1.data["CodePass"].img.Image = this.IsNull(data1.Image);
                        }
                    }
                })
                .catch((err) => {
                    this.Elog(err);
                });
        },

        FunctionLogin(data_in) {
            let fun1 = this._storeData('LoginFUN')
            this.apiLogin(data_in)
                .then((res) => {
                    let json = JSON.parse(res.data);
                    this.Clog(json);
                    if (json.Status) {
                        let data1 = JSON.parse(json.Data);
                        this._sessionData("TokenID", data1);
                        this.Clog(data1);

                        this.modal["form1"].hide();
                        this.form1.text = '學員資料'
                        if (this.IsNull(fun1) != '') {
                            fun1(this.$route.path, () => {
                                this._go('/myRegistration')
                            })
                        } else {
                            this._go('/myRegistration')
                        }


                    } else {
                        this.FunctionToken(this.FunctionGetImageCode, {
                            JSval: { type: "form1" },
                        });
                        alert("登入失敗");
                    }
                })
                .catch((err) => {
                    this.Elog(err);
                    this.FunctionToken(this.FunctionGetImageCode, {
                        JSval: { type: "form1" },
                    });
                    alert("登入失敗");
                });
        },

        FunctionACCOUNTInsertByIndex(data_in) {
            this.apiACCOUNTInsertByIndex(data_in)
                .then((res) => {
                    let json = JSON.parse(res.data);
                    this.Clog(json);
                    if (json.Status) {
                        alert("註冊成功");
                        this.modal["form1"].hide();
                        return;
                    } else {
                        alert("註冊失敗," + json.Data);

                        return;
                    }
                })
                .catch((err) => {
                    this.Elog(err);
                    alert("註冊失敗");
                });
        },

        FunctionGetMail(data_in) {
            this.apiGetMail(data_in)
                .then(res => {
                    let json = JSON.parse(res.data)
                    if (this.IsNullKeys(data_in, ['JSval', 'email']) != '') {
                        if (json.Status == false) {
                            alert('信箱已註冊，請重新填寫')
                            this.form2.data['AC_EMAIL'].val = ''
                            this.form2.data['AC_EMAIL'].change(this.form2.data['AC_EMAIL'])
                        }
                    } else if (this.IsNullKeys(data_in, ['JSval', 'login']) != '') {
                        if (json.Status == true) {
                            alert(`此信箱(${data_in.AC_EMAIL})，尚未註冊`)
                            this.form2Clear()
                            this.form2.data['AC_EMAIL'].val = data_in.AC_EMAIL
                            this.form2Show()
                        } else {
                            this.FunctionToken(this.FunctionMailLogin, {
                                AC_EMAIL: data_in.AC_EMAIL
                            })
                        }
                    }


                })
                .catch(err => {
                    this.Elog(err)
                })
        },
        FunctionMailLogin(data_in) {
            let fun1 = this._storeData('LoginFUN')
            this.apiMailLogin(data_in)
                .then(res => {
                    let json = JSON.parse(res.data)
                    if (json.Status) {
                        let data1 = JSON.parse(json.Data)
                        this._sessionData("TokenID", data1);
                        this.modal["form1"].hide();
                        this.form1.text = '學員資料'
                        if (this.IsNull(fun1) != '') {
                            fun1(this.$route.path, () => {
                                this._go('/myRegistration')
                            })
                        } else {
                            this._go('/myRegistration')
                        }
                    } else {
                        this.FunctionToken(this.FunctionGetImageCode, {
                            JSval: { type: "form1" },
                        });
                        if (json.Data.indexOf('尚未註冊') != -1) {
                            alert(json.Data)
                            this.form2Clear()
                            this.form2.data['AC_EMAIL'].val = json.Data.split('，')[1]
                            this.form2Show()
                        } else {
                            alert("登入失敗");
                        }

                    }
                })
                .catch(err => {
                    this.Elog(err)
                })
        },
        FunctionMailLogin2(data_in) {
            let fun1 = this._storeData('LoginFUN')
            this.apiMailLogin2(data_in)
                .then(res => {
                    let json = JSON.parse(res.data)
                    if (json.Status) {
                        let data1 = JSON.parse(json.Data)
                        this._sessionData("TokenID", data1);
                        this.modal["form1"].hide();
                        this.form1.text = '學員資料'
                        if (this.IsNull(fun1) != '') {
                            fun1(this.$route.path, () => {
                                this._go('/myRegistration')
                            })
                        } else {
                            this._go('/myRegistration')
                        }
                    } else {
                        this.FunctionToken(this.FunctionGetImageCode, {
                            JSval: { type: "form1" },
                        });
                        if (json.Data.indexOf('尚未註冊') != -1) {
                            alert(json.Data)
                            this.form2Clear()
                            this.form2.data['AC_EMAIL'].val = json.Data.split('，')[1]
                            this.form2Show()
                        } else {
                            alert("登入失敗");
                        }

                    }
                })
                .catch(err => {
                    this.Elog(err)
                })
        },
        FunctionLastPass(data_in) {
            this.apiLastPass(data_in)
                .then(res => {
                    let json = JSON.parse(res.data)
                    if (json.Status) {
                        alert('寄送成功')
                        this.modal['form3'].hide()
                    } else {
                        alert('寄送失敗')
                    }

                })
                .catch(err => {
                    this.Elog(err)
                    alert('寄送失敗')
                })
        },
        FunctionGetCategoryListByIndex(data_in) {
            this.apiGetCategoryListByIndex(data_in)
                .then(res => {
                    let json = JSON.parse(res.data)
                    if (json.Status) {
                        let data1 = JSON.parse(json.Data)
                        let data2 = []

                        let files = []
                        for (let i in data1) {
                            let item = data1[i]
                            data2.push(item)
                            if (this.IsNull(item.CAT_ICON_URL) != '') {
                                files.push(item.CAT_ICON_URL);
                            }

                        }
                        this.CategoryData = data2

                        if (files.length != 0) {
                            this.FunctionToken(this.FunctionGetArchive, {
                                MC_ID: files
                            })
                        }

                    }
                })
                .catch(err => {
                    this.Elog(err)
                })
        },
        FunctionGetArchive(data_in) {
            this.apiGetArchive(data_in)
                .then(res => {
                    let json = JSON.parse(res.data)
                    if (json.Status) {
                        let data1 = JSON.parse(json.Data)
                        let MCIDs = []
                        for (let i in data1) {
                            let item = data1[i]
                            this.files[item.MC_ID] = encodeURI([
                                item.SP_PATH_WEB,
                                item.MC_FILE
                            ].join(''))
                            MCIDs.push(item.MC_ID)
                        }
                        this._watcher.run();
                    }
                })
                .catch(err => {
                    this.Elog(err)
                })
        },


        FunctionGetHashtgList(data_in) {
            this.apiGetHashtgList(data_in)
                .then(res => {
                    let json = JSON.parse(res.data)
                    if (json.Status) {
                        let data1 = JSON.parse(json.Data)
                        data1.forEach(x => {
                            this.nav.options.push(this.IsNull(x.HT_NAME))
                        })
                    }
                })
                .catch(err => {
                    this.Elog(err)
                })
        },


        FunctionGetCourseFeriodListByIndex_mini(data_in) {
            this.apiGetCourseFeriodListByIndex_mini(data_in)
                .then(res => {
                    let json = JSON.parse(res.data)
                    if (json.Status) {
                        let data1 = JSON.parse(json.Data)
                        data1.forEach(x => {
                            if (this.IsNull(x.CA_HASHTAG_ID) === '') {
                                return
                            }

                            let hashs = x.CA_HASHTAG_ID.split(',')
                            hashs.forEach(s => {
                                if (this.nav.options.indexOf(s) == -1) {
                                    this.nav.options.push(s)
                                }
                            })
                        })
                    }
                })
                .catch(err => {
                    this.Elog(err)
                })
        },



    },
    // watch: {
    //   "$route.path"() {
    //     this.nav.show = this.$route.path != "/courses";
    //   },
    // },

    // methods: {
    // 	handleScroll: function (evt, el) {
    // 		if (window.scrollY > 50) {
    // 			el.setAttribute(
    // 				'style',
    // 				'opacity: 1; transform: translate3d(0, -10px, 0)'
    // 			)
    // 		}
    // 		return window.scrollY > 100
    // 	}
    // }
};
</script>
<style lang="scss" scoped>
.form-inline input {
    border-radius: 50px;
}
</style>
